<template>
  <div class="roles"
       :class="{'loading': isLoading}"
  >
    <div class="roles__header">
      <template v-if="!isAddNewRoleMode">
        <div class="roles__header-title" @click="setAddNewRoleStatus(true)">
          + Add new role
        </div>
      </template>

      <template v-else>
        <div class="roles__header-input">
          <div class="roles__header-group">
            <input placeholder="title" type="text" v-model="newRoleValue" @keydown.enter="onKeyEnterNewRole" autofocus>
            <textarea placeholder="description" v-model="newRoleDescriptionValue"></textarea>
          </div>

          <button type="button" class="button accept" :disabled="!newRoleValue.trim()" @click="addNewRole">
            Create
          </button>
          <button type="button" class="button reject" @click="setAddNewRoleStatus(false)">Cancel</button>
        </div>
      </template>
    </div>

    <ul class="roles__content">
      <li v-for="role in roles"
          :key="role.guid"
          class="roles__item role"
      >
        <div class="role__title">
          {{ role.title }}
        </div>
        <div class="role__description"
             v-if="role.description"
        >
          {{ role.description }}
        </div>

        <button type="button"
                @click="removeRole(role.guid)"
                class="button reject"
        >
          <svg width="20" height="20">
            <use xlink:href="@/assets/icons.svg#remove"></use>
          </svg>
        </button>
      </li>
    </ul>
  </div>
</template>


<script>
import RolesService from "@/services/roles-service";

export default {
  name: 'RolesList',
  data: () => ({
    isLoading: false,
    rolesService: null,

    order: null,
    limit: 10,
    page: 1,

    roles: [],

    newRoleValue: '',
    newRoleDescriptionValue: '',
    isAddNewRoleMode: false,
  }),
  created() {
    this.rolesService = new RolesService();
  },
  mounted() {
    this.getRolesList();
  },
  methods: {
    removeRole(guid) {
      const message = confirm(`Remove this role?`);
      if (message) {
        this.rolesService.deleteRole(guid)
            .finally(() => this.isLoading = false)
            .then(() => {
              this.getRolesList();
            })
      }
    },
    onKeyEnterNewRole() {
      if (this.newRoleValue.trim()) {
        this.addNewRole();
      }
    },
    addNewRole() {
      this.newRoleValue = this.newRoleValue.trim().toLowerCase();
      this.isLoading = true;

      const data = {
        role: {
          name: this.newRoleValue,
          title: `${this.newRoleValue.charAt(0).toUpperCase()}${this.newRoleValue.slice(1)}`,
          description: `${this.newRoleDescriptionValue.trim()}`,
        }
      }

      this.rolesService.createRole(data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.newRoleValue = '';
            this.newRoleDescriptionValue = '';
            this.getRolesList();
          })
    },
    setAddNewRoleStatus(status) {
      this.isAddNewRoleMode = status;
    },
    getRolesList() {
      this.isLoading = true;

      const pagination = {
        pagination: {
          "page": this.page,
          "limit": this.limit,
          "order": this.order
        }
      }

      this.rolesService.getRolesList(pagination)
          .finally(() => this.isLoading = false)
          .then(res => {
            this.roles = res.items;
            this.$store.commit('setRolesList', this.roles);
          })
    }
  }
}
</script>

<style scoped lang="scss">
.roles {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
  max-height: calc(100vh - 58px - 40px);
  overflow: auto;
}

.roles__header-title {
  background-color: #ff9000;
  padding: 10px 20px;
  cursor: pointer;
  min-height: 38px;
}

.roles__header-input {
  min-height: 38px;
  display: flex;
}

.roles__header-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.roles__header-input input {
  border: 2px solid #ff9000;
  outline: none;
  border-radius: 5px 0 0 0;
  padding: 0 20px;
  font-family: inherit;
  height: 38px;
}

.roles__header-input textarea {
  border: 2px solid #ff9000;
  border-top: none;
  outline: none;
  border-radius: 0 0 0 0;
  padding: 10px 20px;
  font-family: inherit;
  min-height: 50px;
  resize: none;
}

.roles__content {
  padding: 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.roles__item {
  padding-bottom: 3px;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(#000, 0.4);
  }
}

.role {
  display: flex;
  flex-direction: column;
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 0;
    fill: #ffffff;
  }
}

.role__title {
  min-height: 30px;
  padding-right: 40px;
  line-height: 30px;
  font-weight: bold;
}

.role__description {
  padding: 3px 0;
  font-style: italic;
  color: rgba(#000, 0.7);
  border-top: 1px solid rgba(#000, 0.1);
  margin-top: 3px;
}
</style>