import ConfigStorageService from "@/services/config-storage-service";
import UserService from "@/services/user-service";

export default class HttpService {
    constructor() {
        this.endpointV2 = ConfigStorageService.get('apiEndpointV2');
    }

    http(url, method = 'GET', headers, body) {
        const methodURL = `${this.endpointV2}${url}`;
        const options = {}

        if (method) {
            options['method'] = method;
        }

        if (headers) {
            options['headers'] = headers;
        }

        if (body) {
            options['body'] = body;
        }

        return new Promise((resolve, reject) => {
            fetch(methodURL, options)
                .then(res => {
                    // return res.ok ? res.json() : reject(res)
                    if (!res.ok) {
                        if (res.status === 401) {
                            UserService.logout();
                        }
                        return res.json().then(err => {
                            return reject(err);
                        })
                    } else {
                        return resolve(res.json());
                    }
                })
                .then(data => resolve(data))
                .catch(err => {
                    console.log(err);
                    return reject(err)
                });
        })
    }
}