<template>
  <div class="overlay" @click.self="close">
<!--    <div class="overlay__close" @click="close"></div>-->

    <div class="overlay__counter">
      {{index + 1}} / {{len}}
    </div>

    <div class="overlay__goto overlay__goto--prev"
         :class="{'overlay__goto--disabled': index === 0}"
         @click="prev">
    </div>
    <div class="overlay__goto overlay__goto--next"
         :class="{'overlay__goto--disabled': index + 1 === len}"
         @click="next">
    </div>

    <div class="picset-preview" @click="close">
      <img :src="pic" alt="Pic not found">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPicsetPreview',
  props: {
    pic: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0,
    },
    len: {
      type: Number,
      default: 0,
    }
  },
  emits: ['close', 'prev', 'next'],
  created() {
    document.addEventListener('keydown', this.escListener);
  },
  unmounted() {
    document.removeEventListener('keydown', this.escListener);
  },
  methods: {
    escListener(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
    prev() {
      this.index && this.$emit('prev');
    },
    next() {
      (this.index + 1 !== this.len) && this.$emit('next');
    }
  }
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  background-color: rgba(#000, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.picset-preview img {
  object-fit: contain;
  max-width: calc(100vw - 200px);
  max-height: calc(100vh - 100px);
}

.overlay__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 80px;
    height: 4px;
    background-color: #fff;
    transform: rotate(45deg);
    top: 38px;
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.overlay__goto {
  position: absolute;
  width: 50px;
  height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  cursor: pointer;
  transition: 300ms ease-out;
  top: 50%;
  transform: translateY(-50%);

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 40px;
    height: 4px;
    background-color: #1b1b1b;
    transform: rotate(-45deg);
  }

  &::after {
    transform: rotate(45deg);
  }

  &:not(.overlay__goto--disabled):hover {
    background-color: #ff9000;
  }
}

.overlay__goto--prev {
  left: 0;

  &::before {
    top: 35px;
  }

  &::after {
    bottom: 35px;
  }
}

.overlay__goto--next {
  right: 0;

  &::before {
    bottom: 35px;
  }

  &::after {
    top: 35px;
  }
}

.overlay__goto--disabled {
  cursor: auto;

  &::before,
  &::after {
    top: auto;
    bottom: auto;
  }
}

.overlay__counter {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 20px;
}
</style>