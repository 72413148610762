<template>
  <div class="slider-overlay" v-if="picture">
    <div class="slider-view">
      <div class="slider-view__pic">
        <div class="slider-view__pic-wrapper">
          <img :src="pictureUrl"
               :alt="picture.guid"
          />
        </div>
      </div>

      <div class="slider-view__info">
        <h2>Picture {{ currentPictureIndex }} / {{ maxIndex }}</h2>

        <span>Size: {{ picture.width }}x{{ picture.height }}</span>

        <AbstractPictureInfo :trained="picture.trained"/>

<!--        <code class="slider-view__picture-data">-->
<!--          {{ picture }}-->
<!--        </code>-->

        <div class="slider-view__buttons">
          <div class="slider-view__button" @click="copyInfo"
          >
            Copy info
          </div>

          <div class="slider-view__button"
               @click="download"
          >
            Download image
          </div>
        </div>
      </div>

      <div class="slider-control slider-control--left"
           v-if="currentPictureIndex > minIndex"
           @click="decreaseIndex"
      >
        ← Back
      </div>

      <div class="slider-control slider-control--right"
           v-if="currentPictureIndex < maxIndex"
           @click="increaseIndex"
      >
        Next →
      </div>

      <div class="slider-close" @click="$emit('close', currentPictureIndex)"/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import ConfigStorageService from "@/services/config-storage-service";
import AbstractPictureInfo from "@/components/AbstractPictureInfo.vue";
// import EventBus from "@/services/event-bus";

export default defineComponent({
  name: "AbstractSliderView",
  components: {
    AbstractPictureInfo,
  },
  props: {
    currentPictureIndex: {
      type: Number,
      default: 0,
    },
    picture: {
      type: Object,
      default: null,
    },
    minIndex: {
      type: Number,
      default: 0,
    },
    maxIndex: {
      type: Number,
      default: 0,
    }
  },
  data: () => ({
    downloadEndpoint: '',
  }),
  computed: {
    pictureUrl() {
      return this.downloadEndpoint + this.picture.filepath;
    }
  },
  mounted() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
    document.body.style.overflow = 'hidden';

    window.addEventListener('keydown', this.pressKeyHandler.bind(this));
  },
  methods: {
    setIndex(index) {
      let _index = index <= this.minIndex ? this.minIndex : index;
          _index = _index >= this.maxIndex ? this.maxIndex : _index;

      this.$emit('update:currentPictureIndex', _index);
    },
    pressKeyHandler(event) {
      switch (event.key) {
        case 'ArrowLeft' :
          this.decreaseIndex();
          break;
        case 'ArrowRight' :
          this.increaseIndex();
          break;
        default: return;
      }
    },
    decreaseIndex() {
      this.setIndex(this.currentPictureIndex - 1);
    },
    increaseIndex() {
      this.setIndex(this.currentPictureIndex + 1);
    },
    copyInfo() {
      const info = JSON.stringify(this.picture, null, 2);
      if (!info) return;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(info);
      } else {
        const tempInput = document.createElement('textarea');
        tempInput.value = info;
        document.body.append(tempInput);
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
      }
    },
    download() {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', this.pictureUrl);
      link.setAttribute('download', '');

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  unmounted() {
    window.removeEventListener('keydown', this.pressKeyHandler.bind(this));
    document.body.style.overflow = '';
  },
})
</script>



<style lang="scss">
  .slider-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 50;
    backdrop-filter: blur(10px);
    background-color: rgba(#000, .4);
    inset: 0;
  }

  .slider-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 125px;
    border-radius: 25px;
    gap: 25px;
    box-shadow: 1px 1px 5px 1px rgba(#000, .1);
    width: 90%;
    max-width: 90%;
    background-color: #fff;
    height: 90%;
    position: relative;
  }

  .slider-view__pic-wrapper {
    height: 100%;
    max-width: 100%;
  }

  .slider-view__pic {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: calc(100% - 170px);
    width: 100%;

    & img {
      max-width: 100%;
      width: calc(100% - 50px);
      height: 100%;
      object-fit: cover;
    }
  }

  .slider-view__info {
    display: flex;
    width: 50%;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    height: 170px;
  }

  .slider-view__info h2 {
    margin-inline: auto;
  }

  .slider-view__info > span {
    margin-inline: auto;
  }


  .slider-view__picture-data {
    white-space: pre-wrap;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #f4f4f4;
    overflow: auto;
    font-size: 14px;
    margin-bottom: auto;
    max-height: 200px;
  }

  .slider-view__buttons {
    display: flex;
    gap: 25px;
    width: 100%;
  }

  .slider-view__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    color: #fff;
    background-color: rebeccapurple;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;

    &:hover {
      background-color: lighten(rebeccapurple, 10%);
    }
  }

  .slider-control {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100px;
    top: 0;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: .2s ease-out;

    &:hover {
      background-color: rgba(#000, .1);
    }
  }

  .slider-control--left {
    border-radius: 25px 0 0 25px;
    left: 0;
  }

  .slider-control--right {
    border-radius: 0 25px 25px 0;
    right: 0;
  }

  .slider-close {
    position: absolute;
    right: 125px;
    top: 25px;
    width: 35px;
    height: 35px;

    &::before,
    &::after {
      content: "";
      display: block;
      width: 2px;
      height: 20px;
      background-color: #222;
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      translate: -50% -50%;

      &:hover {
        background-color: #777;
      }
    }

    &::before {
      rotate: 45deg;
    }

    &::after {
      rotate: -45deg;
    }
  }
</style>
