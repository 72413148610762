<template>
  <div class="finder-result__item"
       :class="{'loading-item': item.loading}"
  >

    <span class="picture-expand" @click="$emit('expand')">
    <span class="picture-expand__size">
      {{ item.width }}x{{ item.height }}
    </span>
      <svg width="15" height="15">
        <use xlink:href="@/assets/icons.svg#expand"></use>
      </svg>
    </span>

    <app-checked-picset-persons-navigation :persons="filteredPersons"
                                           v-model:activeIndex="activePerson"
                                           @clear-active-tag="activeLabel = ''"
    />
    <div class="finder-result__image"
         :class="{'finder-result__image--vertical': orientation === 'vertical'}"
    >
      <app-checked-picset-zone v-for="(person, personIndex) in filteredPersons"
                :key="person.gender[0] + (person.index)"
                :title="person.gender[0] + (person.index)"
                :gender="person.gender"
                :bbox="person.bbox"
                :show="activePerson === -1 || activePerson === person.originalIndex"
                @zone-click="activePerson = activePerson === personIndex ? -1 : person.originalIndex"
      />
      <img class="lazyload"
           :data-src="getThumbUrl(item)"
           alt="Pic not found"
      />
    </div>

    <app-checked-picture-info :trained="filteredPersons"
                              :active-index="activePerson"
                              :counts="categoriesCount"
                              :filter-by-group="filterByGroup"
                              v-model:active-label="activeLabel"
                              @remove-label="onRemoveLabel"
                              @add-label="onAddLabel"
    />
  </div>
</template>


<script>
import ConfigStorageService from "@/services/config-storage-service";
import FinderService from "@/services/finder-service";

import AppCheckedPictureInfo from "@/modules/checked-picset/components/CheckedPictureInfo.vue";
import AppCheckedPicsetZone from "@/modules/checked-picset/components/CheckedPicsetZone.vue";
import AppCheckedPicsetPersonsNavigation from "@/modules/checked-picset/components/CheckedPicsetPersonsNavigation.vue"
import {mapMutations} from "vuex";
// import ItemTrainedCategories from "@/modules/checked-picset/components/ItemTrainedCategories";
// import ItemSetTrainedCategory from "@/modules/checked-picset/components/ItemSetTrainedCategory";

export default {
  name: 'TrainedThumbItem',
  components: {
    // ItemTrainedCategories,
    // ItemSetTrainedCategory,
    AppCheckedPictureInfo,
    AppCheckedPicsetZone,
    AppCheckedPicsetPersonsNavigation,
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    orientation: {
      type: String,
      default: ''
    },
    filterCategoriesList: {
      type: Map,
      default: new Map()
    },
    filterByGroup: {
      type: String,
      default: '',
    },
    currentFilter: {
      type: Array,
      default: () => []
    },
    pictureGuid: {
      type: String,
      default: '',
    },
  },
  emits: ['updateThumbData', 'setThumbGroupData', 'removeGroupFromThumb', 'preview', 'expand'],
  data: () => ({
    downloadEndpoint: null,
    finderService: null,

    isShowUnsetGroups: false,
    setupGroup: '',

    activePerson: -1,
    activeLabel: '',
  }),
  computed: {
    unsetGroupsList() {
      return this.getUnsetGroups();
    },
    filteredPersons() {
      if (!this.item || !this.item.trained.length) return [];

      const rawPersons = this.item.trained;

      const males = rawPersons
          .map((person, index) => {
            return ({...person, originalIndex: index });
          })
          .filter(person => {
            return String(person.gender).toLowerCase() === 'male';
          })
          .map((person, index) => {
            return ({...person, index: index + 1 });
          });

      const females = rawPersons
          .map((person, index) => {
            return ({...person, originalIndex: index });
          })
          .filter(person => {
            return String(person.gender).toLowerCase() === 'female';
          })
          .map((person, index) => {
            return ({...person, index: index + 1 });
          });

      const persons = [...females, ...males];

      if (!this.activeLabel) return persons;

      return persons.filter(person => {
        return person.groups.some(group => {
          return group.category?.some(category => {
            return String(category).trim().toLowerCase() === this.activeLabel;
          });
        });
      });
    },
    categoriesCount() {
      let categoriesList = [];
      const result = new Map();

      this.item?.trained
          ?.filter(person => person.gender !== 'male')
          ?.forEach(person => {
              person.groups?.forEach(group => {
                categoriesList = [...categoriesList, ...group.category];
          });
      });

      categoriesList.forEach(category => {
        result.has(category)
          ? result.set(category, result.get(category) + 1)
          : result.set(category, 1);
      });

      return result;
    },
  },
  mounted() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
    this.finderService = new FinderService();
  },
  methods: {
    ...mapMutations(['addCategory', 'removeCategory']),
    onRemoveLabel(label) {
      const data = {
        picture: this.item,
        pictureGuid: this.pictureGuid,
        category: label,
        personIndex: this.activePerson,
      }
      this.removeCategory(data);
    },
    showPreview() {
      this.$emit('preview', this.item.id);
    },
    cleanSetupGroup() {
      this.setupGroup = '';
    },
    selectSetupGroup(title) {
      this.setupGroup = this.setupGroup === title ? '' : title;
    },
    toggleShowUnsetGroup() {
      this.cleanSetupGroup();
      this.isShowUnsetGroups = !this.isShowUnsetGroups;
    },
    getUnsetGroups() {
      const tempArr = [];
      if (this.filterCategoriesList.size) {
        for (let key of this.filterCategoriesList.keys()) {
          const find = this.item.trained.find(el => el.group === key);
          if (!find) {
            tempArr.push(key);
          }
        }
      }

      return tempArr;
    },
    getThumbUrl(thumb) {
      if (thumb.filepath) {
        return this.downloadEndpoint + thumb.filepath;
      } else {
        return thumb.url;
      }
    },
    closeTags(trained) {
      trained.forEach(el => {
        el.open = false;
      })
    },
    onAddLabel(label) {
      const data = {
        picture: this.item,
        category: label.category,
        personIndex: this.activePerson,
        pictureGuid: this.pictureGuid,
        group: label.group,
      }
      this.addCategory(data);
    },
    getItemTrained(key, trained) {
      return trained.find(item => item.group === key)
    },
    onRemoveGroupFromThumb(data) {
      this.$emit('removeGroupFromThumb', data);
    },
    onUpdateThumbData(id) {
      this.$emit('updateThumbData', id);
    },
    onSetThumbGroupData(data) {
      this.toggleShowUnsetGroup();
      this.$emit('setThumbGroupData', data);
    },
  },
  watch: {
    activePerson: {
      handler(newValue) {
        if (newValue === -1) {
          this.activeLabel = '';
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.finder-result__item {
  position: relative;
}

.finder-result__image {
  //aspect-ratio: 16/9;
  background-color: #ff9000;
  line-height: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.finder-result__image--vertical {
  aspect-ratio: 9/16;
}


.trained {
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  padding: 10px;
}

.trained__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  min-height: 100%;
  height: min-content;
}

.unset {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  width: fit-content;
}

.unset__add-btn {
  fill: #222222;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: 3px;

  &:hover {
    fill: #ffffff;
    background-color: #222222;
  }
}

.unset__groups {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 3px;
}

.unset__groups span {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 2px 4px;
  border-radius: 3px;
  display: inline-flex;
  font-size: 13px;
  color: #000;
  cursor: pointer;

  &:hover {
    background-color: #fff;
    color: #000000;
  }

  &.active {
    background-color: #ffffff;
    font-weight: bold;
    height: 32px;
    align-items: center;
  }
}

.picture-expand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding-inline: 3px;
  height: 17px;
  font-size: 11px;
  background-color: #fff;
  border: 1px solid #222;
  position: absolute;
  right: 0;
  top: 2px;
  z-index: 1;
  cursor: pointer;
}
</style>
