import UserService from "@/services/user-service";
import HttpV2Service from "@/services/http-v2-service";
import ConfigStorageService from "@/services/config-storage-service";

export default class ArchivesV2Service extends HttpV2Service {
    constructor() {
        super();
        this.endpointV2 = ConfigStorageService.get('apiEndpointV2');
        this.token = UserService.getToken();
        this.headers = {
            Authorization: `bearer ${this.token}`
        };
    }

    // Добавить пиксет по архиву
    addPicsetByArchive(formData, cancelToken, progressCallback) {
        const url = this.endpointV2 + `archive`;

        // eslint-disable-next-line no-undef
        return axios
            .post(url, formData, {
                headers: this.headers,
                onUploadProgress: progressCallback,
                cancelToken: cancelToken,
            })
            .then((x) => {
                return x.data;
            })
            .catch((thrown) => {
                // eslint-disable-next-line no-undef
                if (axios.isCancel(thrown)) {
                    console.log('Request canceled: ', thrown.message);
                } else {
                    return thrown;
                }
            });
    }
}