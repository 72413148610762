<template>
  <div class="neural-network-info" v-if="show">
    <div class="neural-network-info__group neural-group"
         v-for="group in Object.keys(info)"
         :key="group"
    >
     <span class="neural-group__title">
       {{ group }}
     </span>

      <span class="neural-group__info">
        Ver.:
        <span class="neural-group__info-value">{{ info[group].version }}</span>
      </span>

      <span class="neural-group__info">
        Time:
        <span class="neural-group__info-value">{{ new Date(info[group].timestamp).toLocaleString() }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AbstractNeuralNetworkInfo",
  props: {
    info: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    }
  },
})
</script>

<style lang="scss">
.neural-network-info {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.neural-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.neural-group__title {
  font-weight: bold;
  font-size: 14px;
}

.neural-group__info {
  color: #888;
  font-size: 12px;
}

.neural-group__info-value {
  color: #222;
}
</style>
