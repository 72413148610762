<template>
  <div class="wrapper">
    <AppGroupList :list="groupList"
                  :is-loading="isGroupListLoading"
                  :selected-group-guid="selectedGroupGuid"
                  @add-new-group="onAddNewGroup"
                  @remove-group="onRemoveGroup"
                  @remove-category-from-group="onRemoveCategoryFromGroup"
                  @update-group-title="onUpdateGroupTitle"
                  @select-group="onSelectGroup"
    ></AppGroupList>

    <AppGroupSelectionControl :is-loading="isGroupListLoading || isCategoriesListLoading"
                              :is-selected-category="!!selectedCategoriesList.length"
                              :is-selected-group="!!selectedGroupGuid"
                              :is-max-categories-selected="allFilteredIsSelected()"
                              @set-categories-to-group="onSetCategoriesToGroup"
                              @select-all-categories="onSelectAllCategories"
                              @unselect-all-categories="onUnselectAllCategories"
                              @remove-all-categories-from-group="onRemoveAllCategoriesFromGroup"
    ></AppGroupSelectionControl>

    <AppCategoriesList :list="categoriesList"
                       :is-loading="isCategoriesListLoading"
                       :selected-categories-list="selectedCategoriesList"
                       @add-new-category="onAddNewCategory"
                       @update-category-title="onUpdateCategoryTitle"
                       @remove-category="onRemoveCategory"
                       @toggle-selected-category="onToggleSelectedCategory"
                       @set-filtered-categories="onSetFilteredCategories"
    ></AppCategoriesList>
  </div>
</template>

<script>
import SeriesService from "@/services/series-service";
import CategoriesService from "@/services/categories-service";

import AppGroupList from "@/modules/categories/components/GroupList";
import AppCategoriesList from "@/modules/categories/components/CategoriesList";
import AppGroupSelectionControl from "@/modules/categories/components/GroupSelectionControl";

export default {
  name: 'AppCategoriesLayout',
  components: {AppGroupSelectionControl, AppCategoriesList, AppGroupList},
  data: () => ({
    seriesService: null,
    categoriesService: null,

    isGroupListLoading: false,
    isCategoriesListLoading: false,

    groupList: [],
    categoriesList: [],

    selectedCategoriesList: [],
    selectedGroupGuid: '',

    filteredCategories: []
  }),
  created() {
    this.seriesService = new SeriesService();
    this.categoriesService = new CategoriesService();
  },
  mounted() {
    this.getGroupList();
    this.getCategoriesList();
  },
  methods: {
    allFilteredIsSelected() {
      let result = true;
      for (let i = 0; i < this.filteredCategories.length; i++) {
        const find = this.selectedCategoriesList.find(el => el.guid === this.filteredCategories[i].guid);

        if (!find) {
          result = false;
          break;
        }
      }

      return result;
    },
    mergeArray(...arr) {
      return [...new Set(arr.flat())];
    },
    getCategoriesList() {
      this.isCategoriesListLoading = true;

      this.categoriesService.getCategoriesList()
          .finally(() => this.isCategoriesListLoading = false)
          .then(res => {
            this.categoriesList = res.categories;
            this.selectedCategoriesList = [];
          })
    },
    getGroupList() {
      this.isGroupListLoading = true;

      this.seriesService.getGroupList()
          .finally(() => this.isGroupListLoading = false)
          .then(res => {
            this.groupList = res.series;
            this.selectedGroupGuid = '';
          })
    },
    setNewCategoriesToGroup(data) {
      this.isGroupListLoading = true;
      this.isCategoriesListLoading = true;

      this.seriesService.setSeriesBindings(data)
          .finally(() => this.isGroupListLoading = false)
          .finally(() => this.isCategoriesListLoading = false)
          .then(() => {
            this.getGroupList();
            this.getCategoriesList();
          })
    },
    updateCategoriesToGroup(data) {
      this.isGroupListLoading = true;
      this.isCategoriesListLoading = true;

      this.seriesService.addSeriesBindings(data)
          .finally(() => this.isGroupListLoading = false)
          .finally(() => this.isCategoriesListLoading = false)
          .then(() => {
            this.getGroupList();
            this.getCategoriesList();
          })
    },
    onSetCategoriesToGroup() {
      const categories = this.selectedCategoriesList.map(el => {
        return {title: el.title}
      });

      const data = {
        guid: [this.selectedGroupGuid],
        categories
      }

      const group = this.groupList.find(el => el.guid === this.selectedGroupGuid);
      if (group.categories.length) {
        this.updateCategoriesToGroup(data);
      } else {
        this.setNewCategoriesToGroup(data);
      }
    },
    onAddNewGroup(value) {
      const data = {
        payload: {
          title: value.toLowerCase()
        }
      }

      this.isGroupListLoading = true;
      this.seriesService.createSeries(data)
          .finally(() => this.isGroupListLoading = false)
          .then(() => {
            this.getGroupList();
          })
    },
    onAddNewCategory(value) {
      const data = {
        payload: {
          title: value.toLowerCase()
        }
      }

      this.isCategoriesListLoading = true;
      this.categoriesService.createCategory(data)
          .finally(() => this.isCategoriesListLoading = false)
          .then(() => {
            this.getCategoriesList();
          })
    },
    onRemoveAllCategoriesFromGroup() {
      const data = {
        guid: [this.selectedGroupGuid],
        categories: []
      }

      this.isGroupListLoading = true;
      this.isCategoriesListLoading = true;

      this.seriesService.setSeriesBindings(data)
          .finally(() => this.isGroupListLoading = false)
          .finally(() => this.isCategoriesListLoading = false)
          .then(() => {
            this.getGroupList();
            this.getCategoriesList();
          })
    },
    onRemoveCategoryFromGroup(value) {
      const [groupGuid, categoryGuid] = value;
      const group = this.groupList.find(el => el.guid === groupGuid);
      const categories = group.categories.filter(el => el.guid !== categoryGuid);
      const data = {
        guid: [groupGuid],
        categories
      }

      this.isGroupListLoading = true;
      this.isCategoriesListLoading = true;

      this.seriesService.setSeriesBindings(data)
          .finally(() => this.isGroupListLoading = false)
          .finally(() => this.isCategoriesListLoading = false)
          .then(() => {
            this.getGroupList();
            this.getCategoriesList();
          })
    },
    onRemoveGroup(guid) {
      const message = confirm(`Remove group?`);
      if (message) {
        this.isGroupListLoading = true;
        this.isCategoriesListLoading = true;
        this.seriesService.deleteSeriesItem(guid)
            .finally(() => this.isGroupListLoading = false)
            .finally(() => this.isCategoriesListLoading = false)
            .then(() => {
              this.getGroupList();
              this.getCategoriesList();
            })
      }
    },
    onRemoveCategory(guid) {
      const message = confirm(`Remove category?`);
      if (message) {
        this.isCategoriesListLoading = true;
        this.isGroupListLoading = true;
        this.categoriesService.deleteCategory(guid)
            .finally(() => this.isGroupListLoading = false)
            .finally(() => this.isCategoriesListLoading = false)
            .then(() => {
              this.getGroupList();
              this.getCategoriesList();
            })
      }
    },
    onUpdateGroupTitle(item) {
      this.isGroupListLoading = true;
      const data = {
        payload: {
          title: item.title.trim().toLowerCase()
        }
      }

      this.seriesService.updateSeries(item.guid, data)
          .finally(() => this.isGroupListLoading = false)
          .then(() => {
            this.getGroupList();
          })
    },
    onUpdateCategoryTitle(item) {
      this.isCategoriesListLoading = true;
      const data = {
        payload: {
          title: item.title.trim().toLowerCase()
        }
      }

      this.categoriesService.updateCategory(item.guid, data)
          .finally(() => this.isCategoriesListLoading = false)
          .then(() => {
            this.getCategoriesList();
          })
    },
    onSelectGroup(guid) {
      this.selectedGroupGuid = guid === this.selectedGroupGuid ? '' : guid;
    },
    onToggleSelectedCategory(item) {
      const findIndex = this.selectedCategoriesList.findIndex((el) => el.guid === item.guid);

      if (findIndex < 0) {
        this.selectedCategoriesList.push(item);
      } else {
        this.selectedCategoriesList.splice(findIndex, 1);
      }
    },
    onSelectAllCategories() {
      this.selectedCategoriesList = this.mergeArray(this.selectedCategoriesList, this.filteredCategories);
    },
    onUnselectAllCategories() {
      this.selectedCategoriesList = [];
    },
    onSetFilteredCategories(items) {
      this.filteredCategories = items;
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  display: grid;

  grid-template-columns: 1fr 50px 450px;
  align-items: start;
}
</style>