import ConfigStorageService from "@/services/config-storage-service";
import UserService from "@/services/user-service";
import HttpService from "@/services/http-service";
// TODO: interceptor
// https://blog.logrocket.com/intercepting-javascript-fetch-api-requests-responses/

export default class FinderService extends HttpService {
    constructor() {
        super();
        this.endpoint = ConfigStorageService.get('apiEndpoint')
        this.token = UserService.getToken();
        this.headers = {
            Authorization: `bearer ${this.token}`
        };
    }

    // Получить пиксет по guid
    getPicset(guid) {
        const url = `picset/${guid}`;

        return this.http(url, 'GET', this.headers);
    }

    // Получить изображения ВЫСОКОГО качества по guid
    getPicsetPics(guid, filtered = false, excluded = false) {
        const url = filtered ? `picture?_filter=picset_guid == "${guid}" and excluded == "${excluded}"&_order_by=created`
            : `picture?_filter=picset_guid == "${guid}"&_order_by=created`;

        return this.http(url, 'GET', this.headers);
    }

    // Получить изображения ВЫСОКОГО качества по guid c фильтрацией
    getPicsetPicsByCategories(guid, filtered = false, excluded = false, trained) {
        const url = filtered ? `picture?_filter=picset_guid == "${guid}" and excluded == "${excluded}"&_order_by=created`
            : `picture?_filter=picset_guid == "${guid}"&_order_by=created`;

        return this.http(url, 'POST', this.headers, JSON.stringify({ trained }));
    }

    // Получить изображения НИЗКОГО качества по guid
    getPicsetThumbs(guid, page, limit, filtered = false, excluded = false) {
        let url = filtered ? `thumb?_filter=picset_guid == "${guid}" and excluded == "${excluded}" &_order_by=created`
            : `thumb?_filter=picset_guid == "${guid}"&_order_by=created`;

        if (page) {
            url = url + `&_offset=${(page - 1) * limit}`;
        }

        if (limit) {
            url = url + `&_limit=${limit}`;
        }

        return this.http(url, 'GET', this.headers);
    }

    // Получить изображения НИЗКОГО качества по guid c фильтрацией
    getPicsetThumbsByCategories(guid, page, limit, filtered = false, excluded = false, trained) {
        let url = filtered ? `thumb?_filter=picset_guid == "${guid}" and excluded == "${excluded}" &_order_by=created`
            : `thumb?_filter=picset_guid == "${guid}"&_order_by=created`;

        if (page) {
            url = url + `&_offset=${(page - 1) * limit}`;
        }

        if (limit) {
            url = url + `&_limit=${limit}`;
        }

        return this.http(url, 'POST', this.headers, JSON.stringify({ trained }));
    }

    // Создать новый сет
    makeNewPicset(req) {
        const url = `picset`;

        return this.http(url, 'POST', this.headers, JSON.stringify(req));
    }

    // Обновить существующий сет
    updatePicset(guid, data) {
        const url = `picset/${guid}`;

        return this.http(url, 'PUT', this.headers, JSON.stringify(data));
    }

    // Сохраняет отмеченые изображения и их статус
    savePics(guid, data) {
        const url = `picset/${guid}/save`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Создать архив по гуиду
    createArchive(guid, data) {
        const url = `picset/${guid}/archive`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Создать мульти архив по гуидам
    createMultiArchive(data) {
        const headers = {
            Authorization: `bearer ${this.token}`,
            'Content-Type': 'application/json'
        };
        const url = `archive`;

        return this.http(url, 'PUT', headers, JSON.stringify(data));
    }

    // Получить список пиксетов
    getArchivesListByPost(page, limit, sort = 'picset.created desc', payload, originTag, owner = '', query = '', statusFilter) {
        let url = `picsets?_filter=mode == 'PREPARING'`; // по умолчанию в списке пиксетов не выводим trained

        // if (originTag) {
        //     url = url + `and origin_guid == '${originTag}'`;
        // }

        if (statusFilter) {
            if (statusFilter === 'ready') {
                url = url + `and is_ready == 'true'`;
            }

            if (statusFilter === 'unready') {
                url = url + `and is_ready == 'false'`;
            }
        }

        if (owner) {
            url = url + `and owner == '${owner}'`;
        }

        if (query) {
            url = url + `and query == '${query}'`;
        }

        if (sort) {
            url = url + `&_order_by=${sort}`;
        }

        if (page) {
            url = url + `&_offset=${(page - 1) * limit}`;
        }

        if (limit) {
            url = url + `&_limit=${limit}`;
        }

        return this.http(url, 'POST', this.headers, JSON.stringify(payload));
    }

    // Получить обработанный НСеткой список сетов
    getCheckingList(page, limit, sort = 'picset.created desc', payload, originTag, owner = '') {
        let url = `picsets?_filter=mode == 'CHECKING'`;

        if (originTag) {
            url = url + `and origin_guid == '${originTag}'`;
        }

        if (owner) {
            url = url + `and owner == '${owner}'`;
        }

        if (sort) {
            url = url + `&_order_by=${sort}`;
        }

        if (page) {
            url = url + `&_offset=${(page - 1) * limit}`;
        }

        if (limit) {
            url = url + `&_limit=${limit}`;
        }

        return this.http(url, 'POST', this.headers, JSON.stringify(payload));
    }

    // Получить валидированный список пиксетов
    getValidatedList(page, limit, sort = 'picset.created desc', payload, originTag, owner = '') {
        let url = `picsets?_filter=mode == 'VALIDATED'`;

        if (originTag) {
            url = url + `and origin_guid == '${originTag}'`;
        }

        if (owner) {
            url = url + `and owner == '${owner}'`;
        }

        if (sort) {
            url = url + `&_order_by=${sort}`;
        }

        if (page) {
            url = url + `&_offset=${(page - 1) * limit}`;
        }

        if (limit) {
            url = url + `&_limit=${limit}`;
        }

        return this.http(url, 'POST', this.headers, JSON.stringify(payload));
    }

    // Удалить выбранный пиксет
    deletePicset(guid) {
        const url = `picset/${guid}`;

        return this.http(url, 'DELETE', this.headers);
    }

    // Получить список основных тегов
    getOriginTagsList() {
        const url = `origin?_order_by=title`;

        return this.http(url, 'GET', this.headers);
    }

    // Получить список тегов
    getTagsList() {
        const url = `tag`;

        return this.http(url, 'GET', this.headers);
    }

    // Обновить список тегов у пиксета
    tagsUpdate(guid, data) {
        const url = `picset/${guid}/tags`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Обновить основной тег
    originTagUpdate(guid, data) {
        const url = `picset/${guid}/origin`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }


    // Удалить основной тег
    removeOriginTag(guid) {
        const url = `picset/${guid}/origin`;

        return this.http(url, 'DELETE', this.headers);
    }

    // Установить статус готовности пиксета
    setReadiness(guid, data) {
         const url = `picset/${guid}/readiness`

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Обновить trained для изображения
    updatePicTrained(guid, data) {
        const url = `picture/${guid}/trained`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить категории к пиксету
    setCategories(guid, data) {
        const url = `picset/${guid}/category`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить группы к пиксету
    setSeries(guid, data) {
        const url = `picset/${guid}/series`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить что пиксет валидный
    setPicsetValidated(guid, data) {
        const url = `picset/${guid}/validated`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить что набор пиксетов валидный
    setPicsetListValidated(data) {
        const url = `picset/validated`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить что пиксет проверяемый
    setPicsetChecking(guid, data) {
        const url = `picset/${guid}/checking`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить что набор пиксетов проверяемый
    setPicsetListChecking(data) {
        const url = `picset/checking`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить что пиксет подготовленный
    setPicsetPreparing(guid, data) {
        const url = `picset/${guid}/preparing`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Установить что набор пиксетов подготовленный
    setPicsetListPreparing(data) {
        const url = `picset/preparing`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    setPicsetAcl(guid, data) {
        const url = `picset/${guid}/acl`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    setSelectedPicsetsAlc(data) {
        const url = `picset/acl`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    // Спарсить заново пиксет
    reparsePicset(guid) {
        const url = 'picset/reparse';
        const data = {
            guids: [guid],
        };

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }
}
