<template>
  <header class="archives-header">
    <AppSortPaginate :count="totalItems"
                     v-model="currentPage"
                     :is-some-selected="selectedPicsetList.length > 0"
                     :selected-count="selectedPicsetList.length"
                     title="Validated list"
                     :is-validated="true"
                     @select-all="onSelectAll"
                     @unselect-all="onUnselectAll"
                     @set-checking="onSetChecking"
                     @set-prepared="onSetPrepared"
                     @set-archived="onSetArchived"
                     @json-selected="onJSONSelected"
    ></AppSortPaginate>

    <AppFilterForm :is-loading="isLoading"
                   :users-list="usersList"
                   :groups-list="groupList"
                   :categories-list="filterCategoriesList"
                   v-model:currentGroupFilter="currentGroupFilter"
                   v-model:currentCategoryFilter="currentCategoryFilter"
                   v-model:selectedOwner="selectedOwner"
    ></AppFilterForm>
  </header>

  <div class="archives-list"
       :class="{'loading': isLoading}"
  >
    <div class="archives-list__item archives-item"
         v-for="item in items"
         :key="item.guid"
    >
      <div class="archives-item__row">
        <div class="archives-item__toggler"
             @click.self="toggleSelected(item)"
             :class="{'archives-item__toggler--active': isIncludedPicset(item.guid)}"
        ></div>

        <router-link
            :to="'/validated/' + item.guid"
            v-if="item.state === 'PARSED' || (item.state === 'PARSING' && +item.parsed > 0) || (item.state === 'TAKEN_PARSE' && +item.parsed > 0) || item.state === 'ARCHIVED' || item.state === 'ERROR'"
            class="archives-item__title"
        >
          <img v-if="item.url"
               :data-src="item.url"
               alt=""
               class="lazyload"
          >
          {{ item.query }}
        </router-link>
        <div class="archives-item__title" v-else>
          <img v-if="item.url"
               :data-src="item.url"
               alt=""
               class="lazyload"
          >
          {{ item.query }}
        </div>

        <div class="archives-item__state"
             :class="{
              'error': item.state === 'ERROR',
              'correct': item.state === 'PARSED' || item.state === 'ARCHIVED',
              'common': item.state === 'PARSE' || item.state === 'ARCHIVE',
              'taken': item.state === 'TAKEN_PARSE' || item.state === 'TAKEN_ARCHIVE',
              'processing': item.state === 'PARSING' || item.state === 'ARCHIVING',
           }">
          {{ item.state }}
        </div>

        <div class="archives-item__count">
          ({{ getCount(item) !== item.parsed ? (getCount(item) + '/' + item.parsed) : item.parsed }})
        </div>

        <div class="archives-item__info">
          <div class="archives-item__info-pin">
            i
          </div>
          <div class="archives-item__info-tooltip">
            <b>{{ item.orientation }}</b> / <b>{{ item.imageSize ? item.imageSize : 'auto' }}</b> /
            <b>{{ item.imageType }}</b> / <b>{{ item.fileType ? item.fileType : 'jpg' }}</b>
          </div>
        </div>

<!--        <div class="archives-item__tags tags" v-if="item.origin && item.origin.title">-->
<!--          <div class="tags__item tags__item&#45;&#45;main"-->
<!--               @click="filterByOriginTag(item.origin.guid)"-->
<!--          >-->
<!--            {{ item.origin.title }}-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="archives-item__tags tags" v-if="item.tags">-->
<!--          <div class="tags__item"-->
<!--               v-for="tag in item.tags.sort(el => el.origin)"-->
<!--               :key="tag.guid"-->
<!--               @click="filterBySecondTag(tag.title)"-->
<!--          >-->
<!--            {{ tag.title }}-->
<!--          </div>-->
<!--        </div>-->

        <div class="archives-item__role"
             :class="{'archives-item__role--admin': item.owner === 'admin'}"
             v-if="isUserAdmin && item.owner"
             @click="filterByOwner(item.owner)"
        >
          {{ item.owner }}
        </div>

        <div class="archives-item__controls">
          <!--          <button class="archives-item__button archives-item__button&#45;&#45;validate"-->
          <!--                  type="button"-->
          <!--                  v-if="item.state === 'PARSED' || item.state === 'ARCHIVED' || item.state === 'ERROR'"-->
          <!--                  @click="setChecking(item)"-->
          <!--          >-->
          <!--            Checking-->
          <!--          </button>-->
          <!--          <button class="archives-item__button archives-item__button&#45;&#45;archive"-->
          <!--                  type="button"-->
          <!--                  v-if="item.state === 'PARSED' || item.state === 'ARCHIVED' || item.state === 'ERROR'"-->
          <!--                  @click="archive(item)"-->
          <!--          >-->
          <!--            Archive-->
          <!--          </button>-->
          <a :href="downloadEndpoint + item.filepath"
             class="archives-item__button archives-item__button--download"
             v-if="item.state === 'ARCHIVED' && item.filepath"
          >
            Download
            <span>{{ getDate(item.archivedAt) }}</span>
          </a>
          <router-link
              class="archives-item__button archives-item__button--edit"
              :to="'/validated/' + item.guid"
              v-if="item.state === 'PARSED' || (item.state === 'PARSING' && +item.parsed > 0) || (item.state === 'TAKEN_PARSE' && +item.parsed > 0) || item.state === 'ARCHIVED' || item.state === 'ERROR'"
          >
            Edit
            <span>{{ getDate(item.updated, true) }}</span>
          </router-link>
        </div>
      </div>

      <div class="archives-item__labels labels">
        <div v-if="item.series.length"
             class="labels__label labels__label--black"
             :class="{
                'labels__label--highlighted': currentGroupFilter === item.series[0].title
             }"
             title="Original group"
             @click="currentGroupFilter = currentGroupFilter === item.series[0].title ? '' : item.series[0].title"
        >
          {{ item.series[0].title }}
        </div>

        <div v-if="item.categories.length"
             class="labels__label labels__label--gray"
             :class="{
                'labels__label--highlighted': currentCategoryFilter === item.categories[0].title
             }"
             title="Original category"
             @click="currentCategoryFilter = currentCategoryFilter === item.categories[0].title ? '' : item.categories[0].title"
        >
          {{ item.categories[0].title }}
        </div>

        <div class="labels__label labels__label"
             :class="{
                'labels__label--highlighted': group.group === currentTrainedGroupFilter
             }"
             v-for="group in item.trained"
             :key="group.guid"
             title="Trained group"
             @click="currentTrainedGroupFilter = currentTrainedGroupFilter === group.group ? '' : group.group"
        >
          {{ group.group }}
        </div>
      </div>

<!--      <div class="archives-item__trained-groups trained-groups"-->
<!--           v-if="mustShowTrainedGroups && item.trained?.length"-->
<!--      >-->
<!--        <div class="trained-groups__item"-->
<!--             v-for="group in item.trained"-->
<!--             :key="group.guid"-->
<!--        >-->
<!--          {{ group.group }}-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="archives-item__categories categories" v-if="item.series.length">-->
<!--        <div class="categories__item"-->
<!--             v-for="gp in item.series"-->
<!--             :key="gp.guid"-->
<!--        >-->
<!--          {{ gp.title }}-->
<!--          <span v-for="group in gp.series" :key="group">-->
<!--            {{ group }}-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->
    </div>


    <div class="archives-list__controls finder-form">
      <div class="finder-form__select">
        <span>Count</span>
        <select v-model="perPage" @change="setPerPage">
          <option v-for="(item, index) in perPageOptions"
                  :key="index"
                  :value="item">{{ item }}
          </option>
        </select>
      </div>

      <vue-awesome-paginate
          :total-items="totalItems"
          :items-per-page="perPage"
          :max-pages-shown="perPage"
          v-model="currentPage"
          :on-click="setPage"
      ></vue-awesome-paginate>
    </div>
  </div>
</template>

<script>
import FinderService from "@/services/finder-service";
import UsersService from "@/services/users-service";
import SeriesService from "@/services/series-service";
import CategoriesService from "@/services/categories-service";
import ConfigStorageService from "@/services/config-storage-service";

import AppSortPaginate from "@/modules/picsets-list/archives-sort-paginate/ArchivesSortPaginate";
import AppFilterForm from "@/modules/picsets-list/filter-form/FilterForm";
import {mapState} from "vuex";
import getPageFromQuery from "@/mixins/getPageFromQuery";

export default {
  name: 'AppElementsList',
  components: {
    AppSortPaginate,
    AppFilterForm
  },
  mixins: [
    getPageFromQuery,
  ],
  props: {
    mustShowTrainedGroups: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    usersService: null,
    finderService: null,
    seriesService: null,
    categoriesService: null,
    downloadEndpoint: null,
    isLoading: false,

    currentPage: 1,
    totalItems: 0,
    perPage: 50,
    perPageOptions: [50, 100, 200],

    items: [],

    reloadInterval: null,
    reloadIntervalTime: 10000,

    selectedPicsetList: [],

    usersList: [],
    tagsList: [],
    originTagsList: [],

    groupList: [],
    groupListRaw: [],
    categoriesListRaw: [],

    currentGroupFilter: '',
    currentTrainedGroupFilter: '',
    currentCategoryFilter: '',

    selectedOriginTagGuid: '',
    selectedSecondTagTitle: '',
    selectedOwner: '',

    sort: 'picset.created desc'
  }),
  created() {
    this.finderService = new FinderService();
    this.usersService = new UsersService();
    this.seriesService = new SeriesService();
    this.categoriesService = new CategoriesService();
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
  },
  mounted() {
    const onMounted = () => {
      if (this.userName) {
        this.getList();
        this.getGroupsList();
        this.getCategoriesList();
        this.getUsersList();
      } else {
        setTimeout(() => {
          onMounted();
        }, 1000);
      }
    }

    onMounted();
  },
  computed: {
    ...mapState([
      'isUserAdmin',
      'userName'
    ]),
    filterCategoriesList() {
      if (!this.currentGroupFilter) {
        return this.categoriesListRaw;
      } else {
        return this.groupListRaw.find(group => group.title === this.currentGroupFilter)?.categories || [];
      }
    },
  },
  watch: {
    currentGroupFilter() {
      this.currentCategoryFilter = '';
      this.onGroupFilterSet();
    },
    currentTrainedGroupFilter() {
      this.setPage(1);
      this.getList();
    },
    currentCategoryFilter() {
      this.onCategoryFilterSet();
    },
    selectedOwner() {
      this.onOwnerFilterChange();
    },
  },
  methods: {
    getGroupsList() {
      this.seriesService.getGroupList()
          .then(res => {
            // console.log(res);
            this.groupListRaw = res.series;
            this.groupList = res.series.map(el => el.title);
            // this.categoriesList = res.categories.map(el => el.title);
          })
    },
    getCategoriesList() {
      this.categoriesService.getCategoriesList()
          .then(res => {
            this.categoriesListRaw = res.categories;
          });
    },
    filterByOriginTag(guid) {
      this.selectedOriginTagGuid = guid;
    },
    filterBySecondTag(title) {
      this.selectedSecondTagTitle = title;
    },
    filterByOwner(title) {
      this.selectedOwner = title;
    },
    onTagsFilterChange() {
      this.getList();
    },
    onOwnerFilterChange() {
      this.getList();
    },
    getUsersList() {
      this.usersService.getUsersList()
          .then(res => {
            this.usersList = res.items;
          })
    },
    getOriginTagsList() {
      this.finderService.getOriginTagsList()
          .then(res => {
            this.originTagsList = res.origins;
          })
    },
    getTagsList() {
      this.finderService.getTagsList()
          .then(res => {
            this.tagsList = res.tags;
          })
    },
    onSetArchived() {
      this.isLoading = true;
      const data = {
        guids: this.selectedPicsetList
      }

      this.finderService.createMultiArchive(data)
          .finally(() => {
            this.isLoading = false;
          })
          .then(() => {
            this.selectedPicsetList = [];
            this.getList();

            alert('Archive create success');
          })
          .catch(err => {
            alert(err.error[0].message);
          })
    },
    onSetPrepared() {
      this.isLoading = true;
      const data = {
        guids: this.selectedPicsetList
      }

      this.finderService.setPicsetListPreparing(data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.getList()
          })
    },
    onSetChecking() {
      this.isLoading = true;
      const data = {
        guids: this.selectedPicsetList
      }

      this.finderService.setPicsetListChecking(data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.getList()
          })
    },
    onCategoryFilterSet() {
      this.setPage(1);
      this.getList();
    },
    onGroupFilterSet() {
      this.setPage(1);
      this.getList();
    },
    onSelectAll() {
      this.items.forEach(item => {
        if (!this.selectedPicsetList.includes(item.guid)) {
          this.selectedPicsetList.push(item.guid);
        }
      })
    },
    onUnselectAll() {
      this.selectedPicsetList = [];
    },
    onJSONSelected() {
      const idsList = JSON.stringify(this.selectedPicsetList, null, 2);
      if (!idsList) return;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(idsList);
      } else {
        const tempInput = document.createElement('textarea');
        tempInput.value = idsList;
        document.body.append(tempInput);
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
      }
    },
    isIncludedPicset(guid) {
      return this.selectedPicsetList.includes(guid);
    },
    toggleSelected(item) {
      if (!this.selectedPicsetList.includes(item.guid)) {
        this.selectedPicsetList.push(item.guid);
      } else {
        const findIndex = this.selectedPicsetList.findIndex(el => el === item.guid);
        this.selectedPicsetList.splice(findIndex, 1);
      }
    },
    checkWaiting() {
      const find = this.items.find(
          (item) => item.state === 'PARSE'
              || item.state === 'TAKEN_PARSE'
              || item.state === 'PARSING'
              || item.state === 'ARCHIVE'
              || item.state === 'TAKEN_ARCHIVE'
              || item.state === 'ARCHIVING'
      )

      if (find) {
        if (!this.reloadInterval) {
          this.reloadInterval = setInterval(() => {
            this.getList();
          }, this.reloadIntervalTime)
        }
      } else {
        clearInterval(this.reloadInterval);
      }
    },
    getList() {
      let owner = this.selectedOwner;

      if (this.isListFetched) return;

      this.isLoading = true;
      this.isListFetched = true;

      if (!this.isUserAdmin) {
        if (!this.userName) {
          return;
        }

        owner = this.userName;
      }

      const params = {
        series: this.currentGroupFilter ? [this.currentGroupFilter] : null,
        category: this.currentCategoryFilter ? [this.currentCategoryFilter] : null,
        trained: this.currentTrainedGroupFilter ? [this.currentTrainedGroupFilter] : null,
      }

      this.finderService.getValidatedList(this.currentPage, this.perPage, this.sort, params, this.selectedOriginTagGuid, owner)
          .finally(() => {
            this.isLoading = false;
            this.isListFetched = false;
          })
          .then((res) => {
            this.items = res.picsets;

            this.totalItems = res.info.total;

            this.checkWaiting();
          })
    },
    getCount(item) {
      return +item.saved ? item.saved : +item.parsed ? item.parsed : '0';
    },
    getDate(date, withTime = false) {
      const value = new Date(date);
      let result = '';
      if (!withTime) {
        result = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
      } else {
        result = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()} ${value.getHours()}:${value.getMinutes()}`;
      }

      return result;
    },
    setPerPage() {
      this.getList();
    },
    setPage(pageNum) {
      this.currentPage = pageNum;
      this.getList();
    },
    // setChecking(item) {
    //   const message = confirm(`Set Checking "${item.query}"?`);
    //   if (message) {
    //     this.finderService.setPicsetChecking(item.guid)
    //         .then(() => {
    //           this.getList();
    //         });
    //   }
    // },
    archive(item) {
      this.isLoading = true;

      const data = {
        guid: item.guid
      }
      this.finderService.createArchive(item.guid, data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.getList();
          });
    },
    remove(item) {
      const message = confirm(`Remove "${item.query}"?`);
      if (message) {
        this.finderService.deletePicset(item.guid)
            .then(() => {
              this.getList();
            });
      }
    },
  }
}
</script>

<style scoped lang="scss">
.archives-list {
  display: flex;
  flex-direction: column;
}

.archives-list__item {
  margin-bottom: 10px;
}

.archives-item {
  border-radius: 5px;
  border: 1px solid #1b1b1b;
  background-color: #ffffff;
}

.archives-item__row {
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 100%;
  padding: 10px 20px 5px 5px;
  border-radius: 5px;
  background-color: #ffffff;
  gap: 5px;
}

.archives-item__title {
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 8px;

  img {
    max-width: 100px;
  }
}

.archives-item__state {
  color: rgba(#000, 0.5);
}

.archives-item__count {
  color: #ff9000;
}

.archives-item__controls {
  margin-left: auto;

  display: flex;
}

.archives-item__button {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 10px;
  }

  cursor: pointer;
  border: none;
  background-color: #ff9000;
  border-radius: 5px;
  padding: 8px 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
}

.archives-item__button--download {
  color: #1b1b1b;

  span {
    display: block;
    font-size: 0.6rem;
    opacity: 0.5;
    white-space: nowrap;
  }
}

.archives-item__button--archive {
  color: #ffffff;
}

.archives-item__button--validate {
  background-color: blue;
  color: #ffffff;
}

.archives-item__button--remove {
  background-color: red;
  color: #ffffff;
}

.archives-item__button--edit {
  background-color: green;
  color: #ffffff;

  span {
    display: block;
    font-size: 0.6rem;
    opacity: 0.5;
    white-space: nowrap;
  }
}

.archives-item__info {
  color: #1b1b1b;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  position: relative;

  b {
    font-size: 18px;
  }
}

.archives-item__info-pin {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff9000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}

.archives-item__info-pin:hover + .archives-item__info-tooltip {
  display: block;
}

.archives-item__info-tooltip {
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(102%);
  background-color: #fff;
  border: 1px solid #ff9000;
  padding: 5px 10px;
  display: none;
}

.archives-list__controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finder-form__select {
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    margin-right: 10px;
  }

  select {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }
}

.archives-item__tags {
  //margin-left: 10px;

  & + & {
    //margin-left: 5px;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tags__item {
  border-radius: 5px;
  padding: 5px 8px;
  background-color: rgba(#1b1b1b, 0.5);
  cursor: pointer;
}

.tags__item--main {
  background-color: gold;
  color: var(--base-text);
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 5px 10px;
}

.categories__item {
  border-radius: 5px;
  padding: 5px 8px;
  background-color: #d5d5d5;
  color: #222222;
}

.archives-item__toggler {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 2px solid #ff9000;
  cursor: pointer;
  position: relative;
}

.archives-item__toggler--active::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 22px;
  border-bottom: 2px solid #222222;
  border-right: 2px solid #222222;
  rotate: 45deg;
  left: 10px;
}

.archives-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 5px;
}

.archives-header > .row {
  width: fit-content;
}

.archives-item__role {
  color: #1b1b1b;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: rgba(pink, 0.6);
  cursor: pointer;

  &--admin {
    background-color: rgba(green, 0.3);
  }
}

.trained-groups {
  display: flex;
  align-items: center;
  gap: 3px;flex-wrap: wrap;
  flex-basis: 100%;
  padding: 3px;
}

.trained-groups__item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;
  background-color: #fff;
  color: #222222;
  border: 1px solid #222;
}


.labels {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 5px 10px;
}

.labels__label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;
  background-color: #fff;
  color: #222222;
  border: 1px solid #222;
  transition: .1s;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    border-color: #777;
    color: #777;
  }

  &--black {
    color: #eee;
    border-color: #222;
    background-color: #222;

    &:hover {
      border-color: #555;
      background-color: #555;
      color: #ffffff;
    }
  }

  &--gray {
    color: #eee;
    border-color: #888;
    background-color: #888;

    &:hover {
      color: #fff;
      border-color: #aaa;
      background-color: #aaa;
    }
  }

  &--highlighted {
    box-shadow: 0 0 3px 1px #ff9000;
  }
}
</style>
