<template>
  <li class="picture-info__label"
      :class="{
            'picture-info__label--active': isActiveLabel,
          }"
      @click="$emit('select')"
  >
    <span>
      {{ label }} {{ count }}
    </span>

    <div class="picture-info__toggle"
         :class="{
          'picture-info__toggle--active': isActiveLabel,
         }"
         @click.stop="toggle"
         v-if="activeIndex >= 0 && !isSinglePerson"
    >
      <svg width="10" height="10">
        <use xlink:href="@/assets/icons.svg#triangle"></use>
      </svg>
    </div>

    <div class="dropdown" v-if="showDropdown && (activeIndex >= 0 || !isSinglePerson)" @click.stop.self="toggle">
      <span class="dropdown__item" @click.stop="$emit('remove', label)">Remove</span>
      <template v-if="group.length">
        <span class="dropdown__title">Replace:</span>
        <span class="dropdown__item"
              v-for="category in group"
              :key="category"
              @click.stop="$emit('replace', { deletingCategory: label, newCategory: category })"
        >
        {{ category }}
      </span>
      </template>
    </div>
  </li>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "ReplaceableLabel",
  props: {
    activeIndex: {
      type: Number,
      default: -1,
    },
    count: {
      type: String,
      default: '',
    },
    isActiveLabel: {
      type: Boolean,
      default: false,
    },
    isSinglePerson: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
      required: true,
    },
    group: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'select',
    'remove',
    'replace',
  ],
  data: () => ({
    showDropdown: false,
  }),
  methods: {
    toggle() {
      if (this.isActiveLabel) return;
      this.showDropdown = !this.showDropdown;
    }
  }
})
</script>

<style lang="scss">
.picture-info__label {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 1px 3px;
  border-radius: 5px;
  border: 1px solid #222;
  cursor: pointer;
  order: 0;
  position: relative;

  & > span:hover {
    color: #777;
    border-color: #777;
  }

  &--active {
    background: #222;
    color: #fff;
  }

  &--active > span:hover {
    color: #fff;
    background-color: #777;
  }
}

.picture-info__toggle {
  border-radius: 50%;
  rotate: 180deg;
  translate: 0 -1px;
  fill: #222;
  padding: 1px;
  position: relative;
  transition: rotate .4s ease-out;

  &::before {
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.picture-info__toggle--active {
  pointer-events: none;
  z-index: -1;
}

.picture-info__add-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  min-width: 19px;
  line-height: 1px;
  background-color: #ddd;
  border-radius: 50%;
  align-self: center;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background-color: #ccc;
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: calc(100% + 3px);
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 1px 2px 5px 1px rgba(#222, .5);
  min-width: 100%;
  border-radius: 2px;
  z-index: 2;
  color: #222;

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 200vw;
    height: 200vh;
    left: -50vw;
    top: -50vh;
    z-index: 0;
    cursor: default;
  }
}

.dropdown > span {
  padding: 5px 15px;
  white-space: nowrap;
  position: relative;
  z-index: 2;
}

.dropdown__title {
  cursor: default;
  font-weight: bold;
  font-style: italic;
  border-top: 1px solid #ccc;
}

.dropdown__item:hover {
  background-color: #eee;
  cursor: pointer;
}
</style>
