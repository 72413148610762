<template>
  <div class="overlay"
       v-if="isShow"
       @click.self="close"
  >
    <div class="popup">
      <div class="popup__head">
        Login
      </div>
      <div class="popup__body">
        <form @submit.prevent="onSubmit" class="form">
          <div class="form__item">
            <input type="text"
                   class="form__input"
                   name="login"
                   v-model="loginValue"
                   placeholder="Login"
            >
          </div>

          <div class="form__item">
            <input type="password"
                   class="form__input"
                   name="password"
                   v-model="passwordValue"
                   placeholder="Password"
            >
          </div>

          <button type="submit" class="form__button" :disabled="isDisabled">
            Enter
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user-service";

export default {
  name: 'AppPopupLogin',
  data: () => ({
    loginValue: '',
    passwordValue: '',
  }),
  created() {
    this.userService = new UserService();
  },
  computed: {
    isDisabled() {
      return !this.loginValue || !this.passwordValue;
    },
    isShow() {
      return this.$store.state.isLoginShow;
    }
  },
  methods: {
    close() {
      this.$store.commit('setLoginShowStatus', false);
    },
    onSubmit() {
      const data = {
        login: this.loginValue,
        password: this.passwordValue
      }

      this.userService.login(data)
          .then(res => {
            this.onUserLogin(res);

            this.close();
          })
    },
    onUserLogin(data) {
      this.userService.setToken(data);
      this.userService.setUserName(this.loginValue);
      this.$store.commit('setAuth', true);
    }
  }
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  background-color: rgba(#000, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.popup {
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 5px;
  width: 300px;
  max-width: 100%;
}

.popup__head {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}

.form {

}

.form__item {
  margin-bottom: 15px;
}

.form__input {
  width: 100%;
  height: 40px;
  border: 2px solid #ff9000;
  border-radius: 5px;
  outline: none;
  padding: 0 10px;

  &:focus {
    border-color: #1b1b1b;
  }
}

.form__button {
  width: 100%;
  height: 40px;
  background-color: #ff9000;
  border: 2px solid #ff9000;
  //border: none;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: no-drop;
  }

  &:not([disabled]):hover {
    color: #1b1b1b;
    background-color: rgba(#ff9000, 0.5);
  }
}
</style>
