<template>
  <div class="categories"
       :class="{'loading': isLoading}"
  >
    <div class="categories__header">
      <template v-if="!isAddNewCategoryMode">
        <div class="categories__header-title" @click="setAddNewCategoryStatus(true)">
          + Add new categories
        </div>
      </template>

      <template v-else>
        <div class="categories__header-input">
          <input type="text" v-model="newCategoryValue" @keydown.enter="onKeyEnterNewCategory" autofocus>

          <button type="button" class="button accept" :disabled="!newCategoryValue.trim()" @click="addNewCategory">
            Create
          </button>
          <button type="button" class="button reject" @click="setAddNewCategoryStatus(false)">Cancel</button>
        </div>
      </template>
    </div>

    <div class="categories__content">
      <div class="categories__search">
        <input type="text" placeholder="Search category" v-model="query">
      </div>

      <!--    Search params   -->
      <AppGroupCategoriesControls
          @select-letter="onLetterChange"
          @select-type="onTypeChange"
      />

      <div class="categories__group" v-if="selectedCategoriesList.length">
        <span class="categories__title">Selected categories</span>
        <ul class="categories__list label">
          <li
              class="categories__label categories__label--selected"
              :class="{'categories__label--edit': category.isEditTitle}"
              :key="index"
              v-for="(category, index) in selectedCategoriesList"
              @click="toggleSelectedCategory(category)"
          >
            <template v-if="!category.isEditTitle">
              <span class="categories__label-title">
                {{ category.title }}
              </span>
              <button class="button accept" type="button"
                      @click.stop="editCategoryTitle(category)"
              >
                <svg width="20" height="20">
                  <use xlink:href="@/assets/icons.svg#edit"></use>
                </svg>
              </button>
              <button class="button reject" type="button"
                      @click.stop="removeCategory(category.guid)"
              >
                <svg width="20" height="20">
                  <use xlink:href="@/assets/icons.svg#remove"></use>
                </svg>
              </button>
            </template>
            <template v-else>
              <input type="text" v-model="category.title"
                     @click.stop
                     @keydown.enter="onKeyEnterSaveCategoryTitle(category)" autofocus>
              <button class="button accept" type="button"
                      :disabled="!category.title.trim()"
                      @click.stop="saveCategoryTitle(category)"
              >
                <svg width="20" height="20">
                  <use xlink:href="@/assets/icons.svg#save"></use>
                </svg>
              </button>
              <button class="button reject" type="button"
                      @click.stop="cancelCategoryTitle(category)"
              >
                <svg width="20" height="20">
                  <use xlink:href="@/assets/icons.svg#cross"></use>
                </svg>
              </button>
            </template>
          </li>
        </ul>
      </div>


      <ul class="categories__groups" v-if="Object.keys(groupedByLetter).length">
        <li class="categories__group" v-for="(group, key) in groupedByLetter" :key="key">
          <ul class="categories__list">
            <li class="categories__inline-title" :data-count="`(${group.length}):`">
              {{ key === '_' ? '#' : key }}
            </li>
            <li
                class="categories__label"
                :class="{
                  'categories__label--selected': isSelectedCategory(category),
                }"
                v-for="category in group"
                :key="category.guid"
                @click="toggleSelectedCategory(category)"
            >
              <span class="categories__label-title">
                {{ category.title }}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AppGroupCategoriesControls from "@/modules/categories/components/GroupCategoriesControls";

export default {
  name: 'AppCategoriesList',
  components: {AppGroupCategoriesControls},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    selectedCategoriesList: {
      ype: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['addNewCategory', 'updateCategoryTitle', 'toggleSelectedCategory', 'setFilteredCategories'],
  data: () => ({
    isAddNewCategoryMode: false,
    newCategoryValue: '',

    letter: 'All',
    groupType: 'ungrouped',
    query: '',
  }),
  computed: {
    groupedByLetter() {
      let groupedLabels = {};

      this.filteredItems.forEach(item => {
        const letter = item.title[0]?.match(/\d/) || item.title[0]?.match(/\W/) ? '_' : item.title[0];
        groupedLabels[letter] ? groupedLabels[letter].push(item) : (groupedLabels[letter] = [item]);
      });

      groupedLabels = Object.fromEntries(Object.entries(groupedLabels).sort()); // Sorting keys A -> Z

      return groupedLabels;
    },
    filteredItems() {
      const filtered = this.list
          .filter(item => {
            switch (this.groupType) {
              case 'grouped':
                return item.series?.length;
              case 'ungrouped':
                return !item.series.length;
              case 'all':
                return item;
            }
          })
          .filter(item => !this.query || item.title.toLowerCase().includes(this.query.toLowerCase()))
          .filter(item => this.letter === 'All' || item.title[0].toLowerCase() === this.letter.toLowerCase());

      this.$emit('setFilteredCategories', filtered);
      return filtered;
    },
  },
  methods: {
    onKeyEnterSaveCategoryTitle(item) {
      if (item.title.trim()) {
        this.saveCategoryTitle(item);
      }
    },
    saveCategoryTitle(item) {
      item.isEditTitle = false;
      this.$emit('updateCategoryTitle', item);
    },
    editCategoryTitle(item) {
      item.isEditTitle = true;
      item.tempTitle = item.title;
    },
    removeCategory(guid) {
      this.$emit('removeCategory', guid);
    },
    cancelCategoryTitle(item) {
      item.isEditTitle = false;
      item.title = item.tempTitle;
    },
    onLetterChange(letter) {
      this.letter = letter;
    },
    onTypeChange(type) {
      this.groupType = type;
    },
    isSelectedCategory(item) {
      return this.selectedCategoriesList.find(el => el.guid === item.guid);
    },
    toggleSelectedCategory(item) {
      this.$emit('toggleSelectedCategory', item);
    },
    setAddNewCategoryStatus(status) {
      this.isAddNewCategoryMode = status;
    },
    onKeyEnterNewCategory() {
      if (this.newCategoryValue.trim()) {
        this.addNewCategory();
      }
    },
    addNewCategory() {
      this.$emit('addNewCategory', this.newCategoryValue.trim());
      this.newCategoryValue = '';
    }
  }
}
</script>

<style scoped lang="scss">
.categories {
  box-shadow: 0 0 5px rgba(#000, 0.3);
  background-color: #fff;

  border-radius: 5px;

  max-height: calc(100vh - 58px - 20px * 2);
  overflow: auto;
}

.categories__header-title {
  background-color: #ff9000;
  padding: 10px 20px;
  cursor: pointer;
  height: 38px;

  &:hover {
    background-color: rgba(#ff9000, 0.8);
  }
}

.categories__content {
  padding: 20px;

  & > .categories__group {
    margin-bottom: 5px;
  }
}

.categories__header-input {
  height: 38px;

  display: flex;
}

.categories__header-input input {
  flex-grow: 1;
  border: 2px solid #ff9000;
  outline: none;
  border-radius: 5px 0 0 0;
  padding: 0 20px;
  font-family: inherit;

  &:focus {
    border-color: #333;
  }
}

.categories__groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
}

.categories__group {
  display: flex;
  flex-direction: column;
  padding-bottom: 5px;

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
}

.categories__list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  padding: 0;
  margin: 0;
}

.categories__inline-title {
  display: inline-flex;
  font-weight: bold;
  margin-right: 5px;
  font-size: 14px;
  align-items: baseline;
  text-transform: capitalize;

  &::after {
    content: attr(data-count);
    color: #777;
    margin-left: 3px;
    font-size: 0.9rem;
    font-weight: normal;
  }
}

.categories__label {
  justify-content: center;
  padding: 0 8px;
  background-color: #d5d5d5;
  color: #222;
  cursor: pointer;
  transition: 0.1s ease-out;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  height: 32px;
  fill: #ffffff;

  &:hover {
    background-color: rgba(#ff9000, 0.7);
  }

  button {
    height: 100%;
    width: 32px;
    padding: 0;
    background-color: transparent !important;
    border-left: 1px solid #d5d5d5;

    &:hover {
      background-color: rgba(#ff9000, 1) !important;
    }
  }
}

.categories__label--selected {
  background-color: #ff9000;
  color: #ffffff;
  position: relative;

  button + button {
    margin-right: -8px;
  }
}

.categories__label--edit {
  padding-left: 0;

  input {
    height: 100%;
    width: 100px;
    outline: none;
    font-family: inherit;
    border: 1px solid #ff9000;
    border-right: none;
    padding: 0 5px;
  }
}

.categories__label-title {
  margin-right: 5px;
}

.categories__title {
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  display: inline-flex;
  margin-bottom: 5px;
}

.categories__search {
  margin-bottom: 10px;

  input {
    width: 100%;
    height: 36px;
    padding: 0 10px;
    border: 1px solid rgba(#ff9000, 0.8);
    border-radius: 3px;
    outline: none;

    &:focus {
      border-color: #ff9000;
    }
  }
}
</style>
