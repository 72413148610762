<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__menu">
        <router-link class="header__menu-link"
                     active-class="header__menu-link--active"
                     :class="{
                      'header__menu-link--active': $route.path?.search('/picset') >= 0
                     }"
                     to="/">
          Preparing
        </router-link>

        <router-link class="header__menu-link"
                     active-class="header__menu-link--active"
                     to="/archives">
          Multi Archives
        </router-link>
        <router-link class="header__menu-link"
                     active-class="header__menu-link--active"
                     :class="{
                      'header__menu-link--active': $route.path?.search('/checked') >= 0
                     }"
                     to="/checking"
                     v-if="isUserAdmin"
        >
          Checking
        </router-link>
        <!--    v-if="isUserAdmin || checkValidRole('review')"-->
        <router-link class="header__menu-link"
                     active-class="header__menu-link--active"
                     :class="{
                      'header__menu-link--active': $route.path?.search('/validated') >= 0
                     }"
                     to="/validated"
                     v-if="isUserAdmin"
        >
          Validated
        </router-link>
        <!--    v-if="isUserAdmin || checkValidRole(validRolesList)"-->
        <router-link class="header__menu-link"
                     active-class="header__menu-link--active"
                     to="/categories"
                     v-if="isUserAdmin"
        >
          Categories
        </router-link>

        <router-link class="header__menu-link"
                     active-class="header__menu-link--active"
                     to="/users"
                     v-if="isUserAdmin"
        >
          Users
        </router-link>
      </div>


      <div class="header__user-panel">
        <div class="header__user"
             v-if="userName"
        >
          User: <b>{{ userName }}</b>
        </div>

        <div class="header__login" v-if="!isLoginPage">
          <div @click="logout"
               class="header__control"
               v-if="isAuth"
          >
            Logout
          </div>
          <div @click="login"
               class="header__control"
               v-else
          >
            Login
          </div>
        </div>
      </div>

    </div>
  </header>
</template>

<script>
import {mapState} from 'vuex';
import UserService from "@/services/user-service";

export default {
  name: 'AppHeader',
  computed: {
    isLoginPage() {
      return this.$route.name === 'Login';
    },
    ...mapState([
      'userName',
      'isAuth',
      'isUserAdmin',
      'userRoles'
    ]),
  },
  data: () => ({
    validRolesList: ['review']
  }),
  methods: {
    checkValidRole(...data) {
      // TODO: сделать эту функцию глобальной
      /**
       * data can be:
       * ['string'] or Proxy.['string']
       **/
      let result = false;

      if (this.userRoles.length) {
        for (let i = 0; i < data.length; i++) {
          /**
           * el.name === data[i] || el.name === data[0][i]
           * запись для работы с Array и Proxy.Array
           **/
          const find = this.userRoles.find((el) => el.name === data[i] || el.name === data[0][i]);
          if (find) {
            return true;
          }
        }
      }

      return result;
    },
    login() {
      this.$store.commit('setLoginShowStatus', true);
    },
    logout() {
      UserService.logout();
      this.$store.commit('setAuth', false);
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/mixins";

.header {
  padding: 20px 0;
  background-color: #ff9000;
  position: sticky;
  top: 0;
  z-index: 11;
}

.header__wrapper {
  @include container;
  display: flex;
  justify-content: space-between;
}

.header__menu {
  display: flex;
}

.header__menu-link {
  text-decoration: none;
  color: inherit;
  //padding: 0 10px;

  &:not(:last-child) {
    margin-right: 20px;
  }
}

.header__menu-link:not(.header__menu-link--active):hover {
  text-decoration: underline;
}

.header__menu-link--active {
  font-weight: bold;
  cursor: default;
}

.header__control {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.header__user-panel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header__user b {
  font-size: 18px;
  line-height: 1;
}
</style>
