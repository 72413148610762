<template>
  <div class="header">
    <AppFilterForm :tags="tagsList"
                   :origin-tags="originTagsList"
                   :is-loading="isLoading"
                   :users-list="usersList"
                   v-model:selectedOriginTagGuid="selectedOriginTagGuid"
                   v-model:selectedSecondTagTitle="selectedSecondTagTitle"
                   v-model:selectedOwner="selectedOwner"
    ></AppFilterForm>
  </div>

  <div class="archives-list"
       :class="{'loading': isLoading}"
  >
    <AppMultiArchivesItem v-for="(item, index) in items"
                          :item="item"
                          :index="index"
                          :key="index"
                          @remove="removeArchive"
                          @filter-origin="filterByOriginTag"
                          @filter-second="filterBySecondTag"
                          @filter-owner="filterByOwner"
    ></AppMultiArchivesItem>
  </div>

  <div class="finder-form">
    <div class="finder-form__select">
      <span>Count</span>
      <select v-model="perPage" @change="setPerPage">
        <option v-for="(item, index) in perPageOptions"
                :key="index"
                :value="item">{{ item }}
        </option>
      </select>
    </div>

    <vue-awesome-paginate
        :total-items="totalItems"
        :items-per-page="perPage"
        :max-pages-shown="perPage"
        v-model="currentPage"
        :on-click="setPage"
    ></vue-awesome-paginate>
  </div>
</template>

<script>
import ArchivesService from "@/services/archives-service";
import UsersService from "@/services/users-service";
import FinderService from "@/services/finder-service";

import AppMultiArchivesItem from "@/modules/multi-archives/multi-archive-item/MultiArhiveItem";
import AppFilterForm from "@/modules/picsets-list/filter-form/FilterForm";
import {mapState} from "vuex";

export default {
  name: 'AppMultiArchivesList',
  components: {
    AppMultiArchivesItem,
    AppFilterForm
  },
  data: () => ({
    tagsList: [],
    originTagsList: [],
    usersList: [],
    selectedOriginTagGuid: '',
    selectedSecondTagTitle: '',
    selectedOwner: '',


    currentPage: 1,
    totalItems: 0,
    perPage: 50,
    perPageOptions: [50, 100, 200],

    archivesService: null,
    isLoading: false,

    items: [],

    reloadInterval: null,
    reloadIntervalTime: 10000,
  }),
  created() {
    this.archivesService = new ArchivesService();
    this.usersService = new UsersService();
    this.finderService = new FinderService();

    this.getList();

    this.getTagsList();
    this.getOriginTagsList();
    this.getUsersList();
  },
  computed: {
    ...mapState([
      'isUserAdmin',
      'userName'
    ]),
  },
  watch: {
    selectedOriginTagGuid() {
      this.onTagsFilterChange();
    },
    selectedSecondTagTitle() {
      this.onTagsFilterChange();
    },
    selectedOwner() {
      this.onOwnerFilterChange();
    },
    userName() {
      this.getList();
    }
  },
  methods: {
    filterByOriginTag(guid) {
      this.selectedOriginTagGuid = guid;
    },
    filterBySecondTag(title) {
      this.selectedSecondTagTitle = title;
    },
    filterByOwner(title) {
      this.selectedOwner = title;
    },
    onTagsFilterChange() {
      this.getList();
    },
    onOwnerFilterChange() {
      this.getList();
    },
    getOriginTagsList() {
      this.finderService.getOriginTagsList()
          .then(res => {
            this.originTagsList = res.origins;
          })
    },
    getTagsList() {
      this.finderService.getTagsList()
          .then(res => {
            this.tagsList = res.tags;
          })
    },
    getUsersList() {
      this.usersService.getUsersList()
          .then(res => {
            this.usersList = res.items;
          })
    },
    setPerPage() {
      this.getList();
    },
    setPage(pageNum) {
      this.currentPage = pageNum;
      this.getList();
    },
    checkWaiting() {
      const find = this.items.find(
          (item) => item.state === 'ARCHIVE'
              || item.state === 'TAKEN_ARCHIVE'
              || item.state === 'ARCHIVING'
      )

      if (find) {
        if (!this.reloadInterval) {
          this.reloadInterval = setInterval(() => {
            this.getList();
          }, this.reloadIntervalTime)
        }
      } else {
        clearInterval(this.reloadInterval);
      }
    },
    getList() {
      let owner = this.selectedOwner;
      this.isLoading = true;
      if (!this.isUserAdmin) {
        if (!this.userName) {
          return;
        }

        owner = this.userName;
      }

      const params = {
        tags: this.selectedSecondTagTitle ? [this.selectedSecondTagTitle] : null
      }

      this.archivesService.getMultiArchives(this.currentPage, this.perPage, this.sort, params, this.selectedOriginTagGuid, owner)
          .finally(() => this.isLoading = false)
          .then(res => {
            this.totalItems = res.info.total;
            this.items = res.archives;

            this.checkWaiting();
          })
    },
    removeArchive(guid) {
      const message = confirm(`Remove multi archive?`);
      if (message) {
        this.archivesService.deleteArchive(guid)
            .then(() => {
              this.getList();
            });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.finder-form__select {
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    margin-right: 10px;
  }

  select {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }
}

.archives-list {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);

  & > * {
    min-width: 0;
  }
}

.finder-form {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.header > * {
  margin-left: auto;
  margin-bottom: 20px;
}
</style>
