<template>
  <AppHeader></AppHeader>
  <main>
    <router-view/>
  </main>

  <AppNotify></AppNotify>

  <AppPopupLogin></AppPopupLogin>
</template>

<script>
import UserService from "@/services/user-service";
import UsersService from "@/services/users-service";

import AppHeader from "@/components/Header";
import AppPopupLogin from "@/components/PopupLogin";
import AppNotify from "@/components/AppNotify";

export default {
  name: 'App',
  components: {
    AppNotify,
    AppPopupLogin,
    AppHeader,
  },
  data: () => ({
    usersService: null
  }),
  created() {
    this.$store.commit('setAuth', UserService.isAuth());

    this.usersService = new UsersService();
  },
  mounted() {
    if (UserService.isAuth()) {
      this.getSelfData();
    }
  },
  methods: {
    getSelfData() {
      this.usersService.getSelfData()
          .then(res => {
            this.$store.commit('setUserRoles', res.roles);
            this.$store.commit('setUserName', res.login);
            this.$store.commit('setUserAdmin', !!res.roles.find(el => el.name === 'admin'));
          })
    }
  }
}
</script>

<style lang="scss">
@import "./styles/core";
@import "./styles/mixins";
@import "./styles/pagination";

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: var(--bg-color);
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, 'Noto Sans Armenian', 'Noto Sans Bengali', 'Noto Sans Cherokee', 'Noto Sans Devanagari', 'Noto Sans Ethiopic', 'Noto Sans Georgian', 'Noto Sans Hebrew', 'Noto Sans Kannada', 'Noto Sans Khmer', 'Noto Sans Lao', 'Noto Sans Osmanya', 'Noto Sans Tamil', 'Noto Sans Telugu', 'Noto Sans Thai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--base-text);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  min-height: calc(100vh - 58px);
  padding: 20px;
}

h1, h2 {
  font-size: 1.5rem;
  line-height: 34px;
  margin: 0 0 10px;

  * + & {
    margin: 20px 0;
  }
}

.loading {
  position: relative;
}

.loading::before {
  //opacity: 0.5;
  //pointer-events: none;

  top: 0;
  left: 0;
  content: '';
  //position: absolute;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  background-image: url(@/assets/spinner.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}

.loading-item::before {
  top: 0;
  left: 0;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  background-image: url(@/assets/spinner.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40px;
}

.hidden {
  display: none !important;
}

.error {
  color: red !important;
}

.correct {
  color: green !important;
}

.common {
  color: rgba(0, 0, 0, 0.5) !important;
}

.taken {
  color: goldenrod !important;
}

.processing {
  color: blue !important;
}

.button {
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.accept {
    background-color: green;
  }

  &.reject {
    background-color: red;
  }

  &:hover {
    opacity: 0.8;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
}
</style>
