import HttpService from "@/services/http-service";
import ConfigStorageService from "@/services/config-storage-service";
import UserService from "@/services/user-service";

export default class UsersService extends HttpService {
    constructor() {
        super();
        this.endpoint = ConfigStorageService.get('apiEndpoint')
        this.token = UserService.getToken();
        this.headers = {
            Authorization: `bearer ${this.token}`
        };
    }

    getSelfData() {
        const url = `self`;

        return this.http(url, 'GET', this.headers);
    }

    createUser(data) {
        const url = `user`;

        return this.http(url, 'PUT', this.headers, JSON.stringify(data));
    }

    getUsersList(data) {
        const url = `user`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    deleteUser(guid) {
        const url = `user/${guid}`;

        return this.http(url, 'DELETE', this.headers);
    }

    getUserData(guid) {
        const url = `user/${guid}`;

        return this.http(url, 'GET', this.headers);
    }

    setUserPassword(data, guid) {
        // data = {
        //     "guid": "c92514be-cb53-4b47-8ede-55058ace5c81",
        //     "password": "nC82JpRPLx61901c"
        // }

        const url = `user/${guid}/password`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }
}