<template>
  <section class="group-selection-control">
    <!--    Union     -->
    <span
        class="group-selection-control__button group-selection-control__button--rotate-180"
        :class="{
        'group-selection-control__button--disabled': !isSelectedGroup || !isSelectedCategory || isLoading,
      }"
        title="Add selected labels to pictures"
        @click="setCategoriesToGroup"
    >
      <svg width="20" height="20">
        <use xlink:href="@/assets/icons.svg#arrow-right"></use>
      </svg>
    </span>

    <!--    Separator     -->
    <span class="group-selection-control__separator"/>
    <span class="group-selection-control__button"
          :class="{
            'group-selection-control__button--disabled': !isSelectedGroup || isLoading,
          }"
          title="Remove all categories from group"
          @click="removeAllCategoriesFromGroup"
    >
      <svg width="20" height="20">
        <use xlink:href="@/assets/icons.svg#erase"></use>
      </svg>
    </span>

    <span class="group-selection-control__separator"/>

    <!--    Select all labels     -->
    <span
        class="group-selection-control__button group-selection-control__button--select-all"
        :class="{
        'group-selection-control__button--disabled': isMaxCategoriesSelected || isLoading,
      }"
        title="Select all categories"
        @click="selectAll"
    >
      <svg width="20" height="20" v-if="!isMaxCategoriesSelected">
        <use xlink:href="@/assets/icons.svg#selection"></use>
      </svg>

      <svg width="20" height="20" v-else>
        <use xlink:href="@/assets/icons.svg#selected"></use>
      </svg>
    </span>

    <!--    Unselect all labels     -->
    <span
        class="group-selection-control__button group-selection-control__button--unselect-all"
        :class="{
        'group-selection-control__button--disabled': !isSelectedCategory || isLoading,
      }"
        title="Unselect all categories"
        @click="unselectAll"
    >
      <svg width="20" height="20">
        <use xlink:href="@/assets/icons.svg#unselection"></use>
      </svg>
    </span>
  </section>
</template>

<script>

export default {
  name: 'AppGroupSelectionControl',
  props: {
    isSelectedGroup: {
      type: Boolean,
      default: false
    },
    isSelectedCategory: {
      type: Boolean,
      default: false
    },
    isMaxCategoriesSelected: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['setCategoriesToGroup', 'selectAllCategories', 'unselectAllCategories', 'removeAllCategoriesFromGroup'],
  methods: {
    setCategoriesToGroup() {
      this.$emit('setCategoriesToGroup');
    },
    selectAll() {
      this.$emit('selectAllCategories');
    },
    unselectAll() {
      this.$emit('unselectAllCategories');
    },
    removeAllCategoriesFromGroup() {
      this.$emit('removeAllCategoriesFromGroup');
    }
  },
};
</script>

<style scoped lang="scss">
.group-selection-control {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  padding: 8px;
}

.group-selection-control__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ff9000;
  cursor: pointer;

  &:hover {
    background-color: rgba(#ff9000, 0.8);
  }

  &--disabled {
    background-color: #bbb;
    filter: grayscale(1);
    pointer-events: none;
  }

  & > svg {
    fill: #ffffff;
    max-height: 100%;
  }

  &--rotate-180 > svg {
    rotate: 180deg;
  }
}

.group-selection-control__separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-block: 10px;
}
</style>
