import { createStore } from 'vuex';

import Groups from '@/store/modules/Groups';
import editPicturesInfo from "@/store/modules/editPictures";

const store = createStore({
  state: {
    userName: '',
    userRoles: [],
    rolesList: [],
    isUserAdmin: false,
    isAuth: false,
    isLoginShow: false,
    maxLabelsForPerson: 0,
  },
  mutations: {
    setLoginShowStatus(state, data) {
      state.isLoginShow = data;
    },
    setUserName(state, data) {
      state.userName = data;
    },
    setAuth(state, data) {
      state.isAuth = data;
    },
    setUserRoles(state, data) {
      state.userRoles = data;
    },
    setUserAdmin(state, data) {
      state.isUserAdmin = data;
    },
    setRolesList(state, data) {
      state.rolesList = data;
    },
    setMaxLabelsForPerson(state, count) {
      state.maxLabelsForPerson = count;
    }
  },
  getters: {
    getLoginShowStatus: state => state.isLoginShow,
    getUserName: state => state.isLoginShow,
    getUserRoles: state => state.userRoles,
    getIsUserAdmin: state => state.isUserAdmin,
    getRolesList: state => state.rolesList,
    getMaxLabelsForPerson: state => state.maxLabelsForPerson,
  },
  actions: {

  },
  modules: {
    editPicturesInfo: editPicturesInfo,
    groups: Groups,
  }
});

export default store;
