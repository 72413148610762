<template>
  <div class="persons-navigation"
  >
    <span class="persons-navigation__item persons-navigation__item--all"
          :class="{
            'persons-navigation__item--active': activeIndex === -1,
          }"
          @click="onClickAll"
    >
      All
    </span>
    <span class="persons-navigation__item"
          :class="{
            'persons-navigation__item--active': activeIndex === person.originalIndex,
          }"
          v-for="(person, index) in persons"
          :key="person.gender + index"
          @click="$emit('update:active-index', person.originalIndex)"
    >
      {{ person.gender[0] + person.index }}
    </span>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import index from "vuex";

export default defineComponent({
  name: "AppPersonsPagination",
  props: {
    activeIndex: {
      type: Number,
      default: -1,
    },
    persons: {
      type: Array,
      default: () => [],
      required: true,
    }
  },
  emits: [
      'update:active-index',
      'clearActiveTag',
  ],
  computed: {
    count() {
      return this.persons.length;
    },
    index() {
      return index
    },
    isMultiplePersons() {
      return this.persons.length > 1;
    }
  },
  methods: {
    onClickAll() {
      this.$emit('update:active-index', -1);
      this.$emit('clearActiveTag');
    }
  }
})
</script>

<style lang="scss">
.persons-navigation {
  font-size: 11px;
  background-color: #fff;
  width: fit-content;
  border: 1px solid #ddd;
  min-height: 19px;

  &--hidden {
    border: none;

    * {
      display: none !important;
      border: none;
    }
  }
}

.persons-navigation__item {
  display: inline-block;
  text-transform: capitalize;
  padding: 2px 4px;
  cursor: pointer;

  & + & {
    border-left: 1px solid #ddd;
  }

  &--active {
    background-color: #ddd;
  }

  &--active:not(&--all) {
    cursor: default;
    pointer-events: none;
  }
}
</style>
