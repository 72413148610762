import HttpService from "@/services/http-service";
import ConfigStorageService from "@/services/config-storage-service";
import UserService from "@/services/user-service";

export default class RolesService extends HttpService {
    constructor() {
        super();
        this.endpoint = ConfigStorageService.get('apiEndpoint')
        this.token = UserService.getToken();
        this.headers = {
            Authorization: `bearer ${this.token}`
        };
    }

    createRole(data) {
        // data = "role": {
        //     "name": "test role",
        //         "title": "test role title",
        //         "description": "test role description"
        // }

        const url = `role`;

        return this.http(url, 'PUT', this.headers, JSON.stringify(data));
    }

    getRolesList(data) {
        if (!data) {
            data = {
                pagination: {
                    "page": 1,
                    "limit": 0, // по умолчанию 0, тк вижу что возвращает буд-то все данные
                    "order": null
                }
            }
        }

        const url = `role`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    deleteRole(guid) {
        const url = `role/${guid}`;

        return this.http(url, 'DELETE', this.headers);
    }
}