import HttpService from "@/services/http-service";
import UserService from "@/services/user-service";

export default class CategoriesService extends HttpService {
    constructor() {
        super();
        this.token = UserService.getToken();
        this.headers = {
            Authorization: `bearer ${this.token}`
        };
    }

    // Получить список категорий
    getCategoriesList() {
        const url = `category?_order_by=title`;

        return this.http(url, 'POST', this.headers);
    }

    // Добавить категорию
    createCategory(data) {
        const url = `category`;

        return this.http(url, 'PUT', this.headers, JSON.stringify(data));
    }

    // Обновить название категории
    updateCategory(guid, data) {
        const url = `category/${guid}`;

        return this.http(url, 'PATCH', this.headers, JSON.stringify(data));
    }

    // Удалить категорию
    deleteCategory(guid) {
        const url = `category/${guid}`;

        return this.http(url, 'DELETE', this.headers);
    }

    // Получить категорию (не используется)
    getCategory(guid) {
        const url = `category/${guid}`;

        return this.http(url, 'GET', this.headers);
    }
}