<template>
<div class="changes-controls"
     v-if="getNewPicturesList.size && mustShowSticky"
     :class="{
       'changes-controls--sticky': isSticky
     }"
>
  <div class="changes-controls__loader" v-if="isLoading" />
  <template v-else>
    <div class="changes-controls__button" @click="save">
      Save
    </div>

    <div class="changes-controls__button changes-controls__button--red" @click="reload">
      Cancel
    </div>
  </template>
</div>
</template>

<script>
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from "vuex";
import {debounce} from "throttle-debounce";

export default defineComponent({
  name: "AbstractChangesControls",
  props: {
    isSticky: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    isLoading: false,
    showSticky: false,
  }),
  computed: {
    ...mapGetters(['getNewPicturesList']),
    mustShowSticky() {
      if (!this.isSticky) return true;
      return this.showSticky;
    }
  },
  mounted() {
    window.addEventListener('scroll', debounce(200, this.onScroll).bind(this));
  },
  methods: {
    ...mapActions(['savePicturesInfo']),
    reload() {
      location.reload();
    },
    onScroll() {
      this.showSticky = window.scrollY > 200;
    },
    save() {
      this.isLoading = true;
      this.savePicturesInfo().finally(() => {
        this.isLoading = false;
      })
    }
  },
  unmounted() {
    window.removeEventListener('scroll', debounce(200, this.onScroll).bind(this));
  },
})
</script>

<style lang="scss">
.changes-controls {
  display: flex;
  width: 150px;
  height: 32px;
  gap: 5px;
  margin-left: auto;
}

.changes-controls--sticky {
  position: fixed;
  right: 50px;
  bottom: 50px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #222;
  z-index: 5;
  padding: 5px;
  width: unset;
  height: unset;
}

.changes-controls__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 12px;
  font-weight: bold;
  color: #fff;
  background-color: forestgreen;
  border-radius: 5px;
  cursor: pointer;

  .changes-controls--sticky & {
    padding: 15px 25px;
  }

  &:hover {
    background-color: rgba(forestgreen, .8);
  }

  &--red {
    background-color: crimson;
  }

  &--red:hover {
    background-color: rgba(crimson, .8);
  }
}

.changes-controls__loader {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 5px solid rgba(darkblue, .1);
  border-right-color: darkblue;
  pointer-events: none;

  animation-name: spinner;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  0% {
    rotate: 0;
  }

  100% {
    rotate: 360deg;
  }
}

</style>
