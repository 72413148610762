import HttpService from "@/services/http-service";

export default class UserService extends HttpService {
    constructor() {
        super();
        this.token = null;
        this.userName = null;
    }

    static isAuth() {
        return !!this.getToken();
    }

    setToken(data) {
        localStorage.setItem('token', data.token);
        this.token = data.token;
    }

    static getToken() {
        return this.token || localStorage.getItem('token');
    }

    setUserName(name) {
        localStorage.setItem('name', name);
        this.userName = name;
    }

    static getUserName() {
        return this.userName || localStorage.getItem('name');
    }

    login(data) {
        const url = `login`;

        return this.http(url, 'POST', {}, JSON.stringify(data));
    }

    static logout() {
        localStorage.removeItem('name');
        localStorage.removeItem('token');
        this.token = null;
        this.userName = null;
        window.location = '/login';
    }
}