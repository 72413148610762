<template>
  <div class="users"
       :class="{'loading': isLoading}"
  >
    <div class="users__header">
      <template v-if="!isAddNewUserMode">
        <div class="users__header-title" @click="setAddNewUserStatus(true)">
          + Add new user
        </div>
      </template>

      <template v-else>
        <div class="users__header-input">
          <input placeholder="Login" type="text" v-model="newUserValue" @keydown.enter="onKeyEnterNewUser" autofocus>
          <input placeholder="Password" type="text" v-model="newUserPasswordValue">
          <select v-model="newUserRoleValue">
            <option value="">
              Select role
            </option>
            <option v-for="role in rolesList"
                    :key="role.guid"
                    :value="role.name"
            >
              {{ role.title }}
            </option>
          </select>

          <button type="button" class="button accept" :disabled="!newUserValue.trim() || !newUserPasswordValue.trim() || !newUserRoleValue"
                  @click="addNewUser">
            Create
          </button>
          <button type="button" class="button reject" @click="setAddNewUserStatus(false)">Cancel</button>
        </div>
      </template>
    </div>

    <ul class="users__content">
      <li v-for="user in users"
          :key="user.guid"
          class="users__item user"
      >
        <div class="user__title">
          {{ user.login }}
        </div>

        <template v-if="user.roles.length">
          <div class="user__role"
               v-for="role in user.roles"
               :key="role.guid"
          >
            {{ role.title }}
          </div>
        </template>

        <div class="user__password"
             v-if="user.isEditPassword"
        >
          <input type="text"
                 placeholder="type new password"
                 v-model="user.password"
          >
          <button type="button"
                  @click="saveChangePassword(user)"
                  class="button accept"
          >
            <svg width="20" height="20">
              <use xlink:href="@/assets/icons.svg#save"></use>
            </svg>
          </button>
          <button type="button"
                  @click="editPassword(user, false)"
                  class="button reject"
          >
            <svg width="20" height="20">
              <use xlink:href="@/assets/icons.svg#cross"></use>
            </svg>
          </button>
        </div>

        <button type="button"
                @click="editPassword(user, true)"
                class="button accept"
        >
          <svg width="20" height="20">
            <use xlink:href="@/assets/icons.svg#edit"></use>
          </svg>
        </button>
        <button type="button"
                @click="removeUser(user.guid)"
                class="button reject"
        >
          <svg width="20" height="20">
            <use xlink:href="@/assets/icons.svg#remove"></use>
          </svg>
        </button>
      </li>
    </ul>
  </div>
</template>


<script>
import UsersService from "@/services/users-service";

export default {
  name: 'UsersList',
  data: () => ({
    isLoading: false,
    usersService: null,

    order: null,
    limit: 10,
    page: 1,

    users: [],

    newUserValue: '',
    newUserPasswordValue: '',
    newUserRoleValue: '',
    isAddNewUserMode: false,
  }),
  created() {
    this.usersService = new UsersService();
  },
  mounted() {
    this.getUsersList();
  },
  computed: {
    rolesList() {
      return this.$store.state.rolesList;
    }
  },
  methods: {
    saveChangePassword(user) {
      this.isLoading = true

      const data = {
        guid:  user.guid,
        password: user.password
      }

      this.usersService.setUserPassword(data, user.guid)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.editPassword(user, false);
            this.getUsersList();
          })
    },
    editPassword(user, state) {
      user.isEditPassword = state;
    },
    removeUser(guid) {
      this.isLoading = true;

      const message = confirm(`Remove this user?`);
      if (message) {
        this.usersService.deleteUser(guid)
            .finally(() => this.isLoading = false)
            .then(() => {
              this.getUsersList();
            })
      }
    },
    addNewUser() {
      this.newUserValue = this.newUserValue.trim().toLowerCase();
      this.isLoading = true;

      const data = {
        login: this.newUserValue,
        password: this.newUserPasswordValue,
        roles: [this.newUserRoleValue]
      }

      this.usersService.createUser(data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.newUserValue = '';
            this.newUserPasswordValue = '';
            this.newUserRoleValue = '';
            this.getUsersList();
          })
    },
    onKeyEnterNewUser() {
      if (this.newUserValue.trim()) {
        this.addNewUser();
      }
    },
    setAddNewUserStatus(status) {
      this.isAddNewUserMode = status;
    },
    getUsersList() {
      const pagination = {
        pagination: {
          "page": this.page,
          "limit": this.limit,
          "order": this.order
        }
      }

      this.usersService.getUsersList(pagination)
          .then(res => {
            this.users = res.items;
          })
    }
  }
}
</script>

<style scoped lang="scss">
.users {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 5px;
  max-height: calc(100vh - 58px - 40px);
  overflow: auto;
}

.users__header-title {
  background-color: #ff9000;
  padding: 10px 20px;
  cursor: pointer;
  height: 38px;
}

.users__header-input {
  height: 38px;
  display: flex;
}

.users__header-input input {
  flex-grow: 1;
  border: 2px solid #ff9000;
  outline: none;
  border-radius: 5px 0 0 0;
  padding: 0 20px;
  font-family: inherit;

  & + input {
    border-radius: 0;
    border-left: none;
  }
}

.users__header-input select {
  flex-grow: 1;
  border: 2px solid #ff9000;
  border-left: none;
}

.users__content {
  padding: 20px;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.users__item {
}

.user {
  display: flex;
  align-items: center;
  gap: 5px;
  fill: #ffffff;

  & .accept {
    margin-left: auto;
  }
}

.user__title {
  font-weight: bold;
}

.user__role {
  border-radius: 5px;
  padding: 5px 8px;
  background-color: gold;
  color: var(--base-text);
  font-style: italic;
}

.user__password {
  display: flex;
}
</style>