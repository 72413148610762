const useSlider = {
    data: () => ({
       showSlider: false,
       sliderCurrentIndex: 0,
    }),
    methods: {
        onExpand(index) {
            this.showSlider = true;
            this.sliderCurrentIndex = this.currentPage === 1
                ? index
                : this.perPage * (this.currentPage - 1) + index;

        },
        closeSlider(index) {
            this.showSlider = false;

            const thumbs = document.querySelectorAll('.finder-result__item');

            if (thumbs[index]) {
                thumbs[index].scrollIntoView({
                    behavior: 'smooth',
                })
            }
        }
    },
    beforeRouteLeave(to, from, next) {
        this.showSlider = false;
        this.sliderCurrentIndex = 0;
        next();
    }
}

export default useSlider;
