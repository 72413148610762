<template>
  <div class="overlay"
       @click.self="close"
  >
    <div class="popup"
         :class="{'popup--loading': isLoading}"
    >
      <div class="popup__head">
        You must be logged
      </div>
      <div class="popup__body">
        <form @submit.prevent="onSubmit" class="form">
          <div class="form__item">
            <input type="text"
                   class="form__input"
                   name="login"
                   v-model="loginValue"
                   placeholder="Login"
            >
          </div>

          <div class="form__item">
            <input type="password"
                   class="form__input"
                   name="password"
                   v-model="passwordValue"
                   placeholder="Password"
            >
          </div>

          <div class="form__error" v-if="error">
            {{ error }}
          </div>

          <button type="submit" class="form__button" :disabled="isDisabled">
            Enter
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/user-service";

export default {
  name: 'AppLogin',
  data: () => ({
    isLoading: false,
    loginValue: '',
    passwordValue: '',
    error: '',
  }),
  created() {
    this.userService = new UserService();
  },
  computed: {
    isDisabled() {
      return !this.loginValue || !this.passwordValue;
    },
    isShow() {
      return this.$store.state.isLoginShow;
    }
  },
  methods: {
    close() {
      this.$store.commit('setLoginShowStatus', false);
    },
    onSubmit() {
      this.isLoading = true;
      this.error = '';

      const data = {
        login: this.loginValue,
        password: this.passwordValue
      }

      this.userService.login(data)
          .finally(() => this.isLoading = false)
          .then(res => {
            if (res.token) {
              this.onUserLogin(res);
            } else {
              this.error = res.error[0].message;
              console.warn(this.error.message)
            }
          })
          .catch(err => {
            console.error(err);
          })
    },
    onUserLogin(data) {
      this.userService.setToken(data);
      this.userService.setUserName(this.loginValue);
      this.$store.commit('setAuth', true);

      window.location.href = '/';
      // this.$router.push('/');
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.overlay {
  position: fixed;
  background-color: rgba(#000, 0.5);
  width: 100%;
  height: calc(100% - 58px);
  top: 58px;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.popup {
  background-color: #fff;
  padding: 20px 10px;
  border-radius: 5px;
  width: 300px;
  max-width: 100%;
  position: relative;
}

.popup--loading::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.popup--loading::after {
  width: 50px;
  height: 50px;
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #ffffff;
  border-bottom: none;
  border-right: none;
  border-radius: 50%;
  z-index: 2;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.popup__head {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
}

.form {

}

.form__item {
  margin-bottom: 15px;
}

.form__input {
  width: 100%;
  height: 40px;
  border: 2px solid #ff9000;
  border-radius: 5px;
  outline: none;
  padding: 0 10px;

  &:focus {
    border-color: #1b1b1b;
  }
}

.form__error {
  color: red;
  margin-bottom: 15px;
  font-size: 12px;
}

.form__button {
  width: 100%;
  height: 40px;
  background-color: #ff9000;
  border: 2px solid #ff9000;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: no-drop;
  }

  &:not([disabled]):hover {
    color: #1b1b1b;
    background-color: rgba(#ff9000, 0.5);
  }
}
</style>
