import { createRouter, createWebHistory } from 'vue-router';

import UserService from "@/services/user-service";

import AppNotFound from "@/modules/not-found/NotFound";
import AppPicsetsList from "@/modules/picsets-list/PicsetsList";
import AppMultiArchives from "@/modules/multi-archives/MultiArchives";
import AppCheckingList from "@/modules/checking-list/CheckingList";
import AppValidatdList from "@/modules/validated-list/ValidatedList";
import AppPicset from "@/modules/picset/Picset";
import AppCheckedPicset from "@/modules/checked-picset/CheckedPicset";
import AppValidatedPicset from "@/modules/validated-picset/ValidatedPicset";
import AppLogin from "@/modules/login/AppLogin";
import AppCategories from "@/modules/categories/CategoriesLayout";
import AppUsers from "@/modules/users/AppUsers";

const routes = [
  {
    path: `/`,
    name: 'Home',
    component: AppPicsetsList,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: `/archives`,
    name: 'Archives',
    component: AppMultiArchives,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: `/checking`,
    name: 'Checking',
    component: AppCheckingList,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: `/validated`,
    name: 'Validated',
    component: AppValidatdList,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: `/categories`,
    name: 'Categories',
    component: AppCategories,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: AppLogin,
    beforeEnter() {
      if (UserService.isAuth()) {
        return '/';
      }
    }
  },
  {
    path: '/picset/:guid',
    name: 'Picset page',
    component: AppPicset,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: '/checked/:guid',
    name: 'Checked picset page',
    component: AppCheckedPicset,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: '/users',
    name: 'Users list page',
    component: AppUsers,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: '/validated/:guid',
    name: 'Validated picset page',
    component: AppValidatedPicset,
    beforeEnter() {
      if (!UserService.isAuth()) {
        return '/login';
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: AppNotFound
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

export default router
