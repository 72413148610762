<template>
  <header class="archives-header">
    <AppSearchForm @on-search="makePicset"
                   :tags="tagsList"
                   :origin-tags="originTagsList"
                   :groups-list="groupList"
                   :categories-list="categoriesList"
                   :users-list="usersList"
                   :is-loading="isLoading"
                   :progress="progress"
                   v-model:categoryName="category"
                   v-model:groupName="group"
    ></AppSearchForm>
  </header>

  <div class="archives-table-head">
    <AppSortPaginate :count="totalItems"
                     v-model="currentPage"
                     :is-some-selected="selectedPicsetList.length > 0"
                     :is-updating="isUpdating"
                     :users-list="usersList"
                     :selected-count="selectedPicsetList.length"
                     :removable-count="listToRemove.length"

                     @set-page="setPage"
                     @select-all="onSelectAll"
                     @unselect-all="onUnselectAll"
                     @remove-selected="removeSelected"
                     @owner-selected="onOwnerSelected"
                     @set-archived="onArchiveSelected"
                     @json-selected="onJSONSelected"
    ></AppSortPaginate>

    <AppFilterForm :is-loading="isLoading"
                   :users-list="usersList"
                   :groups-list="groupList"
                   :categories-list="filterCategoriesList"
                   v-model:currentGroupFilter="currentGroupFilter"
                   v-model:currentCategoryFilter="currentCategoryFilter"
                   v-model:currentStatusFilter="currentStatusFilter"
                   v-model:selectedOwner="selectedOwner"
                   v-model:titleFilterValue="titleFilterValue"
    ></AppFilterForm>
  </div>

  <div class="archives-list"
       :class="{'loading': isLoading}"
  >
    <div class="archives-list__item archives-item"
         v-for="item in items"
         :key="item.guid"
         :class="{'loading': item.isLoading}"
    >
      <div class="archives-item__row">
        <div class="archives-item__toggler"
             @click.self="toggleSelected(item)"
             :class="{'archives-item__toggler--active': isIncludedPicset(item.guid)}"
        ></div>

        <router-link
            :to="'/picset/' + item.guid"
            v-if="item.state === 'PARSED' || (item.state === 'PARSING' && +item.parsed > 0) || (item.state === 'TAKEN_PARSE' && +item.parsed > 0) || item.state === 'ARCHIVED' || item.state === 'ERROR'"
            class="archives-item__title"
        >
          <img v-if="item.url"
               :data-src="item.url"
               alt=""
               class="lazyload"
          >
          {{ item.query }}
        </router-link>
        <div class="archives-item__title" v-else>
          <img v-if="item.url"
               :data-src="item.url"
               alt=""
               class="lazyload"
          >
          {{ item.query }}
        </div>

        <div class="archives-item__state"
             :class="{
              'error': item.state === 'ERROR',
              'correct': item.state === 'PARSED' || item.state === 'ARCHIVED',
              'common': item.state === 'PARSE' || item.state === 'ARCHIVE',
              'taken': item.state === 'TAKEN_PARSE' || item.state === 'TAKEN_ARCHIVE',
              'processing': item.state === 'PARSING' || item.state === 'ARCHIVING',
           }">
          {{ item.state }}
        </div>

        <div class="archives-item__count">
          ({{ getCount(item) !== item.parsed ? (getCount(item) + '/' + item.parsed) : item.parsed }})
        </div>

        <div class="archives-item__info">
          <div class="archives-item__info-pin">
            i
          </div>
          <div class="archives-item__info-tooltip">
            <b>{{ item.orientation }}</b> / <b>{{ item.imageSize ? item.imageSize : 'auto' }}</b> /
            <b>{{ item.imageType }}</b> / <b>{{ item.fileType ? item.fileType : 'jpg' }}</b>
          </div>
        </div>

<!--        <div class="archives-item__tags tags" v-if="item.origin && item.origin.title">-->
<!--          <div class="tags__item tags__item&#45;&#45;main"-->
<!--               @click="filterByOriginTag(item.origin.guid)"-->
<!--          >-->
<!--            {{ item.origin.title }}-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="archives-item__tags tags" v-if="item.tags">-->
<!--          <div class="tags__item"-->
<!--               v-for="tag in item.tags.sort(el => el.origin)"-->
<!--               :key="tag.guid"-->
<!--               @click="filterBySecondTag(tag.title)"-->
<!--          >-->
<!--            {{ tag.title }}-->
<!--          </div>-->
<!--        </div>-->

        <div class="archives-item__role"
             :class="{'archives-item__role--admin': item.owner === 'admin'}"
             v-if="isUserAdmin && item.owner"
             @click="filterByOwner(item.owner)"
        >
          {{ item.owner }}
        </div>

        <div class="archives-item__controls">
<!--          <button class="archives-item__button archives-item__button&#45;&#45;reparse"-->
<!--                  v-if="shouldReparseButtonShow(item)"-->
<!--                  @click="reparsePicset(item.guid)"-->
<!--          >-->
<!--            Reparse-->
<!--          </button>-->
          <!--          <button class="archives-item__button archives-item__button&#45;&#45;archive"-->
          <!--                  type="button"-->
          <!--                  v-if="item.state === 'PARSED' || item.state === 'ARCHIVED' || item.state === 'ERROR'"-->
          <!--                  @click="archive(item)"-->
          <!--          >-->
          <!--            Archive-->
          <!--          </button>-->
          <a :href="downloadEndpoint + item.filepath"
             class="archives-item__button archives-item__button--download"
             v-if="item.state === 'ARCHIVED' && item.filepath"
          >
            Download
            <span>{{ getDate(item.archivedAt) }}</span>
          </a>

          <app-picset-marker :is-done="item.isReady" @mark="onMark(item.guid, !item.isReady)" />

          <router-link
              class="archives-item__button archives-item__button--edit"
              :to="'/picset/' + item.guid"
              v-if="item.state === 'PARSED' || (item.state === 'PARSING' && +item.parsed > 0) || (item.state === 'TAKEN_PARSE' && +item.parsed > 0) || item.state === 'ARCHIVED' || item.state === 'ERROR'"
          >
            Edit
            <span>{{ getDate(item.updated, true) }}</span>
          </router-link>
          <!--          <button class="archives-item__button archives-item__button&#45;&#45;remove"-->
          <!--                  type="button"-->
          <!--                  @click="remove(item)"-->
          <!--          >-->
          <!--            Remove-->
          <!--          </button>-->
        </div>
      </div>

      <div class="archives-item__labels labels">
        <div v-if="item.series.length"
             class="labels__label labels__label--black"
             :class="{
                'labels__label--highlighted': currentGroupFilter === item.series[0].title
             }"
             title="Original group"
             @click="currentGroupFilter = currentGroupFilter === item.series[0].title ? '' : item.series[0].title"
        >
          {{ item.series[0].title }}
        </div>

        <div v-if="item.categories.length"
             class="labels__label labels__label--gray"
             :class="{
                'labels__label--highlighted': currentCategoryFilter === item.categories[0].title
             }"
             title="Original category"
             @click="currentCategoryFilter = currentCategoryFilter === item.categories[0].title ? '' : item.categories[0].title"
        >
          {{ item.categories[0].title }}
        </div>
      </div>
<!--      -->
<!--      -->
<!--      -->
<!--      <div class="archives-item__categories categories" v-if="item.series.length">-->
<!--        <div class="categories__item categories__item&#45;&#45;original"-->
<!--        >-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="categories__item categories__item&#45;&#45;original"-->
<!--      >-->
<!--        {{ item.series[0].title }}-->
<!--      </div>-->
    </div>


    <div class="archives-list__controls finder-form">
      <div class="finder-form__select">
        <span>Count</span>
        <select v-model="perPage" @change="setPerPage">
          <option v-for="(item, index) in perPageOptions"
                  :key="index"
                  :value="item">{{ item }}
          </option>
        </select>
      </div>

      <vue-awesome-paginate
          :total-items="totalItems"
          :items-per-page="perPage"
          :max-pages-shown="perPage"
          v-model="currentPage"
          :on-click="setPage"
      ></vue-awesome-paginate>

      <!--      <div class="finder-form__archive"-->
      <!--           v-if="selectedPicsetList && selectedPicsetList.length > 1"-->
      <!--           @click="multiArchive"-->
      <!--      >-->
      <!--        <span style="margin-bottom: 10px;">-->
      <!--          Sets: <b>{{ selectedPicsetList.length }}</b>-->
      <!--        </span>-->
      <!--        <b>-->
      <!--          Archive-->
      <!--        </b>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import FinderService from "@/services/finder-service";
import ConfigStorageService from "@/services/config-storage-service";
import SeriesService from "@/services/series-service";
import CategoriesService from "@/services/categories-service";
import RolesService from "@/services/roles-service";
import UsersService from "@/services/users-service";
import ArchivesV2Service from "@/services/archives-v2-service";
import getPageFromQuery from "@/mixins/getPageFromQuery";

import AppSearchForm from '../search-form/SearchForm';
import AppSortPaginate from "@/modules/picsets-list/archives-sort-paginate/ArchivesSortPaginate";
import AppFilterForm from "@/modules/picsets-list/filter-form/FilterForm";
import AppPicsetMarker from "@/modules/picsets-list/components/PicsetMarker";
import {mapState} from "vuex";

export default {
  name: 'AppArchivesList',
  components: {
    AppFilterForm,
    AppSortPaginate,
    AppSearchForm,
    AppPicsetMarker,
  },
  mixins:[
    getPageFromQuery,
  ],
  data: () => ({
    filterTags: null,

    currentPage: 1,
    totalItems: 0,
    perPage: 50,
    perPageOptions: [50, 100, 200],

    groupList: [],
    groupListRaw: [],
    categoriesListRaw: [],
    originTagsList: [],
    notOriginTagsList: [],
    tagsList: [],
    items: [],
    downloadEndpoint: null,

    isLoading: false,
    isUpdating: false,
    isListFetched: false,

    reloadInterval: null,
    reloadIntervalTime: 10000,

    selectedPicsetList: [],
    category: null,
    group: null,

    selectedOriginTagGuid: '',
    selectedSecondTagTitle: '',
    selectedOwner: '',
    titleFilterValue: '',

    currentGroupFilter: '',
    currentCategoryFilter: '',
    currentStatusFilter: '',

    progress: 0,
    cancelToken: null,

    rolesList: [],
    usersList: [],
  }),
  computed: {
    ...mapState([
      'isUserAdmin',
      'userName'
    ]),
    filterCategoriesList() {
      if (!this.currentGroupFilter) {
        return this.categoriesListRaw;
      } else {
        return this.groupListRaw.find(group => group.title === this.currentGroupFilter)?.categories || [];
      }
    },
    categoriesList() {
      const list = this.groupListRaw
          ?.find(item => item.title === this.group)
          ?.categories
          .map(category => category.title);

      return list || [];
    },
    listToRemove() {
      return this.selectedPicsetList.filter(selected => {
        const item = this.items.find(item => item.guid === selected);
        return item.state !== 'PARSING' && item.state !== 'PARSE';
      });
    },
  },
  watch: {
    currentStatusFilter() {
      console.log(123)
      this.onStatusFilterSet();
    },
    currentGroupFilter() {
      this.currentCategoryFilter = '';
      this.onGroupFilterSet();
    },
    currentCategoryFilter() {
      this.onCategoryFilterSet();
    },
    selectedOwner() {
      this.onOwnerFilterChange();
    },
    titleFilterValue() {
      this.onTitleFilterChange();
    },
    userName() {
      this.getList();
    },
  },
  created() {
    this.archivesV2Service = new ArchivesV2Service();
    this.seriesService = new SeriesService();
    this.categoriesService = new CategoriesService();
    this.finderService = new FinderService();
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');

    this.rolesService = new RolesService();
    this.usersService = new UsersService();

    if (this.$route.query.query) {
      this.makeDefault(this.$route.query.query);
      this.$router.push(this.$route.path)
    }

    // this.getList();
  },
  mounted() {
    this.getCategoriesList();
    this.getGroupsList();

    this.getRolesList();
    this.getUsersList();

    if (this.userName) {
      this.getList();
    }
  },
  methods: {
    onPicsetOwnerChange(picset) {
      const data = {
        payload: picset
      }
      picset.isLoading = true;
      this.finderService.updatePicset(picset.guid, data)
          .finally(() => picset.isLoading = false)
          .then(() => {
            if (this.selectedOwner) {
              this.getList();
            }
          })
    },
    getUsersList() {
      this.usersService.getUsersList()
          .then(res => {
            this.usersList = res.items;
          })
    },
    getRolesList() {
      this.rolesService.getRolesList()
          .then(res => {
            this.rolesList = res.items;
          })
    },
    getGroupsList() {
      this.seriesService.getGroupList()
          .then(res => {
            // console.log(res);
            this.groupListRaw = res.series;
            this.groupList = res.series.map(el => el.title);
            // this.categoriesList = res.categories.map(el => el.title);
          })
    },
    getCategoriesList() {
      this.categoriesService.getCategoriesList()
          .then(res => {
            this.categoriesListRaw = res.categories;
          });
    },
    filterByOriginTag(guid) {
      this.selectedOriginTagGuid = guid;
    },
    filterBySecondTag(title) {
      this.selectedSecondTagTitle = title;
    },
    filterByOwner(title) {
      this.selectedOwner = title;
    },
    onSelectAll() {
      this.items.forEach(item => {
        if (!this.selectedPicsetList.includes(item.guid)) {
          this.selectedPicsetList.push(item.guid);
        }
      })
    },
    onUnselectAll() {
      this.selectedPicsetList = [];
    },
    onArchiveSelected() {
      if (this.selectedPicsetList.length === 1) {
        this.archive(this.selectedPicsetList[0]);
      } else {
        this.multiArchive();
      }
    },
    onOwnerSelected(newOwner) {
      const acl = [];

      this.selectedPicsetList.forEach(guid => {
        acl.push({
          guid: guid,
          owner: newOwner
        })
      })

      const data = {
        acl: acl
      }

      const message = confirm(`Change owner on selected sets?`);
      if (message) {
        this.finderService.setSelectedPicsetsAlc(data)
            .then(() => {
              this.selectedPicsetList = [];
              this.getList();
            });
      }
    },
    removeSelected() {
      this.isLoading = true;
      let promises = [];

      this.listToRemove.forEach(item => {
        promises.push(
            this.finderService.deletePicset(item)
        )
      })

      const message = confirm(`Remove selected"?`);
      if (message) {
        Promise.all(promises)
            .then(() => {
              this.selectedPicsetList = [];
              this.getList();
              this.getTagsList();
            });
      }
    },
    isIncludedPicset(guid) {
      return this.selectedPicsetList.includes(guid);
    },
    toggleSelected(item) {
      if (!this.selectedPicsetList.includes(item.guid)) {
        this.selectedPicsetList.push(item.guid);
      } else {
        const findIndex = this.selectedPicsetList.findIndex(el => el === item.guid);
        this.selectedPicsetList.splice(findIndex, 1);
      }
    },
    getOriginTagsList() {
      this.finderService.getOriginTagsList()
          .then(res => {
            this.originTagsList = res.origins;
          })
    },
    getTagsList() {
      this.finderService.getTagsList()
          .then(res => {
            this.tagsList = res.tags;
          })
    },
    getCount(item) {
      return +item.saved ? item.saved : +item.parsed ? item.parsed : '0';
    },
    makeDefault(query) {
      const data = {
        query: query,
        quantity: 1500,
        orientation: 'horizontal',
        imageType: 'photo',
        imageSize: '',
        fileType: 'jpg'
      }

      this.makePicset(data);
    },
    setPerPage() {
      this.getList();
    },
    setPage(pageNum) {
      this.currentPage = pageNum;
      this.getList();
    },
    multiArchive() {
      this.isLoading = true;
      const data = {
        guids: this.selectedPicsetList
      }

      this.finderService.createMultiArchive(data)
          .finally(() => {
            this.isLoading = false;
          })
          .then(() => {
            this.selectedPicsetList = [];
            this.getList();

            alert('Archive create success');
          })
          .catch(err => {
            alert(err.error[0].message);
          })
    },
    archive(guid) {
      this.isLoading = true;

      const data = {
        guid: guid
      }
      this.finderService.createArchive(guid, data)
          .then(() => {
            this.getList();
          });
    },
    remove(item) {
      const message = confirm(`Remove "${item.query}"?`);
      if (message) {
        this.finderService.deletePicset(item.guid)
            .then(() => {
              this.getList();

              this.getTagsList();
            });
      }
    },
    checkWaiting() {
      const find = this.items.find(
          (item) => item.state === 'PARSE'
              || item.state === 'TAKEN_PARSE'
              || item.state === 'PARSING'
              || item.state === 'ARCHIVE'
              || item.state === 'TAKEN_ARCHIVE'
              || item.state === 'ARCHIVING'
      )

      if (find) {
        if (!this.reloadInterval) {
          this.reloadInterval = setInterval(() => {
            this.getList(true);
          }, this.reloadIntervalTime)
        }
      } else {
        clearInterval(this.reloadInterval);
      }
    },
    onTagsFilterChange() {
      this.getList();
    },
    onOwnerFilterChange() {
      this.getList();
    },
    onTitleFilterChange() {
      this.getList();
    },
    onCategoryFilterSet() {
      this.setPage(1);
      this.getList();
    },
    onStatusFilterSet() {
      this.setPage(1);
      this.getList();
    },
    onGroupFilterSet() {
      this.setPage(1);
      this.getList();
    },
    onJSONSelected() {
      const idsList = JSON.stringify(this.selectedPicsetList, null, 2);
      if (!idsList) return;

      if (navigator.clipboard) {
        navigator.clipboard.writeText(idsList);
      } else {
        const tempInput = document.createElement('textarea');
        tempInput.value = idsList;
        document.body.append(tempInput);
        tempInput.select();
        document.execCommand('copy');
        tempInput.remove();
      }
    },
    onMark(guid, isReady) {
      const data = {
        guid,
        isReady,
      }

      this.finderService.setReadiness(guid, data).then(this.getList.bind(this));
    },
    getList(isUpdating = false) {
      let owner = this.selectedOwner;
      if (this.isListFetched) return;

      isUpdating ? this.isUpdating = true : this.isLoading = true;
      this.isListFetched = true;

      if (!this.isUserAdmin) {
        if (!this.userName) {
          return;
        }

        owner = this.userName;
      }

      const params = {
        series: this.currentGroupFilter ? [this.currentGroupFilter] : null,
        category: this.currentCategoryFilter ? [this.currentCategoryFilter] : null,
      }

      /**
       * titleFilterValue - поиск по query
       * но работает только по полному названию
       */
      this.finderService.getArchivesListByPost(this.currentPage, this.perPage, this.sort, params, this.selectedOriginTagGuid, owner, this.titleFilterValue, this.currentStatusFilter)
          .finally(() => {
            this.isLoading = false;
            this.isUpdating = false;
            this.isListFetched = false;
          })
          .then(res => {
            this.items = res.picsets;
            this.totalItems = res.info.total;
            this.checkWaiting();
          })
    },
    getDate(date, withTime = false) {
      const value = new Date(date);
      let result = '';
      if (!withTime) {
        result = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
      } else {
        result = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()} ${value.getHours()}:${value.getMinutes()}`;
      }

      return result;
    },
    async setSeriesToPicset(guid, groups) {
      if (!guid) return;

      const data = {
        guid,
        series: groups
      }
      console.log(data);
      this.finderService.setSeries(guid, data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.progress = 0;
            // this.getList();
            this.getTagsList();
          })
    },
    async setCategoriesToPicset(guid, categories) {
      if (!guid) return;

      const data = {
        guid,
        categories: categories,
      }
      console.log(data);
      this.finderService.setCategories(guid, data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.progress = 0;
            // this.getList();
            this.getTagsList();
          })
    },
    progressCallback() {
      return (progressEvent) => {
        this.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
      };
    },
    makePicset(value) {
      const onPicsetCreated = (res) => {
        if (!res || !res.guid) return;

        setTimeout(() => {
          const promises = [];
          if (groups) promises.push(this.setSeriesToPicset(res.guid, groups));
          if (category) promises.push(this.setCategoriesToPicset(res.guid, category));

          Promise.allSettled(promises)
              .then(() => {
                this.getList();
                this.group = '';
                this.category = '';
              })
              .finally(() => {
                this.isLoading = false;
              });
        }, 300);
      }

      let [req, groups, category, file] = value;
      this.isLoading = true;

      let data;

      if (file) {
        const formData = new FormData();

        const payload = {
          'query': req.query,
          'orientation': req.orientation,
          'image_type': req.imageType
        };

        formData.append('payload', JSON.stringify(payload))
        formData.append('archive', file);

        // eslint-disable-next-line no-undef
        this.cancelToken = axios.CancelToken.source().token;

        this.archivesV2Service.addPicsetByArchive(formData, this.cancelToken, this.progressCallback())
            .then(onPicsetCreated.bind(this))
            .finally(() => {
              this.isLoading = false;
            })
            // .then(res => {
            //   if (res.payload.guid) {
            //     this.setSeriesToPicset(res.payload.guid, groups);
            //   }
            // })
      } else {
        data = {
          payload: req
        }

        this.finderService.makeNewPicset(data)
            .finally(() => this.isLoading = false)
            .then(onPicsetCreated.bind(this))
            // .then(res => {
            //   if (res) {
            //     this.setSeriesToPicset(res.guid, groups);
            //   }
            // })
      }
    },
    shouldReparseButtonShow(picset) {
      return ['PARSE', 'PARSING', 'ERROR'].some(status => picset.state === status);
    },
    reparsePicset(guid) {
      this.finderService.reparsePicset(guid)
          .finally(() => this.isLoading = false)
          .then(this.getList);
    }
  },
  beforeRouteLeave(from, to, next) {
    if (this.reloadInterval) {
      clearInterval(this.reloadInterval);
    }
    next();
  }
}
</script>

<style scoped lang="scss">
.archives-table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  width: 100%;
}

.archives-list {
  display: flex;
  flex-direction: column;
}

.archives-list__item {
  margin-bottom: 10px;
}

.archives-item {
  border-radius: 5px;
  border: 1px solid #1b1b1b;
  background-color: #ffffff;

  &.loading::before {
    position: absolute;
  }
}

.archives-item__row {
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 100%;
  padding: 10px 20px 5px 5px;
  border-radius: 5px;
  background-color: #ffffff;
  gap: 5px;
}

.archives-item__toggler {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 2px solid #ff9000;
  cursor: pointer;
  position: relative;
}

.archives-item__toggler--active::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 22px;
  border-bottom: 2px solid #222222;
  border-right: 2px solid #222222;
  rotate: 45deg;
  left: 10px;
}

.archives-item__created {
  margin-right: 10px;
  color: rgba(#000, 0.5);
  font-size: 13px;
  line-height: 20px;
}

.archives-item__title {
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 8px;

  img {
    max-width: 100px;
  }
}

.archives-item__state {
  color: rgba(#000, 0.5);
}

.archives-item__count {
  color: #ff9000;
}

.archives-item__controls {
  margin-left: auto;

  display: flex;
}

.archives-item__button {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 10px;
  }

  cursor: pointer;
  border: none;
  background-color: #ff9000;
  border-radius: 5px;
  padding: 8px 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;

  &:hover {
    opacity: 0.9;
  }
}

.archives-item__button--download {
  color: #1b1b1b;

  span {
    display: block;
    font-size: 0.6rem;
    opacity: 0.5;
    white-space: nowrap;
  }
}

.archives-item__button--reparse {
  color: darkblue;
  border: 2px solid darkblue;
  background-color: #fff;
}

.archives-item__button--archive {
  color: #ffffff;
}

.archives-item__button--remove {
  background-color: red;
  color: #ffffff;
}

.archives-item__button--edit {
  background-color: green;
  color: #ffffff;

  span {
    display: block;
    font-size: 0.6rem;
    opacity: 0.5;
    white-space: nowrap;
  }
}

.archives-item__info {
  color: #1b1b1b;
  font-size: 13px;
  line-height: 20px;
  white-space: nowrap;
  position: relative;

  b {
    font-size: 18px;
  }
}

.archives-item__info-pin {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ff9000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}

.archives-item__info-pin:hover + .archives-item__info-tooltip {
  display: block;
}

.archives-item__info-tooltip {
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(102%);
  background-color: #fff;
  border: 1px solid #ff9000;
  padding: 5px 10px;
  display: none;
}


.archives-list__controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finder-form__select {
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    margin-right: 10px;
  }

  select {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }
}

.finder-form__archive {
  background-color: #ff9000;
  display: flex;
  padding: 0 15px;
  cursor: pointer;
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.archives-item__tags {
  //margin-left: 10px;

  & + & {
    //margin-left: 5px;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tags__item {
  border-radius: 5px;
  padding: 5px 8px;
  background-color: rgba(#1b1b1b, 0.5);
  cursor: pointer;
}

.tags__item--main {
  background-color: gold;
  color: var(--base-text);
}

.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 5px 10px;
}

.categories__item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;
  background-color: #fff;
  color: #222222;
  border: 1px solid #222;
}

.categories__item span {
  background-color: gold;
  padding: 2px 4px;
  border-radius: 2px;
  font-weight: bold;
}

.archives-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 5px;
}

.archives-item__role {
  color: #1b1b1b;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: rgba(pink, 0.6);
  cursor: pointer;

  &--admin {
    background-color: rgba(green, 0.3);
  }
}

.labels {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0 5px 10px;
}


.labels__label {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 4px;
  font-size: 13px;
  background-color: #fff;
  color: #222222;
  border: 1px solid #222;
  transition: .1s;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    border-color: #777;
    color: #777;
  }

  &--black {
    color: #eee;
    border-color: #222;
    background-color: #222;

    &:hover {
      border-color: #555;
      background-color: #555;
      color: #ffffff;
    }
  }

  &--gray {
    color: #eee;
    border-color: #888;
    background-color: #888;

    &:hover {
      color: #fff;
      border-color: #aaa;
      background-color: #aaa;
    }
  }

  &--highlighted {
    box-shadow: 0 0 3px 1px #ff9000;
  }
}
</style>
