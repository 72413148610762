<template>
  <div class="finder-result"
       :class="{'loading': isLoading}"
       v-if="items?.length"
  >
    <div class="finder-result__controls finder-result__controls--fixed" @click="save">
      <span style="margin-bottom: 20px;">
        {{ saveCount }}/{{ picItems.length }}
      </span>
      <b>
        Save
      </b>
    </div>
    <div class="finder-result__header">
      <div class="controls">
        <button type="button" @click="setAllApproved">Set all positive</button>
        <button type="button" @click="setAllExcluded">Set all negative</button>
      </div>
      <div class="finder-result__title">
        Found: {{ picItems.length }}
        <span class="finder-result__excluded-value" v-if="excluded && excluded.length">|-{{ excluded.length }}|</span>
        items
      </div>
      <div class="finder-result__filters filter">
        <div class="filter__item" @click="showAll" :class="{'inactive': !isOnlyFiltered}">
          Show all
        </div>
        <div class="filter__item" :class="{'active': isOnlyFiltered, 'approved': isOnlyFiltered && !isNegativeMode}"
             @click="toggleExcluded">
          Show {{ isNegativeMode ? 'Negative' : 'Positive' }}
        </div>
      </div>
      <div class="finder-result__confirm"
           :class="{
              'finder-result__confirm--done': isReady,
           }"
           @click="$emit('markPicset')"
      >
        {{ isReady ? "Set unready" : "Set ready" }}
      </div>
      <div class="finder-result__controls" @click="save">
        <span style="margin-right: 20px;">{{ saveCount }}/{{ picItems.length }}</span><b>Save</b>
      </div>
    </div>

    <div class="finder-result__wrapper"
         :class="{'finder-result__wrapper--vertical': orientation === 'vertical' }"
         v-if="!isOnlyFiltered">
      <div class="finder-result__item"
           v-for="item in items" :key="item.guid"
      >
        <div class="finder-result__image"
             :class="{'finder-result__image--vertical': orientation === 'vertical'}"
        >
          <div class="finder-result__item-controls">
            <div @click="onPreviewClick(item)">Preview</div>
            <div @click="onToggleClick(item)">Toggle</div>
            <div @click="onSearchClick(item)" class="search-btn">Search</div>
          </div>
          <div class="finder-result__approved" v-if="!isNegativeMode && !isExcluded(item)">
            <span></span>
          </div>
          <div class="finder-result__excluded" v-if="isNegativeMode && isExcluded(item)">
            <span></span>
          </div>

          <img class="lazyload"
               :data-src="getThumbUrl(item)"
               alt=""
          />
        </div>

        <div class="finder-result__tags" v-if="item.tags.length">
          <router-link :to="{path:'/', query: { query: tag }}"
                       v-for="(tag, index) in item.tags"
                       :key="index"
                       target="_blank"
          >
            {{ tag }}
          </router-link>
        </div>
      </div>
    </div>


    <div class="finder-result__wrapper"
         :class="{'finder-result__wrapper--vertical': orientation === 'vertical' }"
         v-if="isOnlyFiltered">
      <div class="finder-result__item"
           v-for="item in excludedItems" :key="item.guid"
      >
        <div class="finder-result__image"
             :class="{'finder-result__image--vertical': orientation === 'vertical'}"
        >
          <div class="finder-result__item-controls">
            <div @click="onPreviewClick(item)">Preview</div>
            <div @click="onToggleClick(item)">Toggle</div>
            <div @click="onSearchClick(item)" class="search-btn">Search</div>
          </div>
          <div class="finder-result__approved" v-if="!isNegativeMode && !isExcluded(item)">
            <span></span>
          </div>
          <div class="finder-result__excluded" v-if="isNegativeMode && isExcluded(item)">
            <span></span>
          </div>

          <img class="lazyload"
               :data-src="getThumbUrl(item)"
               alt=""
          />
        </div>

        <div class="finder-result__tags" v-if="item.tags.length">
          <router-link :to="{path:'/', query: { query: tag }}"
                       v-for="(tag, index) in item.tags"
                       :key="index"
                       target="_blank"
          >
            {{ tag }}
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="finder-result"
       v-else>
    Loading...
  </div>
</template>

<script>
import 'lazysizes';
import ConfigStorageService from "@/services/config-storage-service";

export default {
  name: 'AppPicsetItems',
  props: {
    orientation: {
      type: String,
      default: 'horizontal'
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => []
    },
    picItems: {
      type: Array,
      default: () => []
    },
    excludedItems: {
      type: Array,
      default: () => []
    },
    excludedPicItems: {
      type: Array,
      default: () => []
    },
    isOnlyFiltered: {
      type: Boolean,
      default: false,
    },
    isNegativeMode: {
      type: Boolean,
      default: true,
    },
    isReady: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle-excluded', 'save', 'preview', 'search-by-img', 'show-all', 'markPicset'],
  data: () => ({
    downloadEndpoint: null,

    excluded: [],
    approved: [],
  }),
  watch: {
    picItems() {
      this.excluded = [];

      this.picItems.forEach(item => {
        if (item.excluded) {
          if (!this.excluded.find(el => el === item.id)) {
            this.excluded.push(item.id);
          }
        }
      })
    },
  },
  mounted() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');

    this.items.forEach(item => {
      item.excluded && this.excluded.push(item.id);
    })
  },
  computed: {
    saveCount() {
      if (this.excluded.length) {
        return this.picItems.length - this.excluded.length;
      } else {
        return this.picItems.length;
      }
    }
  },
  methods: {
    setAllApproved() {
      this.excluded = [];

      this.picItems.forEach(el => el.excluded = false);
    },
    setAllExcluded() {
      this.excluded = [];
      this.picItems.forEach(el => {
        el.excluded = true;
        this.excluded.push(el.id);
      });
    },
    showAll() {
      this.$emit('show-all');
    },
    toggleExcluded() {
      const withoutExcluded = this.picItems.filter(item => !item.excluded);
      const idsList = withoutExcluded.map(item => item.id);
      this.$emit('toggle-excluded', idsList);
    },
    save() {
      const withoutExcluded = this.picItems.filter(item => !item.excluded);
      const idsList = withoutExcluded.map(item => item.id);
      this.$emit('save', idsList);
    },
    onPreviewClick(item) {
      this.$emit('preview', item.id);
    },
    onSearchClick(item) {
      this.$emit('search-by-img', item.url);
    },
    onToggleClick(item) {
      const el = this.picItems.find(e => e.id === item.id);
      el.excluded = !el.excluded;

      if (el.excluded) {
        this.excluded.push(el.id);
      } else {
        const findIndex = this.excluded.findIndex(ex => el.id === ex);
        this.excluded.splice(findIndex, 1);
      }
    },
    isExcluded(item) {
      const el = this.picItems && this.picItems.find(e => e.id === item.id);
      return el && el.excluded;
    },
    getThumbUrl(thumb) {
      if (thumb.filepath) {
        return this.downloadEndpoint + thumb.filepath;
      } else {
        return thumb.url;
      }
    },
  }
}
</script>

<style scoped lang="scss">
.finder-result {
  padding-bottom: 70px;
}

.finder-result__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.finder-result__title {
  font-size: 1.5rem;
  line-height: 34px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.finder-result__wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px 10px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(6, 1fr);
  }

  & > * {
    min-width: 0;
  }
}

.finder-result__wrapper--vertical {
  grid-template-columns: repeat(7, 1fr);
}

.finder-result__image {
  aspect-ratio: 16/9;
  background-color: #ff9000;
  line-height: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.finder-result__image--vertical {
  aspect-ratio: 9/16;
}

.finder-result__tags {
  padding-top: 5px;

  a {
    font-size: 12px;
    text-decoration: none;
    color: #1b1b1b;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }

    &:not(:last-child)::after {
      content: ', '
    }
  }
}

.finder-result__controls {
  background-color: #ff9000;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: lighten(#ff9000, 10%);
  }
}

.finder-result__controls--fixed {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.finder-result__excluded {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px;
  background: rgba(red, 0.7);
  pointer-events: none;
  width: 100%;
  height: 100%;

  span::before,
  span::after {
    content: '';
    position: absolute;
    background-color: #ffffff;
    width: 100px;
    height: 4px;
    transform: rotate(45deg);
    top: 50%;
    left: 35%;
  }

  span::after {
    transform: rotate(-45deg);
    top: 50%;
    left: 35%;
  }
}

.finder-result__approved {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px;
  background: rgba(green, 0.7);
  pointer-events: none;
  width: 100%;
  height: 100%;

  span::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 100px;
    border: 4px solid #ffffff;
    border-left: none;
    border-top: none;
    rotate: 45deg;
    left: 40%;
    top: 15%;
  }
}

.finder-result__excluded-value {
  color: gray;
  font-size: 1rem;
  //margin-left: 0.5rem;
  margin-right: 0.3rem;
}

.finder-result__item-controls {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;

  & > * {
    transition: 300ms ease-out;
    background-color: rgba(#fff, 0.6);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }

  & > *:first-child {
    width: 30%;
    writing-mode: tb;
  }

  & .search-btn {
    width: 30%;
    writing-mode: tb;
  }
}

.filter {
  margin-right: 10px;
  margin-left: auto;
  height: 34px;
  display: flex;
  gap: 5px;
}

.filter__item {
  border: 1px solid #ff9000;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 10px;
  cursor: pointer;
  height: 100%;

  &.inactive {
    pointer-events: none;
  }

  &.active {
    background-color: rgba(255, 0, 0, 0.7);
    color: #ffffff;
    pointer-events: none;
  }

  &.approved {
    background-color: rgba(green, 0.7);
    color: #ffffff;
  }
}

.finder-result__controls-toggler {
  display: flex;
  flex-direction: column;

  & > * {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  & > *:hover:first-child {
    background-color: rgba(green, 0.5);
  }

  & > *:hover:last-child {
    background-color: rgba(red, 0.5);
  }
}

.finder-result__confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  color: #fff;
  background-color: #aaa;
  border-radius: 10px;
  margin-right: 10px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: #bbb;
  }

  &--done {
    background-color: forestgreen;

    &:hover {
      background-color: lighten(forestgreen, 10%);
    }
  }
}

.controls {
  display: flex;
  gap: 5px;

  button {
    border: 1px solid #ff9000;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba(#ff9000, 0.5);
    }
  }
}
</style>
