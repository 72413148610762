<template>
  <span class="picset-group-label"
        :class="{
          'picset-group-label--approved': approved,
          'picset-group-label--highlighted': highlighted,
        }"
        title="Trained group"
  >
    {{ title }}
  </span>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AppPicsetGroupLabel",
  props: {
    title: {
      type: String,
      default: '',
    },
    approved: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    }
  }
})
</script>

<style scoped lang="scss">
  .picset-group-label {
    padding: 5px 7px;
    border-radius: 5px;
    background-color: #888;
    color: #fff;
    cursor: pointer;

    &:first-of-type {
      margin-left: 8px;
    }

    &:hover {
      background-color: #999;
    }

    &--approved {
      display: flex;
      align-items: center;
      gap: 7px;
      background-color: forestgreen;

      &:hover {
        background-color: lighten(forestgreen, 10%);
      }

      &::after {
        content: "";
        display: block;
        width: 10px;
        height: 5px;
        rotate: -45deg;
        border-bottom: 2px solid #fff;
        border-left: 2px solid #fff;
      }
    }

    &--highlighted {
      outline: darkorange solid 1px;
    }
  }
</style>
