<template>
  <div>NOT FOUND PAGE</div>
</template>

<script>
export default {
  name: 'AppNotFound',
}
</script>

<style scoped lang="scss">

</style>
