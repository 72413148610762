<template>
  <div class="error-list"
       v-if="list.length"
  >
    <div class="error-list__item"
         v-for="notify in list"
         :key="notify.time"
         :class="{'error-list__item--error': notify.type === 'error'}"
         @click="closeNotify(notify)"
    >
      {{ notify.ms }}
    </div>
  </div>
</template>

<script>
import notifyService from "@/services/notify-service";

export default {
  name: 'AppNotify',
  data: () => ({
    timer: null,
    duration: 5000,
    list: []
  }),
  mounted() {
    notifyService.subscribe(error => {
      this.list.push(error);
    })
  },
  watch: {
    list: {
      handler: function () {
        clearTimeout(this.timer);
        this.setNotifyTimeout();
      },
      deep: true
    }
  },
  methods: {
    setNotifyTimeout() {
      this.timer = setTimeout(() => {
        this.closeFirstNotify();
      }, this.duration)
    },
    closeNotify(notify) {
      const findIndex = this.list.findIndex(el => el.time === notify.time);
      this.list.splice(findIndex, 1);
    },
    closeFirstNotify() {
      this.list.shift();

      if (this.list.length) {
        this.setNotifyTimeout();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.error-list {
  position: fixed;
  left: 10px;
  bottom: 10px;
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}

.error-list__item {
  cursor: pointer;
  max-width: 300px;

  background-color: gray;
  color: #000000;
  padding: 20px;
  border-radius: 10px;
  font-weight: bold;
}

.error-list__item--error {
  background-color: red;
  color: #ffffff;
}
</style>