<template>
  <div class="overlay" @click.self="close">
    <div class="overlay__close" @click="close"></div>

    <form class="popup-finder-form" @submit.prevent="submit">
      <div class="popup-finder-form__img">
        <img :src="url" alt="">
      </div>

      <div class="popup-finder-form__textfield">
        <textarea
               class="popup-finder-form__input"
               placeholder="New picset title"
               v-model="textValue"
               :disabled="isLoading"
        ></textarea>
      </div>

      <div class="popup-finder-form__select">
        <span>
          Count

          <span class="popup-finder-form__toggle">
            <label>
              <input type="checkbox" v-model="isHandCountEnter">
              <span></span>
            </label>
          </span>
        </span>
        <select v-model="countValue" v-if="!isHandCountEnter">
          <option v-for="(item, index) in countOptions"
                  :key="index"
                  :value="item">{{ item }}
          </option>
        </select>
        <input v-else type="number" v-model="handCountValue" placeholder="Type value">
      </div>

      <OnClickOutside @trigger="hideOriginTags">
        <div class="popup-finder-form__select popup-finder-form__select--size">
          <span>Main tag</span>
          <input type="text" v-model="originTagValue" placeholder="Type main tag" @focus="showOriginTags">

          <div class="tag-selector tag-selector--main" v-if="serverOriginTags.length && isShowOriginTags">
            <div class="tag-selector__item"
                 :class="{'tag-selector__item--active': getSelectedOriginStatus(tag)}"
                 v-for="tag in serverOriginTags" :key="tag.guid"
                 @click="addOriginTag(tag)"
            >
              {{ tag.title }}
            </div>
          </div>
        </div>
      </OnClickOutside>

      <OnClickOutside @trigger="hideTags">
        <div class="popup-finder-form__select popup-finder-form__select--size">
          <span>Tags</span>
          <input type="text" v-model="tagsValue" placeholder="Type tags" @focus="showTags">

          <div class="tag-selector" v-if="serverTags.length && isShowTags">
            <div class="tag-selector__item"
                 :class="{'tag-selector__item--active': getSelectedStatus(tag)}"
                 v-for="tag in serverTags" :key="tag.guid"
                 @click="addTag(tag)"
            >
              {{ tag.title }}
            </div>
          </div>
        </div>
      </OnClickOutside>

      <div class="popup-finder-form__select">
        <span>Orientation</span>
        <select v-model="orientationValue">
          <option v-for="(item, index) in orientationOptions"
                  :key="index"
                  :value="item">{{ item }}
          </option>
        </select>
      </div>

      <div class="popup-finder-form__select">
        <span>Size</span>
        <select v-model="imageSizeValue">
          <option v-for="(item, index) in imageSizeOptions"
                  :key="index"
                  :value="item">{{ item ? item : 'auto' }}
          </option>
        </select>
      </div>

      <div class="popup-finder-form__select">
        <span>Image type</span>
        <select v-model="imageTypeValue">
          <option v-for="(item, index) in imageTypeOptions"
                  :key="index"
                  :value="item">{{ item }}
          </option>
        </select>
      </div>

      <div class="popup-finder-form__select">
        <span>File type</span>
        <select v-model="fileTypeValue">
          <option v-for="(item, index) in fileTypeOptions"
                  :key="index"
                  :value="item">{{ item }}
          </option>
        </select>
      </div>



      <button class="popup-finder-form__button"
              :class="{'disabled': isLoading}"
              :disabled="isLoading || !textValue"
      >
        Make picset
      </button>
    </form>
  </div>
</template>

<script>
import FinderService from "@/services/finder-service";
import {OnClickOutside} from '@vueuse/components'

export default {
  name: 'AppPricsetSearchPopup',
  components: {
    OnClickOutside
  },
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  emits: ['on-create-picset', 'close'],
  data: () => ({
    serverTags: [],
    serverOriginTags: [],

    isLoading: false,

    isShowOriginTags: false,
    isShowTags: false,

    textValue: '',

    selectedOriginTagsList: [],
    selectedTagsList: [],
    tagsValue: '',
    originTagValue: '',

    handCountValue: '',
    isHandCountEnter: false,

    countValue: 100,
    countOptions: [
      100, 500, 1500, 5000
    ],

    orientationValue: 'horizontal',
    orientationOptions: [
      'horizontal', 'vertical'
    ],

    imageSizeValue: '',
    imageSizeOptions: [
      '',
      'small',
      'medium',
      'large'
    ],

    imageTypeValue: 'photo',
    imageTypeOptions: [
      'photo',
      'clipart',
      'lineart',
      'face',
      'demotivator'
    ],

    fileTypeValue: 'jpg',
    fileTypeOptions: [
      'jpg',
      // 'png',
      // 'gifan'
    ]
  }),
  created() {
    this.finderService = new FinderService();

    document.addEventListener('keydown', this.escListener);
    this.getServerTags();
    this.getServerOriginTags();
  },
  unmounted() {
    document.removeEventListener('keydown', this.escListener);
  },
  methods: {
    getServerOriginTags() {
      this.finderService.getOriginTagsList()
          .then(res => {
            this.serverOriginTags = res.origins;
          })
    },
    getServerTags() {
      this.finderService.getTagsList()
          .then(res => {
            this.serverTags = res.tags;
          })
    },
    escListener(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
    submit() {
      const data = {
        query: this.textValue,
        quantity: (this.isHandCountEnter && this.handCountValue) ? this.handCountValue : this.countValue,
        orientation: this.orientationValue,
        imageType: this.imageTypeValue,
        imageSize: this.imageSizeValue,
        fileType: this.fileTypeValue,
        is_url: true,
        url: this.url
      }

      if (this.originTagValue) {
        data['origin'] = {
          'title': this.originTagValue
        }
      }

      if (this.tagsValue) {
        data['tags'] = [{
          'title': this.tagsValue
        }]
      }

      this.$emit('on-create-picset', data);
    },
    showOriginTags() {
      this.isShowOriginTags = true;
    },
    showTags() {
      this.isShowTags = true;
    },
    hideTags() {
      this.isShowTags = false;
    },
    hideOriginTags() {
      this.isShowOriginTags = false;
    },
    getSelectedStatus(tag) {
      return this.selectedTagsList.find(el => el.title === tag.title);
    },
    getSelectedOriginStatus(tag) {
      return this.selectedOriginTagsList.find(el => el.title === tag.title);
    },
    addOriginTag(tag) {
      this.originTagValue = tag.title;
      this.hideOriginTags();
    },
    addTag(tag) {
      this.tagsValue = tag.title;
      this.hideTags();
    },
  }
}
</script>

<style scoped lang="scss">
.overlay {
  position: fixed;
  background-color: rgba(#000, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.overlay__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: '';
    width: 80px;
    height: 4px;
    background-color: #fff;
    transform: rotate(45deg);
    top: 38px;
  }

  &::after {
    transform: rotate(-45deg);
  }
}


.popup-finder-form {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.popup-finder-form__textfield {
  display: flex;
}

.popup-finder-form__input {
  font-size: 16px;
  padding: 5px 10px;
  border: 2px solid #ff9000;
  font-family: inherit;
  border-radius: 5px;
  outline: none;
  min-height: 100px;
  resize: none;

  &:focus {
    border-color: lighten(#ff9000, 10%);
  }
}

.popup-finder-form__button {
  border: none;
  background-color: #ff9000;
  font-weight: bold;
  padding: 0 20px;
  height: 35px;
  margin-top: 15px;
  border-radius: 5px;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:not([disabled]):hover {
    background-color: lighten(#ff9000, 10%);
  }
}

.popup-finder-form__select {
  height: 50px;
  display: flex;
  flex-direction: column;
  position: relative;

  & > span {
    margin-bottom: 3px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  select {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }

  input {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: text;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }
}

.popup-finder-form__toggle {
  input {
    display: none;
  }

  span {
    display: flex;
    width: 30px;
    height: 12px;
    border-radius: 5px;
    border: 2px solid #ff9000;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #ff9000;
    }
  }

  input:checked + span::before {
    left: auto;
    right: 2px;
  }
}

.tag-selector {
  position: absolute;
  top: calc(100% + 5px);
  background-color: #fff;
  padding: 10px;
  min-width: 320px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  z-index: 11;

  max-height: 208px;
  overflow: auto;
}

.tag-selector__item {
  background-color: rgba(27, 27, 27, 0.5);
  border-radius: 5px;
  padding: 5px 8px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
}

.tag-selector--main .tag-selector__item {
  background-color: gold;
  color: var(--base-text);
}

.tag-selector__item--active {
  background-color: green !important;
  color: #ffffff !important;
}

.popup-finder-form__select--size input {
  width: 100%;
}

.popup-finder-form__img {
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
