<template>
  <div class="picset-info">
    <div class="picset-info__column" v-if="info.url">
      <img :src="info.url" alt="">
    </div>
    <div class="picset-info__column">
      <div class="picset-info__updated">
        {{ getDate(info.updated) }}
      </div>

      <div class="picset-info__title">
        <b>{{ info.query }}</b>
        <template v-if="info.state"> ({{ info.state }})</template>
      </div>

      <div class="picset-info__type">
        Image type: <b>{{ info.imageType }}</b>
      </div>
    </div>

    <div class="picset-info__column">
      <div class="picset-info__orientation">
        Orientation: <b>{{ info.orientation }}</b>
      </div>

      <div class="picset-info__type">
        Image size: <b>{{ info.imageSize ? info.imageSize : 'auto' }}</b>
      </div>

      <div class="picset-info__orientation">
        File Type: <b>{{ info.fileType }}</b>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppPicsetInfo',
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    getDate(date) {
      const value = new Date(date);
      return `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()} ${value.getHours()}:${value.getMinutes()}`;
    },
  }
}
</script>

<style scoped lang="scss">
.picset-info {
  background-color: rgba(#ff9000, 0.5);
  min-width: fit-content;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  line-height: 16px;
}

.picset-info__column img {
  max-width: 100px;
}

.picset-info__column > *:not(:last-child) {
  margin-bottom: 5px;
}

.picset-info__column:not(:last-child) {
  margin-right: 20px;
}

.picset-info__updated {
  opacity: 0.5;
  font-size: 12px;
}
</style>