<template>
  <div class="picset-marker"
       :class="{
        'picset-marker--done': isDone,
       }"
       @click="$emit('mark')"
       :title="isDone ? 'Set unready' : 'Set ready'"
  />
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PicsetMarker",
  props: {
    isDone: {
      type: Boolean,
      default: false,
    }
  },
})
</script>

<style scoped lang="scss">
.picset-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #eee;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }

  &::before {
    content: "";
    display: block;
    width: 25px;
    height: 12px;
    border-left: 5px solid #fff;
    border-bottom: 5px solid #fff;
    rotate: -45deg;
    translate: 0 -2px;
  }

  &--done {
    background-color: forestgreen;
  }

  &--done:hover {
    background-color: lighten(forestgreen, 10%);
  }
}
</style>
