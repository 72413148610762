import ConfigStorageService from "@/services/config-storage-service";
import UserService from "@/services/user-service";
import notifyService from "@/services/notify-service";

export default class HttpService {
    constructor() {
        this.endpoint = ConfigStorageService.get('apiEndpoint');
    }

    http(url, method = 'GET', headers, body) {
        const methodURL = `${this.endpoint}${url}`;
        const options = {}

        if (method) {
            options['method'] = method;
        }

        if (headers) {
            options['headers'] = headers;
        }

        if (body) {
            options['body'] = body;
        }

        return new Promise((resolve, reject) => {
            fetch(methodURL, options)
                .then(res => {
                    // return res.ok ? res.json() : reject(res)
                    if (!res.ok) {
                        if (res.status === 401) {
                            UserService.logout();
                        }
                        return res.json().then(err => {
                            const errorList = err.error;
                            errorList.forEach(el => {
                                console.warn(el.message);
                                notifyService.broadcast({
                                    type: 'error',
                                    time: new Date().getTime(),
                                    ms: el.message
                                });
                            })
                            return reject(err);
                        })
                    } else {
                        return resolve(res.json());
                    }
                })
                .then(data => resolve(data))
                .catch(err => {
                    return reject(err)
                });
        })
    }
}