<template>
  <div class="finder-result"
       :class="{'loading': isLoading}"
       v-if="items?.length"
  >
    <div class="finder-result__header">
      <div class="finder-result__title">
        Found: {{ filteredItems.length }} items {{ (picItems.length - filteredItems.length) ? `(${picItems.length - filteredItems.length} items were hidden)` : '' }}
      </div>
    </div>

    <div class="finder-result__wrapper"
         :class="{'finder-result__wrapper--vertical': orientation === 'vertical' }"
    >
      <ValidatedThumbItem v-for="(item, index) in filteredItems"
                          :key="item.guid"
                          :item="item"
                          :orientation="orientation"
                          :current-filter="currentFilter"
                          :filter-categories-list="filterCategoriesList"
                          :filter-by-group="filterByGroup"
                          @preview="onPreviewClick"
                          @expand="$emit('expand', index)"
      ></ValidatedThumbItem>
    </div>
  </div>

  <div class="finder-result"
       v-else>
    Loading...
  </div>
</template>

<script>
import 'lazysizes';
import ConfigStorageService from "@/services/config-storage-service";
import FinderService from "@/services/finder-service";
import ValidatedThumbItem from "@/modules/validated-picset/components/ValidatedThumbItem";

export default {
  name: 'AppValidatedPicsetItems',
  components: {
    ValidatedThumbItem,
  },
  props: {
    orientation: {
      type: String,
      default: 'horizontal'
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => []
    },
    picItems: {
      type: Array,
      default: () => []
    },
    currentFilter: {
      type: Array,
      default: () => []
    },
    filterCategoriesList: {
      type: Map,
      default: new Map()
    },
    filter: {
      type: Set,
      default: new Set(),
    },
    filterByGroup: {
      type: String,
      default: '',
    },
  },
  emits: ['preview', 'expand'],
  data: () => ({
    downloadEndpoint: null,
    finderService: null,

    isShowOptions: {},
  }),
  computed: {
    filteredItems() {
      if (!this.filter.size) {
        return this.items
      } else {
        return this.items.filter(item => {
          const itemAllCategories = new Set();

          item.trained?.some(person => {
            return person.groups?.forEach(group => {
              itemAllCategories.add(...group.category);
            });
          });

          return Array.from(this.filter).every(filter => {
            return itemAllCategories.has(filter);
          });
        });
      }
    }
  },
  mounted() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
    this.finderService = new FinderService();
  },
  methods: {
    onPreviewClick(id) {
      this.$emit('preview', id);
    },
    onSetThumbGroupData(value) {
      const id = value.id;
      const category = value.category;
      const group = value.group;

      const thumb = this.items.find(el => el.id === id);
      const picture = this.picItems.find(el => el.id === id);
      const guid = picture.guid;
      const trained = thumb.trained.map(el => {
        return {group: el.group, category: el.category}
      }).filter(el => el.group);

      trained.push({
        group: group,
        category: [category]
      })

      thumb.trained = trained;

      const data = {
        guid,
        trained
      }

      thumb.loading = true;
      this.finderService.updatePicTrained(guid, data)
          .finally(() => thumb.loading = false)
          .then(() => {
          })
    },
    onUpdateThumbData(id) {
      const thumb = this.items.find(el => el.id === id);
      const picture = this.picItems.find(el => el.id === id);
      const guid = picture.guid;
      const trained = thumb.trained.map(el => {
        return {group: el.group, category: el.category}
      }).filter(el => el.group);

      const data = {
        guid,
        trained
      }

      thumb.loading = true;
      this.finderService.updatePicTrained(guid, data)
          .finally(() => thumb.loading = false)
          .then(() => {
          })
    },
  }
}
</script>

<style scoped lang="scss">
.finder-result {
  padding-bottom: 70px;
}

.finder-result__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.finder-result__title {
  font-size: 1.5rem;
  line-height: 34px;
  font-weight: bold;
  display: flex;
  align-items: center;
  //margin-left: 10px;
}

.finder-result__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(5, 1fr);
  }

  & > * {
    min-width: 0;
  }
}

//.finder-result__item {
//  position: relative;
//  //  overflow: auto;
//}
//
.finder-result__wrapper--vertical {
  grid-template-columns: repeat(7, 1fr);
}

//.finder-result__image {
//  aspect-ratio: 16/9;
//  background-color: #ff9000;
//  line-height: 0;
//  border-radius: 5px;
//  overflow: hidden;
//  position: relative;
//  cursor: pointer;
//
//  img {
//    object-fit: cover;
//    width: 100%;
//    height: 100%;
//  }
//}
//
//.finder-result__image--vertical {
//  aspect-ratio: 9/16;
//}

.finder-result__tags {
  padding-top: 5px;

  a {
    font-size: 12px;
    text-decoration: none;
    color: #1b1b1b;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }

    &:not(:last-child)::after {
      content: ', '
    }
  }
}

.finder-result__controls {
  background-color: #ff9000;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: lighten(#ff9000, 10%);
  }
}

.finder-result__controls--fixed {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.finder-result__excluded {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px;
  background: rgba(red, 0.7);
  pointer-events: none;
  width: 100%;
  height: 100%;

  span::before,
  span::after {
    content: '';
    position: absolute;
    background-color: #ffffff;
    width: 100px;
    height: 4px;
    transform: rotate(45deg);
    top: 50%;
    left: 35%;
  }

  span::after {
    transform: rotate(-45deg);
    top: 50%;
    left: 35%;
  }
}

.finder-result__approved {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 5px;
  background: rgba(green, 0.7);
  pointer-events: none;
  width: 100%;
  height: 100%;

  span::before {
    content: '';
    position: absolute;
    width: 40px;
    height: 100px;
    border: 4px solid #ffffff;
    border-left: none;
    border-top: none;
    rotate: 45deg;
    left: 40%;
    top: 15%;
  }
}

.finder-result__excluded-value {
  color: gray;
  font-size: 1rem;
  //margin-left: 0.5rem;
  margin-right: 0.3rem;
}

.finder-result__item-controls {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;

  & > * {
    transition: 300ms ease-out;
    background-color: rgba(#fff, 0.6);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;

    &:hover {
      opacity: 1;
      //background-color: rgba(red, 0.6);
    }
  }

  //& > * {
  //  width: 100%;
  //  writing-mode: tb;
  //}

  //& .search-btn {
  //  width: 30%;
  //  writing-mode: tb;
  //}
}

//.filter {
//  margin-right: 10px;
//  margin-left: auto;
//  height: 34px;
//  display: flex;
//  gap: 5px;
//}

//.filter__item {
//  border: 1px solid #ff9000;
//  display: flex;
//  align-items: center;
//  padding: 0 20px;
//  border-radius: 10px;
//  cursor: pointer;
//  height: 100%;
//
//  &.inactive {
//    pointer-events: none;
//  }
//
//  &.active {
//    background-color: rgba(255, 0, 0, 0.7);
//    color: #ffffff;
//    pointer-events: none;
//  }
//
//  &.approved {
//    background-color: rgba(green, 0.7);
//    color: #ffffff;
//  }
//}

//.finder-result__controls-toggler {
//  display: flex;
//  flex-direction: column;
//
//  & > * {
//    height: 50%;
//    width: 100%;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    flex-grow: 1;
//  }
//
//  & > *:hover:first-child {
//    background-color: rgba(green, 0.5);
//  }
//
//  & > *:hover:last-child {
//    background-color: rgba(red, 0.5);
//  }
//}

//.controls {
//  display: flex;
//  gap: 5px;
//
//  button {
//    border: 1px solid #ff9000;
//    padding: 5px;
//    background-color: #ffffff;
//    border-radius: 5px;
//    cursor: pointer;
//
//    &:hover {
//      background-color: rgba(#ff9000, 0.5);
//    }
//  }
//}


//.trained {
//  overflow: auto;
//  position: absolute;
//  top: 0;
//  right: 0;
//  bottom: 0;
//  left: 0;
//  background: rgba(0, 0, 0, 0.5);
//  color: #ffffff;
//  padding: 10px;
//}
//
//.trained__wrapper {
//  display: flex;
//  flex-direction: column;
//  justify-content: flex-end;
//
//  min-height: 100%;
//  height: min-content;
//}

//.trained__tags:not(:empty) {
//  display: flex;
//  flex-wrap: wrap;
//  gap: 3px;
//  margin-bottom: 3px;
//}
//
////.trained__tags.active {
////  background-color: rgba(#fff, 0.2);
////  padding: 2px;
////  border-radius: 3px;
////}
//
//.trained__tags span {
//  background-color: rgba(255, 255, 255, 0.7);
//  padding: 2px 4px;
//  border-radius: 3px;
//  display: inline-flex;
//  font-size: 13px;
//  color: #000;
//  cursor: pointer;
//
//
//  &.active {
//    background-color: rgba(255, 255, 255, 0.9);
//    //background-color: #000;
//    //color: #fff;
//    order: -1;
//  }
//
//  &.inactive {
//    background-color: rgba(#000, 0.7);
//    color: #fff;
//  }
//
//  &:hover {
//    background-color: #fff;
//    color: #000000;
//  }
//}

//.finder-result__item--loading::before {
//  top: 0;
//  left: 0;
//  content: '';
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  background-color: rgba(0, 0, 0, 0.3);
//  z-index: 10;
//  background-image: url(@/assets/spinner.gif);
//  background-repeat: no-repeat;
//  background-position: center;
//  background-size: 40px;
//}
</style>
