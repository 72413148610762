import {createApp} from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import ConfigStorageService from "@/services/config-storage-service";

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

// https://www.npmjs.com/package/tiny-emitter
// https://www.npmjs.com/package/mitt

fetch(process.env.BASE_URL + 'config.json')
    .then(res => res.json())
    .then(config => {
        if (config) {
            ConfigStorageService.store(config);

            const app = createApp(App).use(router).use(VueAwesomePaginate)
            router.isReady().then(() => app.use(store).mount('#app'))
        }
        window.__enableDebugMode = function () {
            window.__is_debug_mode__ = true;
        }
    })
