const CreateSelectionSwitcher = (arrayName) => {
  return (state, item, forceSelect) => {
    const alreadySelected = state[arrayName].find(selected => selected.guid === item.guid);

    if (alreadySelected || forceSelect) {
      state[arrayName] = state[arrayName].filter(selected => selected.guid !== item.guid);
      return;
    }

    state[arrayName].push(item);
  }
};

export default CreateSelectionSwitcher;
