<template>
  <div class="picset-tags" :class="{'loading': isLoading}">
    <div class="picset-tags__controls">
      <OnClickOutside @trigger="hideOriginTags">
        <div class="add-tags add-tags--main">
          <span>Main tag</span>
          <input type="text" :value="originTag"
                 @input="$emit('update:originTag', $event.target.value)"
                 placeholder="Type main tag"
                 @focus="showOriginTags">

          <div class="add-tags__selector tag-selector" v-if="serverOriginTags.length && isShowOriginTags">
            <div class="tag-selector__item"
                 v-for="tag in serverOriginTags" :key="tag.guid"
                 @click="addOriginTag(tag)"
            >
              {{ tag.title }}
            </div>
          </div>
        </div>
      </OnClickOutside>

      <OnClickOutside @trigger="hideTags">
        <div class="add-tags">
          <span>Second Tags</span>
          <input type="text" :value="secondTag"
                 @input="$emit('update:secondTag', $event.target.value)"
                 placeholder="Type second tag"
                 @focus="showTags">

          <div class="add-tags__selector tag-selector" v-if="serverTags.length && isShowTags">
            <div class="tag-selector__item"
                 :class="{'tag-selector__item--active': getSelectedStatus(tag)}"
                 v-for="tag in serverTags" :key="tag.guid"
                 @click="addSecondTag(tag)"
            >
              {{ tag.title }}
            </div>
          </div>
        </div>
      </OnClickOutside>
    </div>
    <div class="picset-tags__list">
      <div class="picset-tags__list-item picset-tags__list-item--origin" v-if="originTag">
        {{ originTag }}

        <button type="button" @click="removeOriginTag()">X</button>
      </div>

      <div class="picset-tags__list-item" v-if="secondTag">
        {{ secondTag }}

        <button type="button" @click="removeSecondTag()">X</button>
      </div>
    </div>
  </div>
</template>

<script>
import FinderService from "@/services/finder-service";
import {OnClickOutside} from '@vueuse/components'
import {throttle} from "throttle-debounce";

export default {
  name: 'AppPicsetTags',
  components: {
    OnClickOutside
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    originTag: {
      type: String,
      default: '',
    },
    secondTag: {
      type: String,
      default: '',
    }
  },
  emits: ['save-tags', 'update:originTag', 'update:secondTag', 'remove-second-tag', 'remove-origin-tag'],
  data: () => ({
    isOrigin: false,
    tagsValue: '',
    currentTags: null,
    serverTags: [],
    serverOriginTags: [],
    selectedTagsList: [],

    isShowTags: false,
    isShowOriginTags: false
  }),
  watch: {
    tagsValue(...args) {
      this.debouncedWatch(...args);
    }
  },
  created() {
    this.debouncedWatch = throttle(500, (val) => {
      this.selectedTagsList = [];
      const arr = val.split(',').map(el => el.trim());

      arr.forEach(arrItem => {
        const find = this.serverTags.find(el => el.title === arrItem);

        if (find) {
          const secondFind = this.selectedTagsList.find(el => el.guid === find.guid);

          if (!secondFind) {
            this.selectedTagsList.push(find);
          }
        }
      })
    });
  },
  beforeUnmount() {
    this.debouncedWatch.cancel();
  },
  mounted() {
    this.finderService = new FinderService();
    this.currentTags = this.tags;
    this.getServerTags();
    this.getServerOriginTags();
  },
  methods: {
    getServerOriginTags() {
      this.finderService.getOriginTagsList()
          .then(res => {
            this.serverOriginTags = res.origins;
          })
    },
    getServerTags() {
      this.finderService.getTagsList()
          .then(res => {
            this.serverTags = res.tags;
          })
    },
    showOriginTags() {
      this.isShowOriginTags = true;
    },
    showTags() {
      this.isShowTags = true;
    },
    hideTags() {
      this.isShowTags = false;
    },
    hideOriginTags() {
      this.isShowOriginTags = false;
    },
    getSelectedStatus(tag) {
      return this.selectedTagsList.find(el => el.title === tag.title);
    },
    collectTags() {
      return this.tagsValue.split(',')
          .map((el) => {
            return {
              'title': el.trim(),
              origin: this.isOrigin
            }
          })
          .filter(el => el.title !== '');
    },
    addOriginTag(tag) {
      this.$emit('update:originTag', tag.title);
      this.hideOriginTags();
    },
    addSecondTag(tag) {
      this.$emit('update:secondTag', tag.title);
      this.hideTags();
    },
    removeOriginTag() {
      this.$emit('remove-origin-tag');
    },
    removeSecondTag() {
      this.$emit('remove-second-tag');
    },
  }
}
</script>

<style scoped lang="scss">
.picset-tags {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.picset-tags__controls {
  display: flex;
  gap: 10px;
}

.picset-tags__list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.picset-tags__list-item {
  border-radius: 5px;
  padding-left: 5px;
  background-color: rgba(27, 27, 27, 0.5);
  color: #ffffff;
  overflow: hidden;
}

.picset-tags__list-item--origin {
  background-color: gold;
  color: var(--base-text);
}

.picset-tags__list-item button {
  border: none;
  padding: 0;
  width: 28px;
  height: 28px;
  background-color: rgba(27, 27, 27, 0.7);
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: rgba(27, 27, 27, 0.9);
  }
}

.add-tags {
  display: flex;
  gap: 3px;
  flex-direction: column;
  position: relative;

  & > span {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  & > input {
    height: 35px;
    width: 180px;
    padding: 0 5px;
    cursor: text;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }

  & > button {
    cursor: pointer;
    background-color: rgba(#ff9000, 0.5);
    border: 2px solid #ff9000;
    padding: 3px 10px;
    border-radius: 5px;
  }
}

.add-tags--main input {
  width: 100px;
}

.tag-selector {
  position: absolute;
  top: calc(100% + 5px);
  background-color: #fff;
  padding: 10px;
  min-width: 320px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  z-index: 11;

  max-height: 208px;
  overflow: auto;
}

.tag-selector__item {
  background-color: #ff9000;
  border-radius: 5px;
  padding: 5px 8px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
}

.tag-selector__item--active {
  background-color: green;
}

.add-tags__checkbox {
  font-size: 13px;

  input {
    display: none;
  }

  span {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      border: 2px solid #ff9000;
      left: 0;
    }

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: #ff9000;
      left: 4px;
      top: 4px;
      border-radius: 2px;
      opacity: 0;
    }
  }

  input:checked + span::after {
    opacity: 1;
  }
}
</style>