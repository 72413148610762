<template>
  <div class="view-toggle"
       :class="{'view-toggle--positive': !isNegativeView}">
    <span @click="onToggleClick(true)"
          :class="{'clickable': !isNegativeView}">Negative mode</span>
    <span @click="onToggleClick(false)"
          :class="{'clickable': isNegativeView}">Positive mode</span>
  </div>
</template>

<script>
export default {
  name: 'AppPicsetViewToggle',
  data: () => ({
    isNegativeView: true
  }),
  methods: {
    onToggleClick(status) {
      this.isNegativeView = status;
      this.$emit('view-toggle', status);
    }
  }
}
</script>

<style scoped lang="scss">
.view-toggle {
  user-select: none;
  display: flex;
  flex-direction: column;
  border: 2px solid #ff9000;
  font-weight: bold;
  border-radius: 10px;
  overflow: hidden;
}

.view-toggle input {
  display: none;
}

.view-toggle span {
  width: 130px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.view-toggle:not(.view-toggle--positive) span:first-of-type {
  background-color: rgba(red, 0.7);
}

.view-toggle--positive span:last-of-type {
  background-color: rgba(green, 0.7);
}

.view-toggle span:hover {
  &:first-of-type {
    background-color: rgba(red, 0.2);
  }

  &:last-of-type {
    background-color: rgba(green, 0.2);
  }
}

.clickable {
  cursor: pointer;
  pointer-events: all !important;
}
</style>