<template>
  <div class="item"
       :class="{'item--loading': item.state !== 'ARCHIVED'}"
  >
    <div class="item__title">
      <h2 @click="filterByOrigin(item.origin.guid)"
          :class="{'disabled': !item.origin}"
      >
        {{ item.title }}
      </h2>
      <b v-if="+item.archived">[{{ item.archived }}]</b>
      <div class="item__owner"
           :class="{'item__owner--admin': item.owner === 'admin'}"
           v-if="isUserAdmin"
           @click="filterByOwner(item.owner)"
      >
        {{ item.owner }}
      </div>
    </div>

    <div class="item__updated">
      <b>{{ item.state }}</b> {{ getDate(item.updated, true) }}
    </div>

    <div class="item__picsets-title">
      Picsets in archive - [{{ item.picsets.length }}]
    </div>

    <div class="item__picsets">
        <span :class="{'hidden': index >= showPicsetLimit && !isAllPicsetsShow}"
              :title="picset.query"
              v-for="(picset, index) in item.picsets"
              :key="picset.guid"
        >
          <b>({{ +picset.saved || +picset.quantity }})</b>
          <span class="item__tag"
                v-if="picset.tags.length"
                @click="filterBySecond(picset.tags[0].title)"
          >
            {{ picset.tags[0].title }}
          </span>
          {{ picset.query }}
        </span>

      <button type="button"
              v-if="item.picsets.length > showPicsetLimit && !isAllPicsetsShow"
              @click="showAllPicsetsInArchive(item)"
      >Show all
      </button>
    </div>

    <div class="item__controls">
      <a :href="downloadEndpoint + item.filepath"
         v-if="item.filepath"
         class="item__button">
        Download
      </a>

      <div class="item__button item__button--remove"
           @click="remove">
        Remove
      </div>
    </div>

  </div>
</template>

<script>
import ConfigStorageService from "@/services/config-storage-service";
import {mapState} from "vuex";

export default {
  name: 'AppMultiArchivesItem',
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  emits: ['remove', 'filter-origin', 'filter-second', 'filter-owner'],
  data: () => ({
    showPicsetLimit: 4,
    downloadEndpoint: null,
    isAllPicsetsShow: false,
  }),
  mounted() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
  },
  computed: {
    ...mapState([
      'isUserAdmin',
    ]),
  },
  methods: {
    filterByOwner(owner) {
      this.$emit('filter-owner', owner);
    },
    filterByOrigin(guid) {
      this.$emit('filter-origin', guid);
    },
    filterBySecond(guid) {
      this.$emit('filter-second', guid);
    },
    showAllPicsetsInArchive() {
      this.isAllPicsetsShow = true;
    },
    getDate(date, withTime = false) {
      const value = new Date(date);
      let result = '';
      if (!withTime) {
        result = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()}`;
      } else {
        result = `${value.getDate()}.${value.getMonth() + 1}.${value.getFullYear()} ${value.getHours()}:${value.getMinutes()}`;
      }

      return result;
    },
    remove() {
      this.$emit('remove', this.item.guid);
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.item {
  border: 1px solid #1b1b1b;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  background-color: #fff;
}

.item--loading {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(#000, 0.4);
  }

  &::after {
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    border: 3px solid #ffffff;
    border-bottom: none;
    border-right: none;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.item__updated {
  font-size: 0.7rem;
}

.item__controls {
  display: flex;
  gap: 10px;
  margin-top: auto;
  position: relative;
}

.item__button {
  display: inline-block;
  cursor: pointer;
  border: none;
  background-color: #ff9000;
  border-radius: 5px;
  padding: 8px 10px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
  color: #ffffff;
}

.item__button--remove {
  background-color: red;
}

.item__picsets {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;

  span {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 2px;
  }

  button {
    background-color: rgba(#ff9000, 0.2);
    border: 1px solid #ff9000;
    padding: 3px;
    cursor: pointer;
  }
}

.item__picsets-title {
  font-weight: bold;
  width: 100%;
}

.item__tags {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
  text-transform: lowercase;
}

.item__tag {
  border-radius: 5px;
  padding: 3px 4px;
  background-color: rgba(27, 27, 27, 0.5);
  color: #ffffff;
  font-size: 12px;
  line-height: 1;
  display: inline-block;
  text-transform: lowercase;
  cursor: pointer;
}

.item__title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.item__title h2 {
  border-radius: 5px;
  padding: 5px 8px;
  background-color: rgba(27, 27, 27, 0.5);
  cursor: pointer;
  background-color: gold;
  color: var(--base-text);

  font-size: inherit;
  line-height: inherit;
  margin: 0;
  font-weight: 400;
}

.item__title .disabled {
  background-color: #ffffff;
  cursor: no-drop;
  pointer-events: none;
}

.item__owner {
  color: #1b1b1b;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: rgba(pink, 0.6);
  cursor: pointer;

  &--admin {
    background-color: rgba(green, 0.3);
  }
}
</style>