<template>
  <div class="groups"
       :class="{'loading': isLoading}"
  >
    <div class="group__header">
      <template v-if="!isAddNewGroupMode">
        <div class="group__header-title" @click="setAddNewGroupStatus(true)">
          + Add new group
        </div>
      </template>

      <template v-else>
        <div class="group__header-input">
          <input type="text" v-model="newGroupValue" autofocus @keydown.enter="onKeyEnterAddNewGroup">

          <button type="button" class="button accept" :disabled="!newGroupValue.trim()" @click="addNewGroup">Create
          </button>
          <button type="button" class="button reject" @click="setAddNewGroupStatus(false)">Cancel</button>
        </div>
      </template>
    </div>

    <div class="group__content">
      <div class="group__item item"
           :class="{'item--selected': selectedGroupGuid === item.guid}"
           v-for="item in list"
           :key="item.guid"
           @click="selectGroup(item.guid)"
      >
        <div class="item__info">
          <div class="item__title" v-if="!item.isEditTitle">
            {{ item.title }}

            <button type="button" class="button accept" @click.stop="editGroupTitle(item)">
              <svg width="20" height="20">
                <use xlink:href="@/assets/icons.svg#edit"></use>
              </svg>
            </button>
          </div>

          <div class="item__edit-title" v-else>
            <input type="text" v-model="item.title" autofocus @keydown.enter="onKeyEnterUpdateGroupTitle(item)">

            <button type="button" class="button accept" @click.stop="updateGroupTitle(item)"
                    :disabled="!item.title.trim()">
              <svg width="20" height="20">
                <use xlink:href="@/assets/icons.svg#save"></use>
              </svg>
            </button>
            <button type="button" class="button reject" @click.stop="cancelGroupTitle(item)">
              <svg width="20" height="20">
                <use xlink:href="@/assets/icons.svg#cross"></use>
              </svg>
            </button>
          </div>

          <div class="item__categories-count">
            {{ item.categories.length }} categories
          </div>

          <div class="item__remove button reject" @click.stop="removeGroup(item.guid)">
            Remove
          </div>
        </div>

        <div class="item__categories" v-if="item.categories.length">
          <span class="item__category" v-for="category in item.categories" :key="category.guid">
            {{ category.title }}

            <button type="button" @click.stop="removeCategoryFromGroup(item.guid, category.guid)">
              <svg width="20" height="20">
                <use xlink:href="@/assets/icons.svg#remove"></use>
              </svg>
            </button>
          </span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppGroupList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    selectedGroupGuid: {
      type: String,
      default: ''
    }
  },
  emits: ['addNewGroup', 'removeGroup', 'updateGroupTitle', 'selectGroup', 'removeCategoryFromGroup'],
  data: () => ({
    isAddNewGroupMode: false,

    newGroupValue: ''
  }),
  methods: {
    setAddNewGroupStatus(status) {
      this.isAddNewGroupMode = status;
    },
    onKeyEnterAddNewGroup() {
      if (this.newGroupValue.trim()) {
        this.addNewGroup();
      }
    },
    addNewGroup() {
      this.$emit('addNewGroup', this.newGroupValue.trim());
      this.newGroupValue = '';
    },
    removeGroup(guid) {
      this.$emit('removeGroup', guid);
    },
    editGroupTitle(item) {
      item.isEditTitle = true;
      item.tempTitle = item.title;
    },
    onKeyEnterUpdateGroupTitle(item) {
      if (item.title.trim()) {
        this.updateGroupTitle(item);
      }
    },
    updateGroupTitle(item) {
      this.$emit('updateGroupTitle', item);
    },
    cancelGroupTitle(item) {
      item.isEditTitle = false;
      item.title = item.tempTitle;
    },
    selectGroup(guid) {
      this.$emit('selectGroup', guid);
    },
    removeCategoryFromGroup(groupGuid, categoryGuid) {
      this.$emit('removeCategoryFromGroup', [groupGuid, categoryGuid]);
    }
  }
}
</script>

<style scoped lang="scss">
.groups {
  box-shadow: 0 0 5px rgba(#000, 0.3);
  background-color: #fff;
  border-radius: 5px;
  max-height: calc(100vh - 58px - 20px * 2);
  overflow: auto;
}

.group__header-title {
  background-color: #ff9000;
  padding: 10px 20px;
  cursor: pointer;
  height: 38px;

  &:hover {
    background-color: rgba(#ff9000, 0.8);
  }
}

.group__content {
}

.group__item {
  background: #fff;

  &:hover {
    background-color: rgba(#ff9000, 0.4);
  }
}

.item {
  position: relative;
}

.item--selected {
  background-color: rgba(#ff9000, 0.6) !important;
}

.item__info {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.item__title {
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 32px;
  fill: #ffffff;

}

.item__title button {
  padding: 3px 5px;
  border-radius: 5px;
  height: 32px;
  width: 32px;

  &:hover {
    opacity: 0.8;
  }
}

.item__remove {
  border-radius: 5px;
  display: none;
  position: absolute;
  right: 20px;
}

.item:hover .item__remove {
  display: block;
}

.item__categories-count {
  font-style: italic;
  color: #555
}

.item__categories {
  padding: 5px 20px;
  border-top: 1px solid rgba(#ff9000, 0.2);
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.item__category {
  color: #ffffff;
  background-color: rgba(#ff9000, 1);
  position: relative;
  fill: #ffffff;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  gap: 5px;
}

.item__category button {
  background-color: transparent;
  border: none;
  border-left: 1px solid #d5d5d5;
  color: #ffffff;
  width: 32px;
  height: 100%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.group__header-input {
  height: 38px;

  display: flex;
}

.group__header-input input {
  flex-grow: 1;
  border: 2px solid #ff9000;
  outline: none;
  border-radius: 5px 0 0 0;
  padding: 0 20px;
  font-family: inherit;

  &:focus {
    border-color: #333;
  }
}

.item__edit-title {
  display: flex;
  fill: #ffffff;
}

.item__edit-title button {
  height: 32px;
  width: 32px;
  padding: 0;
}

.item__edit-title input {
  height: 32px;
  padding: 0 6px;
  border: 1px solid #ff9000;
  border-right: none;
  width: 120px;
  outline: none;
}
</style>