import UserService from "@/services/user-service";
import HttpService from "@/services/http-service";

export default class ArchivesService extends HttpService {
    constructor() {
        super();
        this.token = UserService.getToken();
        this.headers = {
            Authorization: `bearer ${this.token}`
        };
    }

    // Получить список мультиархивов
    getMultiArchives(page, limit, sort = 'created desc', tags, originTag, owner = '') {
        let url = `archive?`;
        if (owner && originTag) {
            url = url + `_filter=origin_guid == '${originTag}'`;
            url = url + `and owner == '${owner}'`;
        } else if (originTag) {
            url = url + `_filter=origin_guid == '${originTag}'`;
        } else if (owner) {
            url = url + `_filter=owner == '${owner}'`;
        }

        if (sort) {
            url = url + `&_order_by=${sort}`;
        }

        if (page) {
            url = url + `&_offset=${(page - 1) * limit}`;
        }

        if (limit) {
            url = url + `&_limit=${limit}`;
        }

        return this.http(url, 'POST', this.headers, JSON.stringify(tags));
    }

    // Удалить выбранный архив
    deleteArchive(guid) {
        const url = `archive/${guid}`;

        return this.http(url, 'DELETE', this.headers);
    }

    setArchiveAcl(guid, data) {
        const url = `archive/${guid}/acl`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }
}