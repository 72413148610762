const neuralNetworkInfoControlMixin = {
    data: () => ({
        showNeuralNetworkInfo: false,
    }),
    methods: {
        toggleNeuralNetworkInfo() {
            this.showNeuralNetworkInfo = !this.showNeuralNetworkInfo;
        }
    }
}

export default neuralNetworkInfoControlMixin;
