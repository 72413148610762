<template>
  <div class="finder-result__item"
       :class="{'loading-item': item.loading}"
  >
    <span class="picture-expand" @click="$emit('expand')">
      <span class="picture-expand__size">
        {{ item.width }}x{{ item.height }}
      </span>
      <svg width="15" height="15">
        <use xlink:href="@/assets/icons.svg#expand"></use>
      </svg>
    </span>

    <app-validated-picset-persons-navigation :persons="filteredPersons"
                                             v-model:activeIndex="activePerson"
                                             @clear-active-tag="activeLabel = ''"

    />

    <div class="finder-result__image"
         :class="{'finder-result__image--vertical': orientation === 'vertical'}"
    >
      <app-validated-picset-zone v-for="(person, personIndex) in filteredPersons"
                                 :key="person.gender[0] + (person.index)"
                                 :title="person.gender[0] + (person.index)"
                                 :gender="person.gender"
                                 :bbox="person.bbox"
                                 :show="activePerson === -1 || activePerson === person.originalIndex"
                                 @zone-click="activePerson = activePerson === personIndex ? -1 : person.originalIndex"
      />
      <img class="lazyload"
           :data-src="getThumbUrl(item)"
           alt=""
      />
    </div>

    <app-validated-picture-info :trained="filteredPersons"
                                :active-index="activePerson"
                                :counts="categoriesCount"
                                :filter-by-group="filterByGroup"
                                v-model:active-label="activeLabel"
                                @remove-label="onRemoveLabel"
                                @add-label="onAddLabel"
    />
  </div>
</template>


<script>
import ConfigStorageService from "@/services/config-storage-service";

// import ItemValidatedCategories from "@/modules/validated-picset/components/ItemValidatedCategories";
import AppValidatedPictureInfo from "@/modules/checked-picset/components/CheckedPictureInfo.vue";
import AppValidatedPicsetZone from "@/modules/checked-picset/components/CheckedPicsetZone.vue";
import AppValidatedPicsetPersonsNavigation from "@/modules/checked-picset/components/CheckedPicsetPersonsNavigation.vue"
import {mapMutations} from "vuex";

export default {
  name: 'ValidatedThumbItem',
  components: {
    AppValidatedPictureInfo,
    AppValidatedPicsetZone,
    AppValidatedPicsetPersonsNavigation,
    // ItemValidatedCategories,
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    },
    orientation: {
      type: String,
      default: ''
    },
    filterCategoriesList: {
      type: Map,
      default: new Map()
    },
    currentFilter: {
      type: Array,
      default: () => []
    },
    filterByGroup: {
      type: String,
      default: '',
    }
  },
  emits: ['preview'],
  data: () => ({
    downloadEndpoint: null,
    activePerson: -1,
    activeLabel: '',
  }),
  computed: {
    categoriesCount() {
      let categoriesList = [];
      const result = new Map();

      this.item?.trained
          ?.filter(person => person.gender !== 'male')
          ?.forEach(person => {
            person.groups?.forEach(group => {
              categoriesList = [...categoriesList, ...group.category];
            });
          });

      categoriesList.forEach(category => {
        result.has(category)
            ? result.set(category, result.get(category) + 1)
            : result.set(category, 1);
      });

      return result;
    },
    filteredPersons() {
      if (!this.item || !this.item.trained.length) return [];

      const rawPersons = this.item.trained;

      const males = rawPersons
          .map((person, index) => {
            return ({...person, originalIndex: index });
          })
          .filter(person => {
            return String(person.gender).toLowerCase() === 'male';
          })
          .map((person, index) => {
            return ({...person, index: index + 1 });
          });

      const females = rawPersons
          .map((person, index) => {
            return ({...person, originalIndex: index });
          })
          .filter(person => {
            return String(person.gender).toLowerCase() === 'female';
          })
          .map((person, index) => {
            return ({...person, index: index + 1 });
          });

      const persons = [...females, ...males];

      if (!this.activeLabel) return persons;

      return persons.filter(person => {
        return person.groups.some(group => {
          return group.category?.some(category => {
            return String(category).trim().toLowerCase() === this.activeLabel;
          });
        });
      });
    },
  },
  mounted() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
  },
  methods: {
    ...mapMutations(['addCategory', 'removeCategory']),
    onRemoveLabel(label) {
      const data = {
        picture: this.item,
        pictureGuid: this.pictureGuid,
        category: label,
        personIndex: this.activePerson,
      }
      this.removeCategory(data);
    },
    onAddLabel(label) {
      const data = {
        picture: this.item,
        category: label.category,
        personIndex: this.activePerson,
        pictureGuid: this.pictureGuid,
        group: label.group,
      }
      this.addCategory(data);
    },
    showPreview() {
      this.$emit('preview', this.item.id);
    },
    getThumbUrl(thumb) {
      if (thumb.filepath) {
        return this.downloadEndpoint + thumb.filepath;
      } else {
        return thumb.url;
      }
    },
    getItemTrained(key, trained) {
      return trained.find(item => item.group === key)
    },
  },
  watch: {
    activePerson: {
      handler(newValue) {
        if (newValue === -1) {
          this.activeLabel = '';
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.finder-result__item {
  position: relative;
}

.finder-result__image {
  //aspect-ratio: 16/9;
  background-color: #ff9000;
  line-height: 0;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.finder-result__image--vertical {
  aspect-ratio: 9/16;
}


.trained {
  overflow: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  padding: 10px;
}

.trained__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  min-height: 100%;
  height: min-content;
}

.picture-expand {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding-inline: 3px;
  height: 17px;
  font-size: 11px;
  background-color: #fff;
  border: 1px solid #222;
  position: absolute;
  right: 0;
  top: 2px;
  z-index: 1;
  cursor: pointer;
}
</style>
