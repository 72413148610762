<template>
  <!--  Filter params   -->
  <div class="group-search-params">
    <div class="group-search-params__row">
      <!--  First letter   -->
      <label class="group-search-params__input">
        <span>First letter</span>
        <select name="letter" id="letter" @change="onLetterSelect">
          <option value="All">All</option>
          <option value="A">A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
          <option value="E">E</option>
          <option value="F">F</option>
          <option value="G">G</option>
          <option value="H">H</option>
          <option value="I">I</option>
          <option value="K">K</option>
          <option value="L">L</option>
          <option value="M">M</option>
          <option value="N">N</option>
          <option value="O">O</option>
          <option value="P">P</option>
          <option value="Q">Q</option>
          <option value="R">R</option>
          <option value="S">S</option>
          <option value="T">T</option>
          <option value="U">U</option>
          <option value="V">V</option>
          <option value="W">W</option>
          <option value="X">X</option>
          <option value="Y">Y</option>
          <option value="Z">Z</option>
        </select>
      </label>

      <label class="group-search-params__input">
        <span>Has group</span>
        <select name="letter" id="type" @change="onTypeSelect">
          <option value="ungrouped" selected>Ungrouped</option>
          <option value="grouped">Grouped</option>
          <option value="all">All</option>
        </select>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppGroupCategoriesControls',
  props: {
  },
  data: () => ({
  }),
  methods: {
    onLetterSelect(event) {
      this.$emit('select-letter', event.currentTarget.value);
    },
    onTypeSelect(event) {
      this.$emit('select-type', event.currentTarget.value);
    },
  },
};
</script>

<style scoped lang="scss">
.group-search-params {
  margin-bottom: 15px;
}

.group-search-params__row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.group-search-params__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  & > span {
    display: inline-flex;
    gap: 5px;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    user-select: none;
  }

  & > select {
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid #ff9000;
  }
}
</style>
