<template>
  <div class="zone"
       v-if="show && beforeShowChecks"
       :style="style"
       :title="title"
       :class="{
         'zone--male': String(gender).toLowerCase() === 'male',
       }"
       @click="$emit('zoneClick')"
  />
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AppZone",
  props: {
    bbox: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    gender: {
      type: String,
      required: true,
    }
  },
  emits: [
    'zoneClick'
  ],
  computed: {
    beforeShowChecks() {
      return this.bbox.length && this.gender && this.title;
    },
    style() {
      return ({
        left: +this.bbox[0] * 100 + '%',
        right: 100 - (+this.bbox[2] * 100) + '%',
        top: +this.bbox[1] * 100 + '%',
        bottom: 100 - (+this.bbox[3] * 100) + '%',
      });
    },
  },
})
</script>

<style lang="scss">
  $femaleColor: fuchsia;
  $maleColor: chartreuse;

  .zone {
    position: absolute;
    border: 2px solid $femaleColor;
    border-radius: 1px;
    transition: .1s;

    &:hover {
      background-color: rgba($femaleColor, .2);
      border-color: lighten($femaleColor, 10%);
    }

    &::before {
      content: attr(title);
      display: inline-flex;
      align-items: center;
      background-color: $femaleColor;
      width: fit-content;
      font-size: 11px;
      position: absolute;
      left: -2px;
      top: -2px;
      padding: 1px 5px;
      color: #fff;
      text-transform: capitalize;
      line-height: 1;
      transition: inherit;
      border-radius: 1px;
    }

    &:hover::before {
      background-color: lighten($femaleColor, 10%);;
    }
  }

  .zone--male {
    border-color: $maleColor;

    &:hover {
      background-color: rgba($maleColor, .2);
      border-color: lighten($maleColor, 10%);
    }

    &::before {
      background-color: $maleColor;
      color: #000;
    }

    &:hover::before {
      background-color: lighten($maleColor, 10%);;
    }
  }
</style>
