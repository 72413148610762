<template>
  <div class="picset-header">
    <AppPicsetInfo v-if="picsetInfo"
                   :info="picsetInfo"
    ></AppPicsetInfo>

    <AppPicsetViewToggle
        @view-toggle="onViewToggle"
    ></AppPicsetViewToggle>

    <AppPicsetTags v-if="picsetInfo"
                   :tags="picsetInfo.tags"
                   v-model:originTag="originTag"
                   v-model:secondTag="secondTag"
                   :is-loading="isLoading"
                   @save-tags="saveTags"
                   @remove-origin-tag="removeOriginTag"
                   @remove-second-tag="removeSecondTag"
    ></AppPicsetTags>
  </div>

  <div class="picset-header__selects">
    <div style="margin-bottom: 10px; width:400px; max-width: 505px" v-if="picsetInfo && groupsList.length">
      <Multiselect
          v-model="picsetGroup"
          :options="groupsList"
          placeholder="Select origin group"
          searchable
      />
    </div>

    <div style="margin-bottom: 10px; width:400px; max-width: 505px" v-if="picsetInfo && groupsList.length">
      <Multiselect
          v-model="picsetCategory"
          :options="categoriesList"
          :disabled="!picsetGroup"
          placeholder="Select origin category"
          searchable
      />
    </div>
  </div>

  <AppPicsetItems
      :is-negative-mode="isNegativeView"
      :items="thumbs"
      :pic-items="pictures"
      :excluded-items="excludedThumbs"
      :excluded-pic-items="excludedPictures"
      :is-loading="isLoading"
      :orientation="orientation"
      :is-only-filtered="isOnlyFiltered"
      :is-ready="picsetInfo?.isReady || false"
      @preview="showPreview"
      @save="savePicset"
      @toggle-excluded="toggleFiltered"
      @search-by-img="showCreate"
      @show-all="showAll"
      @mark-picset="onMarkPicset"
  ></AppPicsetItems>

  <div class="archives-list__controls">
    <div class="finder-form__select">
      <span>Count</span>
      <select v-model="perPage" @change="setPerPage">
        <option v-for="(item, index) in perPageOptions"
                :key="index"
                :value="item">{{ item }}
        </option>
      </select>
    </div>

    <vue-awesome-paginate
        :total-items="isOnlyFiltered ? excludedPictures && excludedPictures.length : pictures && pictures.length"
        :items-per-page="perPage"
        :max-pages-shown="5"
        v-model="currentPage"
        :on-click="setPage"
    ></vue-awesome-paginate>
  </div>

  <AppPicsetPreview v-if="previewPic"
                    :pic="previewPic"
                    :index="currentIndex"
                    :len="isOnlyFiltered ? excludedThumbs.length : thumbs.length"
                    @prev="showPrev"
                    @next="showNext"
                    @close="closePreview"
  ></AppPicsetPreview>

  <AppPricsetSearchPopup v-if="searchUrl"
                         :url="searchUrl"
                         @on-create-picset="createPicset"
                         @close="closeCreatePicsetPopup"
  >

  </AppPricsetSearchPopup>

  <div class="overlay-spinner"
       :class="{
          'overlay-spinner--loading': showSpinner
       }"
  />
</template>

<script>
import FinderService from "@/services/finder-service";
import SeriesService from "@/services/series-service";
import ConfigStorageService from "@/services/config-storage-service";

import Multiselect from "@vueform/multiselect";

import getPageFromQuery from "@/mixins/getPageFromQuery";

import AppPicsetItems from "@/modules/picset/picset-items/PicsetItems";
import AppPicsetInfo from "@/modules/picset/picset-info/PicsetInfo";
import AppPicsetPreview from "@/modules/picset/picset-preview/PicsetPreview";
import AppPicsetTags from "@/modules/picset/picset-tags/PicsetTags";
import AppPricsetSearchPopup from "@/modules/picset/picset-search-popup/PicsetSearchPopup";
import AppPicsetViewToggle from "@/modules/picset/picset-view-toggle/PicsetViewToggle";

export default {
  name: 'AppPicset',
  components: {
    Multiselect,
    AppPicsetTags,
    AppPicsetPreview,
    AppPicsetInfo,
    AppPicsetItems,
    AppPricsetSearchPopup,
    AppPicsetViewToggle,
  },
  mixins: [
    getPageFromQuery,
  ],
  data: () => ({
    showSpinner: true,
    downloadEndpoint: null,
    isOnlyFiltered: false,
    finderService: null,

    isLoading: false,

    orientation: 'horizontal',

    picsetGuid: '',
    picsetInfo: null,

    thumbs: [],
    pictures: [],

    excludedThumbs: [],
    excludedPictures: [],

    previewPic: '',
    prevPic: '',
    nextPic: '',
    picIndex: 0,
    currentIndex: 0,

    currentPage: 1,
    perPage: 100,
    perPageOptions: [50, 100, 200],
    totalPages: 500,

    searchUrl: '',

    isNegativeView: true,

    isFiltered: false,

    seriesService: null,
    groupsList: [],
    groupsListRaw: [],
    picsetGroups: [],
  }),
  created() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
    this.finderService = new FinderService();
    this.seriesService = new SeriesService();
    this.picsetGuid = this.$route.params.guid;
  },
  mounted() {
    this.getPicsetInfo();
    this.getPicsetPics().then(() => {
      setTimeout(() => {
        this.getGroupsList();
        this.getPicsetThumbs();
        this.getExcludedThumbs();
      }, 500);
    });
  },
  computed: {
    categoriesList() {
      const categoriesList = this.groupsListRaw
          .find(item => item.title === this.picsetGroup)
          ?.categories
          ?.map(item => item.title);
      return categoriesList || [];
    },
    picsetGroup: {
      get() {
        return this.picsetInfo && this.picsetInfo.series.length && this.picsetInfo.series[0].title;
      },
      set(val) {
        if (this.picsetGroup !== val) this.picsetInfo.categories = [];
        this.picsetInfo['series'] = [{
          title: val
        }];
      }
    },
    picsetCategory: {
      get() {
        return this.picsetInfo && this.picsetInfo.categories.length && this.picsetInfo.categories[0].title;
      },
      set(val) {
        this.picsetInfo['categories'] = [{
          title: val
        }];
      }
    },
    originTag: {
      get() {
        return this.picsetInfo && this.picsetInfo.origin && this.picsetInfo.origin.title;
      },
      set(val) {
        this.picsetInfo['origin'] = {
          title: val
        }
      }
    },
    secondTag: {
      get() {
        const tags = this.picsetInfo && this.picsetInfo.tags;
        return tags.map(el => el.title).join(', ');
      },
      set(val) {
        this.picsetInfo.tags = [];

        this.picsetInfo.tags[0] = {
          title: val
        }
      }
    }
  },
  unmounted() {
    this.picsetGuid = '';
    this.picsetInfo = null;
    this.thumbs = [];
    this.pictures = [];

    this.excludedThumbs = [];
    this.excludedPictures = [];
  },
  methods: {
    onMarkPicset() {
      const data = {
        guid: this.picsetGuid,
        isReady: !this.picsetInfo.isReady,
      }

      this.finderService.setReadiness(this.picsetGuid, data).then(this.getPicsetInfo.bind(this));
    },
    getGroupsList() {
      this.seriesService.getGroupList()
          .then(res => {
            this.groupsListRaw = res.series;
            this.groupsList = res.series.map(el => el.title);
          })
    },
    removeSecondTag() {
      this.picsetInfo.tags = [];
    },
    removeOriginTag() {
      this.originTag = '';
    },
    saveTags(tagsList) {
      this.picsetInfo.tags = tagsList;
    },
    showAll() {
      this.isOnlyFiltered = false;
    },
    toggleFiltered(ids) {
      this.isLoading = true;

      const data = {
        picsetGuid: this.picsetGuid,
        ids: ids
      }

      this.finderService.savePics(this.picsetGuid, data)
          .finally(() => this.isLoading = false)
          .then(() => {
            this.currentPage = 1;

            this.isOnlyFiltered = !this.isOnlyFiltered;
            this.getPicsetThumbs();
            this.getPicsetPics();
          })
    },
    setPerPage() {
      this.getPicsetThumbs();
    },
    setPage(val) {
      this.currentPage = val;
      this.getPicsetThumbs();
    },
    closePreview() {
      this.previewPic = "";
    },
    getUrl(el) {
      return el.filepath ? this.downloadEndpoint + el.filepath : el.url;
    },
    showPrev() {
      const arrPictures = this.isOnlyFiltered ? this.excludedPictures : this.pictures;
      const arrThumbs = this.isOnlyFiltered ? this.excludedThumbs : this.thumbs;

      this.currentIndex--;
      const index = arrPictures.findIndex(item => item.id === arrThumbs[this.currentIndex].id);
      this.previewPic = this.getUrl(arrPictures[index]);
    },
    showNext() {
      const arrPictures = this.isOnlyFiltered ? this.excludedPictures : this.pictures;
      const arrThumbs = this.isOnlyFiltered ? this.excludedThumbs : this.thumbs;

      this.currentIndex++;
      const index = arrPictures.findIndex(item => item.id === arrThumbs[this.currentIndex].id);
      this.previewPic = this.getUrl(arrPictures[index]);
    },

    showPreview(id) {
      const arrPictures = this.isOnlyFiltered ? this.excludedPictures : this.pictures;
      const arrThumbs = this.isOnlyFiltered ? this.excludedThumbs : this.thumbs;

      this.currentIndex = arrThumbs.findIndex(item => item.id === id);
      this.picIndex = arrPictures.findIndex(item => item.id === id);
      this.previewPic = this.getUrl(arrPictures[this.picIndex]);
    },
    savePicset(ids) {
      this.isLoading = true;

      const dataTags = {
        guid: this.picsetGuid,
        tags: this.picsetInfo.tags
      };
      const dataPics = {
        guid: this.picsetGuid,
        ids: ids
      }
      const dataSeries = {
        guid: this.picsetGuid,
        series: this.picsetGroup ? [{ title: this.picsetGroup }] : [],
      }

      const dataCategories = {
        guid: this.picsetGuid,
        categories: this.picsetCategory ? [{ title: this.picsetCategory }] : [],
      }

      const promiseTags = this.finderService.tagsUpdate(this.picsetGuid, dataTags);
      const promiseSeries = this.finderService.setSeries(this.picsetGuid, dataSeries);
      const promiseCategories = this.finderService.setCategories(this.picsetGuid, dataCategories);
      const promises = [];
      promises.push(promiseTags);
      promises.push(promiseSeries);
      promises.push(promiseCategories);

      if (this.picsetInfo?.origin?.title) {
        const dataOrigin = {
          guid: this.picsetGuid,
          origin: {
            title: this.picsetInfo?.origin?.title || ''
          }
        };
        const promiseOrigin = this.finderService.originTagUpdate(this.picsetGuid, dataOrigin);
        promises.push(promiseOrigin)
      } else {
        const promiseRemoveOrigin = this.finderService.removeOriginTag(this.picsetGuid);
        promises.push(promiseRemoveOrigin)
      }

      Promise.allSettled(promises)
          .then(() => {
            this.finderService.savePics(this.picsetGuid, dataPics)
                .finally(() => this.isLoading = false)
                .then(() => {
                  this.$router.push('/');
                })
                .catch((err) => {
                  console.log(err);
                })
          })
    },
    getPicsetInfo() {
      this.finderService.getPicset(this.picsetGuid)
          .then((res) => {
            this.picsetInfo = res.result;

            this.picsetGroups = this.picsetInfo.series.map(el => el.title);

            this.orientation = this.picsetInfo.orientation;
          })
    },
    getPicsetThumbs() {
      this.showSpinner = true;
      this.finderService.getPicsetThumbs(this.picsetGuid, this.currentPage, this.perPage, this.isOnlyFiltered, this.isNegativeView)
          .then(res => {
            const withPicturesThumbs = res.thumbs.filter(thumb => {
              return this.pictures.find(picture => picture.id === thumb.id);
            });

            if (this.isOnlyFiltered) {
              this.excludedThumbs = withPicturesThumbs;
            } else {
              this.thumbs = withPicturesThumbs;
            }
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          })
          .finally(() => {
            this.showSpinner = false;
          });
    },
    getExcludedThumbs() {
      this.finderService.getPicsetThumbs(this.picsetGuid, this.currentPage, this.perPage, true, true)
          .then(res => {
            this.excludedThumbs = res.thumbs.filter(thumb => {
              return this.pictures.find(picture => picture.id === thumb.id);
            });
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          })
    },
    async getPicsetPics() {
      this.finderService.getPicsetPics(this.picsetGuid, this.isOnlyFiltered, this.isNegativeView)
          .then(res => {
            if (this.isOnlyFiltered) {
              this.excludedPictures = res.pictures;
            } else {
              this.pictures = res.pictures
            }
          })
    },
    closeCreatePicsetPopup() {
      this.searchUrl = '';
    },
    showCreate(url) {
      this.searchUrl = url;
    },
    createPicset(req) {
      const data = {
        payload: req
      }

      this.finderService.makeNewPicset(data)
          .then(() => {
            this.$router.push('/');
          })
          .finally(() => this.isLoading = false);
    },
    onViewToggle(status) {
      this.isNegativeView = status;

      if (this.isOnlyFiltered) {
        this.getPicsetThumbs();
        this.getPicsetPics();
      }
    }
  }
}
</script>

<style scoped lang="scss">
.archives-list__controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finder-form__select {
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    margin-right: 10px;
  }

  select {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }
}

.picset-header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}

.picset-header__selects {
  display: flex;
  align-items: center;
  gap: 10px;
}

.multiselect {
  min-height: 30px !important;
  height: 30px !important;
}

::v-deep .multiselect .multiselect-wrapper {
  min-height: 30px !important;
}

::v-deep .multiselect-search {
  position: relative;
}

.overlay-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: fixed;
  inset: 0;
  transition: .5s ease-out;
  z-index: -1;
}

.overlay-spinner--loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: fixed;
  inset: 0;
  z-index: 999;
  background-color: rgba(#000, .2);
  backdrop-filter: blur(5px);

  &::before {
    content: "Loading. Please wait";
    font-weight: bold;
    color: #ffffff;
    text-shadow: #555555 1px 1px 3px;
  }

  &::after {
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    border: 10px solid rgba(darkorange, .1);
    border-right-color: darkorange;
    border-radius: 50%;

    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
