<template>
  <div class="row">
    <div class="controls">
      <button type="button" @click="selectAll">Select All</button>
      <button type="button" @click="unselectAll">Unselect all</button>
    </div>
    <h1>{{ title }} ({{ count }} items)</h1>
    <div class="buttons">
      <select v-if="isSomeSelected && usersList.length && isUserAdmin"
              v-model="selectedOwner"
              @change="onOwnerSelected()"
      >
        <option value="" disabled>
          Select owner
        </option>
        <option v-for="role in usersList"
                :key="role.guid"
                :value="role.login"
        >
          {{ role.login }}
        </option>
      </select>

      <button class="button button--blue"
              v-if="selectedCount >= 1"
              @click="JSONSelected"
      >
        to JSON ({{selectedCount}})
      </button>

      <button class="button button--orange" type="button"
              @click="setArchived"
              v-if="isSomeSelected"
      >
        {{selectedCount > 1 ? 'Multi archive' : 'Archive'}} ({{selectedCount}})
      </button>

      <button class="button" type="button"
              @click="setPrepared"
              v-if="isSomeSelected && (isChecking || isValidated)"
      >
        set Preparing
      </button>
      <button class="button button--blue" type="button"
              @click="setValidated"
              v-if="isSomeSelected && isChecking"
      >
        Set validated
      </button>
      <button class="button button--blue" type="button"
              @click="setChecking"
              v-if="isSomeSelected && isValidated"
      >
        Set checking
      </button>
      <button class="button" type="button"
              @click="removeSelected"
              v-if="!(isValidated) && removableCount"
      >
        Remove selected
      </button>
    </div>
    <div class="mini-loader" v-if="isUpdating"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'AppSortPaginate',
  props: {
    count: {
      default: 0,
      type: Number,
    },
    isChecking: {
      default: false,
      type: Boolean,
    },
    isSomeSelected: {
      default: false,
      type: Boolean,
    },
    isValidated: {
      default: false,
      type: Boolean,
    },
    isUpdating: {
      default: false,
      type: Boolean,
    },
    removableCount: {
      default: 0,
      type: Number,
    },
    selectedCount: {
      default: 0,
      type: Number,
    },
    title: {
      default: 'Picsets list',
      type: String,
    },
    usersList: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['set-archived', 'set-page', 'select-all', 'unselect-all', 'remove-selected', 'set-checking', 'set-validated', 'set-prepared', 'owner-selected'],
  data: () => ({
    selectedOwner: ''
  }),
  computed: {
    ...mapState([
      'isUserAdmin',
    ]),
  },
  methods: {
    onOwnerSelected() {
      this.$emit('owner-selected', this.selectedOwner);
      this.selectedOwner = '';
    },
    selectAll() {
      this.$emit('select-all');
    },
    unselectAll() {
      this.$emit('unselect-all');
    },
    removeSelected() {
      this.$emit('remove-selected');
    },
    setChecking() {
      this.$emit('set-checking');
    },
    setValidated() {
      this.$emit('set-validated');
    },
    setPrepared() {
      this.$emit('set-prepared');
    },
    setArchived() {
      this.$emit('set-archived');
    },
    JSONSelected(event) {
      this.$emit('json-selected')
      event.target.innerText = 'Copied!';

      const innerTextSub = () => {
        event.target.innerText = `to JSON (${this.selectedCount})`;
      };

      setTimeout(innerTextSub.bind(this), 1500);
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 25px;

  @media (max-width: 1699px) {
    flex-wrap: wrap;
  }
}



h1 {
  margin: 0;
}

.controls {
  display: flex;
  gap: 5px;

  button {
    border: 1px solid #ff9000;
    padding: 5px;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: rgba(#ff9000, 0.5);
    }
  }
}

.buttons {
  margin-left: auto;
  display: flex;
  gap: 5px;

  @media (max-width: 1699px) {
    margin-left: 0;
  }
}

.button {
  border: none;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  //margin-left: auto;
  background-color: red;
  color: #ffffff;
  font-weight: bold;
}

.button--blue {
  background-color: blue;
}

.button--orange {
  background-color: #ff9000;
  color: #1b1b1b;
}

.buttons select {
  height: 25px;
  width: 120px;
  padding: 0 5px;
  cursor: pointer;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.mini-loader {
  display: inline-block;
  align-self: center;
  margin-top: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid rgba(darkblue, .1);
  border-right-color: darkblue;

  animation-name: spinner;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes spinner {
    0% {
      rotate: 0;
    }

    100% {
      rotate: 360deg;
    }
  }
}
</style>
