import HttpService from "@/services/http-service";
import UserService from "@/services/user-service";

export default class SeriesService extends HttpService {
    constructor() {
        super();
        this.token = UserService.getToken();
        this.headers = {
            Authorization: `bearer ${this.token}`
        };
    }

    // Получить список групп
    getGroupList(limit) {
        let url = `series?_order_by=title`;

        if (limit) {
            url += `&_limit=${limit}`;
        }

        return this.http(url, 'GET', this.headers);
    }

    // Добавить группу
    createSeries(data) {
        const url = `series`;

        return this.http(url, 'PUT', this.headers, JSON.stringify(data));
    }

    // Обновить название группы
    updateSeries(guid, data) {
        const url = `series/${guid}`;

        return this.http(url, 'PATCH', this.headers, JSON.stringify(data));
    }

    // Удалить группу
    deleteSeriesItem(guid) {
        const url = `series/${guid}`;

        return this.http(url, 'DELETE', this.headers);
    }

    getSeriesItem(guid) {
        const url = `series/${guid}`;

        return this.http(url, 'GET', this.headers);
    }

    setSeriesBindings(data) {
        const url = `series/link`;

        return this.http(url, 'POST', this.headers, JSON.stringify(data));
    }

    addSeriesBindings(data) {
        const url = `series/link`;

        return this.http(url, 'PUT', this.headers, JSON.stringify(data));
    }
}