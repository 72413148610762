<template>
  <div class="finder-result"
       :class="{'loading': isLoading}"
       v-if="items?.length"
  >
    <div class="finder-result__header">
      <div class="finder-result__title">
        Found: {{ filteredItems.length }} items {{ (picItems.length - filteredItems.length) ? `(${picItems.length - filteredItems.length} items were hidden)` : '' }}
      </div>
    </div>
    <div class="finder-result__wrapper"
         :class="{'finder-result__wrapper--vertical': orientation === 'vertical' }"
    >
      <TrainedThumbItem v-for="(item, index) in filteredItems"
                        :key="item.guid"
                        :item="item"
                        :orientation="orientation"
                        :current-filter="currentFilter"
                        :filter-categories-list="filterCategoriesList"
                        :filter-by-group="filterByGroup"
                        @update-thumb-data="onUpdateThumbData"
                        @set-thumb-group-data="onSetThumbGroupData"
                        @remove-group-from-thumb="onRemoveGroupFromThumb"
                        @preview="onPreviewClick"
                        @expand="$emit('expand', index)"
      ></TrainedThumbItem>
    </div>
  </div>

  <div class="finder-result"
       v-else>
    Loading...
  </div>
</template>

<script>
import 'lazysizes';
import ConfigStorageService from "@/services/config-storage-service";
import FinderService from "@/services/finder-service";
import TrainedThumbItem from "@/modules/checked-picset/components/TrainedThumbItem";

export default {
  name: 'AppCheckedPicsetItems',
  components: {
    TrainedThumbItem,
  },
  props: {
    currentFilter: {
      default: () => [],
      type: Array,
    },
    filter: {
      default: new Set(),
      type: Set,
    },
    filterByGroup: {
      default: '',
      type: String,
    },
    filterCategoriesList: {
      default: new Map(),
      type: Map,
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    items: {
      default: () => [],
      type: Array,
    },
    orientation: {
      default: 'horizontal',
      type: String,
    },
    picItems: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['preview', 'expand'],
  data: () => ({
    downloadEndpoint: null,
    finderService: null,

    isShowOptions: {},
  }),
  computed: {
    filteredItems() {
      if (!this.filter.size) {
        return this.items
      } else {
        return this.items.filter(item => {
          const itemAllCategories = new Set();

          item.trained?.some(person => {
            return person.groups?.forEach(group => {
              itemAllCategories.add(...group.category);
            });
          });

          return Array.from(this.filter).every(filter => {
            return itemAllCategories.has(filter);
          });
        });
      }
    }
  },
  mounted() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
    this.finderService = new FinderService();
  },
  methods: {
    onPreviewClick(id) {
      this.$emit('preview', id);
    },
    onSetThumbGroupData(value) {
      const id = value.id;
      const category = value.category;
      const group = value.group;

      const thumb = this.items.find(el => el.id === id);
      const picture = this.picItems.find(el => el.id === id);
      const guid = picture.guid;
      const trained = thumb.trained.map(el => {
        return {group: el.group, category: el.category}
      }).filter(el => el.group);

      trained.push({
        group: group,
        category: [category]
      })

      thumb.trained = trained;

      const data = {
        guid,
        trained
      }

      thumb.loading = true;
      this.finderService.updatePicTrained(guid, data)
          .finally(() => thumb.loading = false)
          .then(() => {
          })
    },
    onUpdateThumbData(id) {
      const thumb = this.items.find(el => el.id === id);
      const picture = this.picItems.find(el => el.id === id);
      const guid = picture.guid;
      const trained = thumb.trained.map(el => {
        return {group: el.group, category: el.category}
      }).filter(el => el.group);

      const data = {
        guid,
        trained
      }

      thumb.loading = true;
      this.finderService.updatePicTrained(guid, data)
          .finally(() => thumb.loading = false)
          .then(() => {
          })
    },
    onRemoveGroupFromThumb(val) {
      const id = val.id;
      const removeGroupTitle = val.group;
      const thumb = this.items.find(el => el.id === id);
      const picture = this.picItems.find(el => el.id === id);
      const guid = picture.guid;

      thumb.trained = thumb.trained
          .filter(el => el.group !== removeGroupTitle);

      const trained = thumb.trained.map(el => {
        return {group: el.group, category: el.category}
      }).filter(el => el.group);

      const data = {
        guid,
        trained
      }

      thumb.loading = true;
      this.finderService.updatePicTrained(guid, data)
          .finally(() => thumb.loading = false)
          .then(() => {
          })
    },
  }
}
</script>

<style scoped lang="scss">
.finder-result {
  padding-bottom: 70px;
}

.finder-result__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.finder-result__title {
  font-size: 1.5rem;
  line-height: 34px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.finder-result__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1800px) {
    grid-template-columns: repeat(5, 1fr);
  }

  & > * {
    min-width: 0;
  }
}

.finder-result__wrapper--vertical {
  grid-template-columns: repeat(7, 1fr);
}
</style>
