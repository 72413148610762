<template>
  <form class="finder-form" @submit.prevent="submit">
    <div class="finder-form__textfield">
      <input type="text"
             class="finder-form__input"
             placeholder="New picset"
             v-model="textValue"
             :disabled="isLoading"
      >
      <div class="finder-form__select-field">
        <span class="finder-form__toggle">
          <b @click="setFileUpload(false)">Url</b>
          <label>
            <input type="checkbox" v-model="isFileUpload">
            <span></span>
          </label>
          <b @click="setFileUpload(true)">File</b>
        </span>
        <input type="text"
               class="finder-form__input finder-form__input--url"
               placeholder="Pic url"
               v-model="picUrlValue"
               :disabled="isLoading"
               v-if="!isFileUpload"
        >
        <label class="dropbox"
               v-else
        >
          <input type="file" accept=".zip"
                 class="dropbox__file"
                 @change="onFileAdd($event.target.files)"
          >
          <span v-if="progress">
            {{ progress }}%
          </span>
          <span v-else-if="!file">
            Drop file <br>
            <small>Max: 150mb</small>
          </span>
          <span v-else>
            {{ file[0].name }}
          </span>
          <span v-if="isFileSizeError" style="color: red;">
            Too big file
          </span>
        </label>
      </div>
      <button class="finder-form__button"
              :class="{'disabled': isLoading}"
              :disabled="isLoading || isFileSizeError || !textValue || (!textValue || !file && isFileUpload)"
      >
        Make picset
      </button>
    </div>

    <div class="finder-form__selects">
      <div class="multiselect-custom-wrapper">
        <Multiselect
            v-model="group"
            :options="groupsList"
            placeholder="Select group"
            searchable
        />
      </div>
      <div class="multiselect-custom-wrapper">
        <Multiselect
            v-model="category"
            :options="categoriesList"
            :disabled="!group"
            placeholder="Select category"
            searchable
        />
      </div>
    </div>

    <div class="finder-form__select">
      <span>
        Lang
      </span>
      <div class="radio">
        <label class="radio__item" v-for="val in langValues" :key="val">
          <input type="radio" v-model="lang" :value="val">
          <span>{{ val }}</span>
        </label>
      </div>
    </div>

    <div class="finder-form__select" v-if="isUserAdmin">
      <span>
        Owner {{ ownerValue }}
      </span>
      <select v-model="ownerValue">
        <option value="">Select owner</option>
        <option v-for="item in usersList"
                :key="item.guid"
                :value="item.login">{{ item.login }}
        </option>
      </select>
    </div>

    <!--    <div class="finder-form__select">-->
    <!--      <span>-->
    <!--        Role-->

    <!--        <span class="finder-form__toggle">-->
    <!--          <label>-->
    <!--            <input type="checkbox" v-model="isHandCountEnter">-->
    <!--            <span></span>-->
    <!--          </label>-->
    <!--        </span>-->
    <!--      </span>-->
    <!--      <select v-model="countValue" v-if="!isHandCountEnter">-->
    <!--        <option v-for="(item, index) in countOptions"-->
    <!--                :key="index"-->
    <!--                :value="item">{{ item }}-->
    <!--        </option>-->
    <!--      </select>-->
    <!--      <input v-else type="number" v-model="handCountValue" placeholder="Type value">-->
    <!--    </div>-->

    <div class="finder-form__select">
      <span>
        Count

        <!--        <span class="finder-form__toggle">-->
        <!--          <label>-->
        <!--            <input type="checkbox" v-model="isHandCountEnter">-->
        <!--            <span></span>-->
        <!--          </label>-->
        <!--        </span>-->
      </span>
      <!--      <select v-model="countValue" v-if="!isHandCountEnter">-->
      <!--        <option v-for="(item, index) in countOptions"-->
      <!--                :key="index"-->
      <!--                :value="item">{{ item }}-->
      <!--        </option>-->
      <!--      </select>-->
      <!--      <input v-else type="number" v-model="handCountValue" placeholder="Type value">-->
      <input type="number" v-model="handCountValue" placeholder="Type value">
    </div>

<!--    <OnClickOutside @trigger="hideOriginTags">-->
<!--      <div class="finder-form__select finder-form__select&#45;&#45;size">-->
<!--        <span>Main tag</span>-->
<!--        <input type="text" v-model="originTagValue" placeholder="Type main tag" @focus="showOriginTags">-->

<!--        <div class="tag-selector tag-selector&#45;&#45;main" v-if="originTags.length && isShowOriginTags">-->
<!--          <div class="tag-selector__item"-->
<!--               :class="{'tag-selector__item&#45;&#45;active': getSelectedOriginStatus(tag)}"-->
<!--               v-for="tag in originTags" :key="tag.guid"-->
<!--               @click="addOriginTag(tag)"-->
<!--          >-->
<!--            {{ tag.title }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </OnClickOutside>-->

<!--    <OnClickOutside @trigger="hideTags">-->
<!--      <div class="finder-form__select finder-form__select&#45;&#45;size">-->
<!--        <span>Tags</span>-->
<!--        <input type="text" v-model="tagsValue" placeholder="Type tags" @focus="showTags">-->

<!--        <div class="tag-selector" v-if="tags.length && isShowTags">-->
<!--          <div class="tag-selector__item"-->
<!--               :class="{'tag-selector__item&#45;&#45;active': getSelectedStatus(tag)}"-->
<!--               v-for="tag in tags" :key="tag.guid"-->
<!--               @click="addTag(tag)"-->
<!--          >-->
<!--            {{ tag.title }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </OnClickOutside>-->

    <div class="finder-form__select" v-if="isShowMore">
      <span>Orientation</span>
      <select v-model="orientationValue">
        <option v-for="(item, index) in orientationOptions"
                :key="index"
                :value="item">{{ item }}
        </option>
      </select>
    </div>

    <div class="finder-form__select" v-if="isShowMore">
      <span>Size</span>
      <select v-model="imageSizeValue">
        <option v-for="(item, index) in imageSizeOptions"
                :key="index"
                :value="item">{{ item ? item : 'auto' }}
        </option>
      </select>
    </div>

    <div class="finder-form__select" v-if="isShowMore">
      <span>Image type</span>
      <select v-model="imageTypeValue">
        <option v-for="(item, index) in imageTypeOptions"
                :key="index"
                :value="item">{{ item }}
        </option>
      </select>
    </div>

    <div class="finder-form__select" v-if="isShowMore">
      <span>File type</span>
      <select v-model="fileTypeValue">
        <option v-for="(item, index) in fileTypeOptions"
                :key="index"
                :value="item">{{ item }}
        </option>
      </select>
    </div>

    <button class="finder-form__button" type="button" @click="toggleShowMore">
      {{ isShowMore ? 'Show less' : 'Show more' }}
    </button>
  </form>
</template>

<script>
import {debounce} from 'throttle-debounce';
// import {OnClickOutside} from '@vueuse/components';
import Multiselect from '@vueform/multiselect';
import {mapState} from "vuex";

export default {
  name: 'AppSearchForm',
  components: {
    // OnClickOutside,
    Multiselect
  },
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => []
    },
    originTags: {
      type: Array,
      default: () => []
    },
    categoriesList: {
      type: Array,
      default: () => []
    },
    categoryName: {
      type: String,
      default: '',
    },
    groupsList: {
      type: Array,
      default: () => []
    },
    groupName: {
      type: String,
      default: '',
    },
    usersList: {
      type: Array,
      default: () => []
    },
  },
  emits: ['on-search', 'update:categoryName', 'update:groupName'],
  data: () => ({
    file: null,
    isFileUpload: false,
    isShowMore: false,

    isShowOriginTags: false,
    isShowTags: false,

    // group: null,
    // category: null,

    selectedOriginTagsList: [],
    selectedTagsList: [],
    textValue: '',
    picUrlValue: '',
    tagsValue: '',
    originTagValue: '',

    ownerValue: '',
    // countValue: 100,
    handCountValue: 100,
    // isHandCountEnter: false,
    // countOptions: [
    //   100, 500, 1500, 5000
    // ],

    orientationValue: 'horizontal',
    orientationOptions: [
      'horizontal', 'vertical'
    ],

    imageSizeValue: '',
    imageSizeOptions: [
      '',
      'small',
      'medium',
      'large'
    ],

    imageTypeValue: 'photo',
    imageTypeOptions: [
      'photo',
      'clipart',
      'lineart',
      'face',
      'demotivator'
    ],

    fileTypeValue: 'jpg',
    fileTypeOptions: [
      'jpg',
      'png',
      'gifan'
    ],

    langValues: [
      'ru',
      'en',
    ],
    lang: 'ru',

    maxSize: 157286400,
    isFileSizeError: false,
  }),
  computed: {
    ...mapState([
      'isUserAdmin',
      'userName'
    ]),
    category: {
      get() {
        return this.categoryName;
      },
      set(value) {
        this.$emit('update:categoryName', value);
      }
    },
    group: {
      get() {
        return this.groupName;
      },
      set(value) {
        if (this.group !== value) this.category = '';
        this.$emit('update:groupName', value);
      }
    }
  },
  watch: {
    tagsValue(...args) {
      this.debouncedTagsWatch(...args);
    },
    originTagValue(...args) {
      this.debouncedOriginTagsWatch(...args);
    },
    userName(val) {
      /**
       * выставляем владельца по умолчанию только НЕ для админа
       */
      if (!this.isUserAdmin) {
        this.ownerValue = val;
      }
    }
  },
  created() {
    this.debouncedOriginTagsWatch = debounce(500, (val) => {
      this.selectedOriginTagsList = [];
      const arr = val.split(',').map(el => el.trim());

      arr.forEach(arrItem => {
        const find = this.originTags.find(el => el.title === arrItem);

        if (find) {
          const secondFind = this.selectedOriginTagsList.find(el => el.guid === find.guid);

          if (!secondFind) {
            this.selectedOriginTagsList.push(find);
          }
        }
      })
    });

    this.debouncedTagsWatch = debounce(500, (val) => {
      this.selectedTagsList = [];
      const arr = val.split(',').map(el => el.trim());

      arr.forEach(arrItem => {
        const find = this.tags.find(el => el.title === arrItem);

        if (find) {
          const secondFind = this.selectedTagsList.find(el => el.guid === find.guid);

          if (!secondFind) {
            this.selectedTagsList.push(find);
          }
        }
      })
    });
  },
  beforeUnmount() {
    this.debouncedTagsWatch.cancel();
    this.debouncedOriginTagsWatch.cancel();
  },
  methods: {
    onFileAdd(file) {
      this.file = file
      this.isFileSizeError = false;

      Array.from(file).forEach((item) => {
        if (item.size > this.maxSize) {
          this.isFileSizeError = true;
        }
      });
    },
    setFileUpload(status) {
      this.isFileUpload = status;
    },
    toggleShowMore() {
      this.isShowMore = !this.isShowMore;
    },
    showOriginTags() {
      this.isShowOriginTags = true;
    },
    showTags() {
      this.isShowTags = true;
    },
    hideOriginTags() {
      this.isShowOriginTags = false;
    },
    hideTags() {
      this.isShowTags = false;
    },
    getSelectedOriginStatus(tag) {
      return this.selectedOriginTagsList.find(el => el.title === tag.title);
    },
    getSelectedStatus(tag) {
      return this.selectedTagsList.find(el => el.title === tag.title);
    },
    addOriginTag(tag) {
      this.originTagValue = tag.title;
      this.hideOriginTags();
    },
    addTag(tag) {
      this.tagsValue = tag.title;
      this.hideTags();
    },
    collectTags(tags) {
      return tags.split(',')
          .map((el) => {
            return {
              'title': el.trim()
            }
          })
          .filter(el => el.title !== '');
    },
    submit() {
      // const groups = this.groups && this.groups.map(el => {
      //   return {title: el}
      // });
      const group = this.group && [{ title: this.group }];
      const category = this.category && [{ title: this.category}];

      const data = {
        query: this.textValue,
        quantity: this.handCountValue,
        orientation: this.orientationValue,
        imageType: this.imageTypeValue,
        imageSize: this.imageSizeValue,
        fileType: this.fileTypeValue,
        is_url: !!this.picUrlValue,
        url: this.picUrlValue,
        owner: this.ownerValue,

        lang: this.lang
      }

      if (this.originTagValue) {
        data['origin'] = {
          'title': this.originTagValue
        }
      }

      if (this.tagsValue) {
        data['tags'] = [{
          'title': this.tagsValue
        }]
      }

      if (this.textValue) {
        if (this.isFileUpload && this.file) {
          // this.$emit('on-search', [data, groups, this.file[0]]);
          this.$emit('on-search', [data, group, category, this.file[0]]);
        } else {
          // this.$emit('on-search', [data, groups]);
          this.$emit('on-search', [data, group, category]);
        }

        this.textValue = '';
        this.picUrlValue = '';
        this.tagsValue = '';
        this.originTagValue = '';
        this.groups = null;
        this.file = null;
        this.selectedTagsList = [];
      }
    }
  }
}
</script>

<style lang="scss">
@import "@vueform/multiselect/themes/default";

.multiselect-tag {
  background-color: #ff9000;
}

.multiselect {
  /*max-width: 50vw;*/
}

.multiselect-custom-wrapper {
  display: inline-flex;
  justify-content: flex-start;
  width: min(400px, 100%);

  & > * {
    max-width: 496px;
    margin: 0;
  }
}
</style>

<style scoped lang="scss">
.finder-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.finder-form > button {
  background-color: #ff9000;
  border: none;
  height: 35px;
  color: #ffffff;
  font-weight: bold;

  //align-self: flex-end;
  border-radius: 5px;

  cursor: pointer;
}

.finder-form__textfield {
  display: flex;
}

.finder-form__input {
  width: 250px;
  height: 35px;
  font-size: 16px;
  padding: 0 10px;
  border: 2px solid #ff9000;
  border-radius: 5px 0 0 5px;
  outline: none;
  border-right: none;

  &:focus {
    border-color: lighten(#ff9000, 10%);
  }
}

.finder-form__input--url {
  width: 130px;
  border-radius: 0;
}

.finder-form__button {
  border-radius: 0 5px 5px 0;
  border: none;
  background-color: #ff9000;
  font-weight: bold;
  padding: 0 10px;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:not([disabled]):hover {
    background-color: lighten(#ff9000, 10%);
  }
}

.finder-form__select {
  height: 50px;
  display: flex;
  flex-direction: column;
  position: relative;

  & > span {
    margin-bottom: 3px;
    margin-top: -7px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  select {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  input[type="text"],
  input[type="number"] {
    height: 35px;
    width: 100px;
    padding: 0 5px;
    cursor: text;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

.finder-form__toggle {
  input {
    display: none;
  }

  span {
    display: flex;
    width: 30px;
    height: 12px;
    border-radius: 5px;
    border: 2px solid #ff9000;
    cursor: pointer;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 2px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #ff9000;
    }
  }

  input:checked + span::before {
    left: auto;
    right: 2px;
  }
}

.tag-selector {
  position: absolute;
  top: calc(100% + 5px);
  background-color: #fff;
  padding: 10px;
  min-width: 320px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  z-index: 11;

  max-height: 208px;
  overflow: auto;
}

.tag-selector__item {
  background-color: rgba(27, 27, 27, 0.5);
  border-radius: 5px;
  padding: 5px 8px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
}

.tag-selector--main .tag-selector__item {
  background-color: gold;
  color: var(--base-text);
}

.tag-selector__item--active {
  background-color: green !important;
  color: #ffffff !important;
}

.finder-form__select--size input {
  width: 180px;
}


.radio {
  display: flex;
  gap: 10px;
}

.radio__item {
  height: 35px;
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  span {
    padding-left: 30px;
    position: relative;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: 50%;
      height: 25px;
      width: 25px;
      border: 2px solid #ff9000;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      width: 13px;
      height: 13px;
      background-color: #ff9000;
      left: 6px;
      opacity: 0;
    }
  }

  input:checked + span::after {
    opacity: 1;
  }
}

.finder-form__select-field {
  position: relative;

  .finder-form__toggle {
    position: absolute;
    transform: translateY(-120%);
    display: flex;
    align-items: center;
    gap: 3px;
    right: 0;

    font-size: 12px;
    line-height: 12px;
  }
}

.finder-form__selects {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 10px;
  width: 600px;
  height: 50px;

  @media (max-width: 1440px) {
    order: 2;
  }
}

.multiselect-custom-wrapper {
  width: 295px;
}

.dropbox {
  width: 130px;
  border: 2px dashed #ffffff; /* the dash box */
  background: #ff9000;
  color: #ffffff;
  padding: 5px;
  height: 35px;
  position: relative;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > span {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.dropbox__file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.multiselect {
  min-height: 35px !important;
  height: 35px !important;
}

::v-deep .multiselect .multiselect-wrapper {
  min-height: 35px !important;
}

::v-deep .multiselect-search {
  position: relative;
}
</style>
