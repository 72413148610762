const getPageFromQuery = {
    beforeMount() {
      this.__getPageFromQuery();
    },
    methods: {
        __getPageFromQuery() {
            const query = new URLSearchParams(window.location.search);

            if (query.has('p')) {
                this.currentPage = Number(query.get('p')) || 1;
            }
        }
    },
    watch: {
        currentPage: {
            handler(newValue) {
                const query = new URLSearchParams(window.location.search);

                if (newValue > 1) {
                    query.set('p', String(newValue));
                } else {
                    query.set('p', String(1));
                }

                window.history.replaceState(null,null, window.location.pathname + '?' + query.toString());
            }
        }
    }
}

export default getPageFromQuery;
