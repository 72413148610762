const changedPicturesChecker = {
    methods: {
        _setChangesToPictures(bufferedPicture) {
            const foundedPicture = this.pictures.find(_picture => _picture.guid === bufferedPicture.guid);
            const foundedThumb = this.thumbs.find(thumb => {
                const id = this.pictures.find(_picture => _picture.guid === bufferedPicture.guid)?.id;
                return thumb.id === id;
            });

            if (foundedPicture) foundedPicture.trained = bufferedPicture.trained;
            if (foundedThumb) foundedThumb.trained = bufferedPicture.trained;
        },
        mergeBufferedChangesToIncomeData() {
            this.$store.getters.getNewPicturesList.forEach(this._setChangesToPictures.bind(this));
        }
    }
}

export default changedPicturesChecker;
