<template>
  <div class="picset-header">
    <div class="picset-header__line">
      <h1>{{ picsetInfo?.query }}</h1>

      <template v-if="picsetInfo">
        <app-picset-group-label
            v-for="group in picsetInfo.trained"
            :key="group.group"
            :title="group.group"
            :highlighted="filterByGroup === group.group"
            @click="filterByGroup = filterByGroup === group.group ? '' : group.group"
        />
      </template>

      <span v-if="Object.keys(picsetInfo?.neuralVersion || {}).length"
            class="picset-header__neural-info-toggle"
            :class="{
              'picset-header__neural-info-toggle--open': showNeuralNetworkInfo,
            }"
            @click="toggleNeuralNetworkInfo"
            :title="(showNeuralNetworkInfo ? 'Hide' : 'Show') + ' neural network info'"
      >
        i
      </span>
    </div>

    <app-checked-picset-neural-network-info :show="showNeuralNetworkInfo" :info="picsetInfo?.neuralVersion || {}" />
    <app-checked-picset-groups-filter v-model:filter="filter" :labels="labels" />

    <div class="picset-header__selects">
      <div style="width:400px; max-width: 505px" v-if="picsetInfo && this.groupsListTitles.length">
        <Multiselect
            v-model="picsetGroup"
            :options="groupsListTitles"
            placeholder="Select origin group"
            searchable
        />
      </div>

      <div style="width:400px; max-width: 505px" v-if="picsetInfo && this.groupsListTitles.length">
        <Multiselect
            v-model="picsetCategory"
            :options="categoriesList"
            :disabled="!picsetGroup"
            placeholder="Select origin category"
            searchable
        />
      </div>

      <div class="picset-header__button" @click="savePicset">
        Set
      </div>

      <app-checked-picset-changes-controls />
      <app-checked-picset-changes-controls :is-sticky="true" />
    </div>
  </div>

  <AppCheckedPicsetItems
      :filter-categories-list="filterCategoriesList"
      :current-filter="currentFilter"
      :items="thumbs"
      :pic-items="pictures"
      :is-loading="isLoading"
      :orientation="orientation"
      :filter="filter"
      :filter-by-group="filterByGroup"
      @preview="showPreview"
      @expand="onExpand"
  ></AppCheckedPicsetItems>

  <div class="archives-list__controls">
    <div class="finder-form__select">
      <span>Count</span>
      <select v-model="perPage" @change="setPerPage">
        <option v-for="(item, index) in perPageOptions"
                :key="index"
                :value="item">{{ item }}
        </option>
      </select>
    </div>

    <vue-awesome-paginate
        :total-items="pictures && pictures.length"
        :items-per-page="perPage"
        :max-pages-shown="5"
        v-model="currentPage"
        :on-click="setPage"
    ></vue-awesome-paginate>
  </div>

  <AppPicsetPreview v-if="previewPic"
                    :pic="previewPic"
                    :index="currentIndex"
                    :len="thumbs.length"
                    @prev="showPrev"
                    @next="showNext"
                    @close="closePreview"
  ></AppPicsetPreview>

  <AppCheckedPicsetSlider v-if="showSlider"
                          v-model:currentPictureIndex="sliderCurrentIndex"
                          :min-index="currentPage === 1 ? 0 : perPage * (currentPage - 1)"
                          :max-index="thumbs.length + (currentPage === 1 ? 0 : perPage * (currentPage - 1)) - 1"
                          :picture="pictures[sliderCurrentIndex]"
                          @close="closeSlider"
  />
</template>

<script>
import {mapMutations} from "vuex";
import ConfigStorageService from "@/services/config-storage-service";
import FinderService from "@/services/finder-service";
import SeriesService from "@/services/series-service";

import AppCheckedPicsetItems from "@/modules/checked-picset/checked-picset-items/CheckedPicsetItems";
// import AppTrainedCategoriesFilter from "@/modules/checked-picset/trained-categories-filter/TrainedCategoriesFilter";
import AppCheckedPicsetGroupsFilter from "@/modules/checked-picset/components/CheckedPicsetGroupsFilter";
import AppCheckedPicsetChangesControls from "@/modules/checked-picset/components/CheckedPicsetChangesControls";
import AppCheckedPicsetNeuralNetworkInfo from "@/modules/checked-picset/components/CheckedPicsetNeuralNetworkInfo";
import AppPicsetGroupLabel from "@/components/PicsetGroupLabel";
import AppCheckedPicsetSlider from "@/modules/checked-picset/components/AppCheckedPicsetSlider.vue";

import neuralNetworkInfoControlMixin from "@/mixins/neuralNetworkInfoControlMixin";
import changedPicturesChecker from "@/mixins/changedPicturesChecker";
import getPageFromQuery from "@/mixins/getPageFromQuery";
import useSlider from '@/mixins/useSlider';

import AppPicsetPreview from "@/modules/picset/picset-preview/PicsetPreview";
import Multiselect from "@vueform/multiselect";

export default {
  name: 'AppCheckedPicset',
  components: {
    // AppTrainedCategoriesFilter,
    AppCheckedPicsetChangesControls,
    AppCheckedPicsetGroupsFilter,
    AppCheckedPicsetItems,
    AppCheckedPicsetNeuralNetworkInfo,
    AppPicsetGroupLabel,
    AppPicsetPreview,
    AppCheckedPicsetSlider,
    Multiselect,
  },
  mixins: [
      neuralNetworkInfoControlMixin, // Контроль показа инфы о нейронках
      changedPicturesChecker, // При запросе новых данных с сервера подхватывает локальные изменения и меняет входящие
      getPageFromQuery, // Подхватывает номер страницы из запроса
      useSlider, // Набор логики для работы слайдера
  ],
  data: () => ({
    categoryCounter: {},
    currentFilter: ['all'],
    filterCategoriesList: new Map(),

    downloadEndpoint: null,
    finderService: null,

    isLoading: false,

    orientation: 'horizontal',

    picsetGuid: '',
    picsetInfo: null,

    thumbs: [],
    pictures: [],

    previewPic: '',
    prevPic: '',
    nextPic: '',
    picIndex: 0,
    currentIndex: 0,

    currentPage: 1,
    perPage: 100,
    perPageOptions: [50, 100, 200],
    totalPages: 500,

    groupsList: [],
    groupsListTitles: [],

    filter: new Set(),
    filterByGroup: null,
  }),
  computed: {
    categoriesList() {
      const categoriesList = this.groupsList
          .find(item => item.title === this.picsetGroup)
          ?.categories
          ?.map(item => item.title);
      return categoriesList || [];
    },
    labels() {
      return this.picsetInfo?.trained
          ?.filter(group => !this.filterByGroup || group.group === this.filterByGroup)
          ?.map(group => group.category)
          ?.flat(1)
          || [];
    },
    picsetGroup: {
      get() {
        return this.picsetInfo && this.picsetInfo.series.length && this.picsetInfo.series[0].title;
      },
      set(val) {
        if (this.picsetGroup !== val) this.picsetInfo.categories = [];

        this.picsetInfo['series'] = [{
          title: val
        }];
      }
    },
    picsetCategory: {
      get() {
        return this.picsetInfo && this.picsetInfo.categories.length && this.picsetInfo.categories[0].title;
      },
      set(val) {
        this.picsetInfo['categories'] = [{
          title: val
        }];
      }
    },
  },
  created() {
    this.downloadEndpoint = ConfigStorageService.get('downloadEndpoint');
    this.finderService = new FinderService();
    this.seriesService = new SeriesService();
    this.picsetGuid = this.$route.params.guid;
  },
  mounted() {
    this.getGroupsInfo().then(() => {
      setTimeout(() => {
        this.getPicsetInfo();
        this.getPicsetPics().then(() => {
          setTimeout(this.getPicsetThumbs.bind(this), 500);
        });
      }, 1000);
    });
  },
  unmounted() {
    this.picsetGuid = '';
    this.thumbs = [];
    this.pictures = [];
  },
  methods: {
    ...mapMutations(['setGroupsList', 'setAllPicturesList', 'setMaxLabelsForPerson']),
    savePicset() {
      this.isLoading = true;

      const dataTags = {
        guid: this.picsetGuid,
        tags: this.picsetInfo.tags
      };
      const dataSeries = {
        guid: this.picsetGuid,
        series: this.picsetGroup ? [{ title: this.picsetGroup }] : [],
      }

      const dataCategories = {
        guid: this.picsetGuid,
        categories: this.picsetCategory ? [{ title: this.picsetCategory }] : [],
      }

      const promiseTags = this.finderService.tagsUpdate(this.picsetGuid, dataTags);
      const promiseSeries = this.finderService.setSeries(this.picsetGuid, dataSeries);
      const promiseCategories = this.finderService.setCategories(this.picsetGuid, dataCategories);
      const promises = [];
      promises.push(promiseTags);
      promises.push(promiseSeries);
      promises.push(promiseCategories);

      if (this.picsetInfo?.origin?.title) {
        const dataOrigin = {
          guid: this.picsetGuid,
          origin: {
            title: this.picsetInfo?.origin?.title || ''
          }
        };
        const promiseOrigin = this.finderService.originTagUpdate(this.picsetGuid, dataOrigin);
        promises.push(promiseOrigin)
      } else {
        const promiseRemoveOrigin = this.finderService.removeOriginTag(this.picsetGuid);
        promises.push(promiseRemoveOrigin)
      }

      Promise.allSettled(promises)
          .finally(() => this.isLoading = false)
          // .then(() => {
          //   this.$router.push('/');
          // })
          .catch((err) => {
            console.log(err);
          });
    },
    getPicsetInfo() {
      this.finderService.getPicset(this.picsetGuid)
          .then((res) => {
            this.picsetInfo = res.result;
            this.setMaxLabelsForPerson(this.picsetInfo.trained.length || 0);

            this.setGroupsList(this.groupsList
                .filter(item => {
                  return this.picsetInfo.trained.find(_item => _item.group === item.title)
                })
                .map(item => ({
                  group: item.title,
                  category: item.categories.map(category => category.title),
                })
            ));
          })
    },
    closePreview() {
      this.previewPic = "";
    },
    showPrev() {
      const arrPictures = this.pictures;
      const arrThumbs = this.thumbs;

      this.currentIndex--;
      const index = arrPictures.findIndex(item => item.id === arrThumbs[this.currentIndex].id);
      this.previewPic = this.getUrl(arrPictures[index]);
    },
    showNext() {
      const arrPictures = this.pictures;
      const arrThumbs = this.thumbs;

      this.currentIndex++;
      const index = arrPictures.findIndex(item => item.id === arrThumbs[this.currentIndex].id);
      this.previewPic = this.getUrl(arrPictures[index]);
    },
    showPreview(id) {
      const arrPictures = this.pictures;
      const arrThumbs = this.thumbs;

      this.currentIndex = arrThumbs.findIndex(item => item.id === id);
      this.picIndex = arrPictures.findIndex(item => item.id === id);
      this.previewPic = this.getUrl(arrPictures[this.picIndex]);
    },
    updateFilter() {
      if (this.groupsList.length && this.filterCategoriesList.size) {
        this.groupsList.forEach((item) => {
          const categories = item.categories.map(el => el.title);

          if (categories.length) {
            if (this.filterCategoriesList.has(item.title)) {
              this.filterCategoriesList.set(item.title, categories);
            }
          }
        })
      }
    },
    async getGroupsInfo() {
      this.seriesService.getGroupList()
          .then(res => {
            this.groupsList = res.series;
            this.groupsListTitles = res.series.map(el => el.title);

            this.updateFilter();
          })
    },
    onSetAllOn() {
      this.currentFilter = ['all'];
    },
    onSetAllOff() {
      this.currentFilter = [];
    },
    onToggleFilterItemActive(val) {
      const findIndex = this.currentFilter.findIndex(el => el === val);

      if (findIndex < 0) {
        const hasAll = this.currentFilter.find(el => el === 'all');
        if (hasAll) {
          this.currentFilter.splice(0, 1); // удаfiltereляем all
        }

        this.currentFilter.push(val);
      } else {
        this.currentFilter.splice(findIndex, 1);
      }
    },
    setPerPage() {
      this.getPicsetThumbs();
    },
    setPage(val) {
      this.currentPage = val;
      this.getPicsetThumbs();
    },
    getUrl(el) {
      return el.filepath ? this.downloadEndpoint + el.filepath : el.url;
    },
    getPicsetThumbs() {
      this.filter.size
        ? this.finderService.getPicsetThumbsByCategories(this.picsetGuid, this.currentPage, this.perPage, true, false, Array.from(this.filter))
              .then(res => {
                if (res.thumbs) {
                  this.thumbs = res.thumbs.filter(thumb => {
                    return this.pictures.find(picture => picture.id === thumb.id);
                  });

                  this.$nextTick(() => {
                    this.mergeBufferedChangesToIncomeData();
                  });

                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  });
                }
              })
        : this.finderService.getPicsetThumbs(this.picsetGuid, this.currentPage, this.perPage, true)
              .then(res => {
                if (res.thumbs) {
                  this.thumbs = res.thumbs.filter(thumb => {
                    return this.pictures.find(picture => picture.id === thumb.id);
                  });

                  this.$nextTick(() => {
                    this.mergeBufferedChangesToIncomeData();
                  });

                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  });
                }
              })
    },
    async getPicsetPics() {
      this.filter.size
       ? this.finderService.getPicsetPicsByCategories(this.picsetGuid, true, false, Array.from(this.filter))
              .then(res => {
                this.pictures = res.pictures;

                this.setAllPicturesList(res.pictures);
                this.updateFilter();
              })
       : this.finderService.getPicsetPics(this.picsetGuid, true)
          .then(res => {
            this.pictures = res.pictures;

            this.setAllPicturesList(res.pictures);
            this.updateFilter();
          })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.editPicturesInfo.newPicturesList.size) {
      if (confirm("Are you sure? You have unsaved changes. These changes will be lost.")) next();
    } else {
      next();
    }
  },
  watch: {
    filter: {
      handler() {
        this.getPicsetThumbs();
        this.getPicsetPics();
      },
      deep: true,
    }
  }
}
</script>

<style scoped lang="scss">
.archives-list__controls {
  display: flex;
  align-items: center;
  justify-content: center;
}

.finder-form__select {
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 20px;

  span {
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    margin-right: 10px;
  }

  select {
    height: 35px;
    min-width: 100px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 2px solid #ff9000;
    border-radius: 5px;
  }
}

.picset-header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  border-bottom: 2px solid #ff9000;
}

.picset-header h1 {
  margin: 0;
}

.picset-header__line {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.picset-header__group {
  padding: 5px 7px;
  border-radius: 5px;
  background-color: #888;
  color: #fff;

  &:first-of-type {
    margin-left: 8px;
  }
}

.picset-header__selects {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.picset-header__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  height: 30px;
  background-color: #ff9000;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity .1s;

  &:hover {
    opacity: .85;
  }
}

.multiselect {
  min-height: 30px !important;
  height: 30px !important;
}

::v-deep .multiselect .multiselect-wrapper {
  min-height: 30px !important;
}

::v-deep .multiselect-search {
  position: relative;
}

.picset-header__neural-info-toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  background-color: #ccc;
  color: #222;
  border-radius: 50%;
  font-weight: bold;
  font-style: italic;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: #e1e1e1;
  }

  &--open {
    background-color: #000;
    color: #fff;

    &:hover {
      background-color: #222;
    }
  }
}
</style>
