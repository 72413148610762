import FinderService from "@/services/finder-service";
import _debugger from "@/store/functions/debugger";

const editPicturesInfo = ({
    state: () => ({
        newPicturesList: new Map(),
        allPicturesList: [],
    }),
    getters: {
        getNewPicturesList: state => state.newPicturesList,
        getAllPicturesList: state => state.allPicturesList,
    },
    mutations: {
        setAllPicturesList: (state, data) => state.allPicturesList = data,
        removeCategory: (state, data) => {
            const { picture, category, personIndex } = data;

            const originalGuid = state.allPicturesList.find(picItem => picItem.id === picture.id).guid || '';

            if (!picture || !category || !originalGuid || typeof personIndex !== 'number') return;

            _debugger(`removeCategory. RUN`, { category, personIndex });

            if (personIndex < 0) {
                _debugger(`removeCategory. IF (personIndex < 0) true case`);

                picture.trained.forEach(person => {
                    if (person.groups.length) {
                        person.groups.forEach((group) => {
                            group.category = group.category?.filter(_category => {
                                return category.trim().toLowerCase() !== _category.trim().toLowerCase();
                            });
                        });
                    }
                });
            } else {
                _debugger(`removeCategory. IF (personIndex < 0) false case`);
                if (!picture.trained[personIndex]) return;

                picture.trained[personIndex].groups.forEach((group) => {
                    group.category = group.category?.filter(_category => {
                        return category.trim().toLowerCase() !== _category.trim().toLowerCase();
                    });
                });
            }

            state.newPicturesList.set(picture.guid, {
                guid: originalGuid,
                trained: picture.trained,
            });
        },
        addCategory: (state, data) => {
            const { picture, category, personIndex, group } = data;

            const originalGuid = state.allPicturesList.find(picItem => picItem.id === picture.id).guid || '';

            if (!picture || !category || !group || !originalGuid || typeof personIndex !== 'number') return;

            if (personIndex === -1) {
                picture.trained.forEach(person => {
                    const target = person.groups.find(_group => _group.group === group);
                    if (target) {
                        target.category.push(category);
                    } else {
                        person.groups.push({
                            group,
                            category: [category],
                        });
                    }
                });
            } else {
                const person = picture.trained[personIndex];
                const target = person.groups.find(_group => _group.group === group);
                if (!person) return;

                if (target) {
                    target.category.push(category);
                } else {
                    person.groups.push({
                        group,
                        category: [category],
                    });
                }
            }

            state.newPicturesList.set(picture.guid, {
                guid: originalGuid,
                trained: picture.trained,
            });
        },
        clearNewPicturesList: state => state.savePicturesInfo = new Map(),
    },
    actions: {
        savePicturesInfo(context) {
            return new Promise(resolve => {
                const newPicturesListArray = Array.from(context.getters.getNewPicturesList.values());

                let finderService = new FinderService();

                const requests = newPicturesListArray.map(item => {
                    return finderService.updatePicTrained(item.guid, item);
                });

                Promise.allSettled(requests).then(() => {
                    context.state.newPicturesList = new Map();
                    finderService = null;
                    resolve();
                });
            });
        },
    }
});

export default editPicturesInfo;
