<template>
  <div class="picture-info">
    <ul class="picture-info__labels">
      <app-replaceable-label v-for="(label, labelIndex) in activeLabelsList"
                             :active-index="activeIndex"
                             :count="getCount(label)"
                             :group="getGroupByCategory(label, true)"
                             :is-active-label="isActiveLabel(label)"
                             :is-single-person="isSinglePerson()"
                             :key="label + labelIndex"
                             :label="label"
                             @select="onClickLabel(label)"
                             @remove="$emit('removeLabel', label)"
                             @replace="replaceCategory"
      />
    </ul>

    <span v-if="shouldShowAddButton"
          class="picture-info__add-toggle"
          title="Add category"
          @click="showSelect = !showSelect"

    >
      +
    </span>

    <div class="picture__add add-category"
         :class="{
            'picture__add add-category--open': showSelect
         }"
         @click.self="showSelect = false"
    >
      <Multiselect
          v-model="selectedCategory"
          :options="availableCategories"
          placeholder="Find category"
          @select="onSelectCategory"
          searchable
      />
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import Multiselect from "@vueform/multiselect";
import {mapGetters} from "vuex";

import AppReplaceableLabel from "@/components/ReplaceableLabel";

export default defineComponent({
  name: "AppPictureInfo",
  components: {
    Multiselect,
    AppReplaceableLabel,
  },
  props: {
    activeIndex: {
      type: Number,
      default: -1,
    },
    activeLabel: {
      type: String,
      default: '',
    },
    filterByGroup: {
      type: String,
      default: '',
    },
    counts: {
      type: Map,
      default: new Map(),
    },
    trained: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
      'addLabel',
      'removeLabel',
      'update:activeLabel'
  ],
  data: () => ({
    selectedCategory: null,
    showSelect: false,
  }),
  computed: {
    ...mapGetters(['getGroupsList', 'getMaxLabelsForPerson']),
    activeLabelsList() {
      if (!this.trained) return [];

      const labelsSet = new Set();
      let trained = this.trained.filter(person => person.gender === 'female');

      if (this.filterByGroup) {
        trained = trained.map(person => {
          return {
            ...person,
            groups: person.groups.filter(group => !this.filterByGroup || group.group === this.filterByGroup)
          };
        });
      }

      const trainedItemCallback = (groups) => {
        groups?.forEach(group => {
          group.category?.forEach(category => {
            labelsSet.add(category);
          })
        });
      }

      if (this.activeIndex <= -1) {
        trained.forEach(person => {
          trainedItemCallback(person.groups);
        });
      } else {
        // trainedItemCallback(trained[this.activeIndex]?.groups);
        trainedItemCallback(trained.find(item => item.originalIndex === this.activeIndex)?.groups);
      }

      return Array.from(labelsSet);
    },
    currentPersonGroupList() {
      return this.trained.find(person => person.originalIndex === this.activeIndex)?.groups
          ?.filter(group => group.category.length)
          ?.map(group => group.group);
    },
    availableCategories() {
      let result = [];
      const currentPerson = this.trained.find(person => person.originalIndex === this.activeIndex);

      if (!currentPerson || currentPerson.gender === 'male') return;

      const currentPersonGroupList = this.trained.find(person => person.originalIndex === this.activeIndex)?.groups
          ?.filter(group => group.category.length)
          ?.map(group => group.group);

      // Можно добавить только те категории, которых еще нет на фотографии
      // т.е. нельзя добавить и прямые и кудрявые волосы для одной персоны
      this.getGroupsList.forEach(group => {
        if (!currentPersonGroupList.find(personGroup => personGroup === group.group)) {
          group.category.forEach(category => {
            result.push(category);
          });
        }
      });
      return result.filter(category => {
        return !this.activeLabelsList.find(label => label === category);
      });
    },
    shouldShowAddButton() {
      const isMale = this.trained.find(person => person.originalIndex === this.activeIndex)?.gender === 'male';

      if (this.activeIndex === -1 || isMale) return false;

      let allLabelsCount
      // let limit;
      // const personsCount = this.filterByGroup ? 1 : this.trained.filter(person => person.gender === 'female').length;

      allLabelsCount = this.trained.find(person => person.originalIndex === this.activeIndex)?.groups.map(group => group.category).flat(1).length;

      // if (this.activeIndex === -1) {
      //   allLabelsCount = this.trained
      //       .reduce((count, person) => {
      //         return count + person.groups.map(group => group.category).flat(1).length;
      //       }, 0);
      //   limit = groupsLength * this.getMaxLabelsForPerson;
      // } else {
      //   allLabelsCount = this.trained[this.activeIndex]?.gender === 'female'
      //       ? this.trained[this.activeIndex]?.groups.map(group => group.category).flat(1).length : 0;
      //   limit = groupsLength * this.getMaxLabelsForPerson;
      // }

      return allLabelsCount < this.getMaxLabelsForPerson;
    },
  },
  methods: {
    replaceCategory(data) {
      const { deletingCategory, newCategory } = data;
      const group = this.getGroupsList.find(group => group.category.some(category => category === newCategory)).group || '';

      this.$emit('addLabel', {group, category: newCategory});
      this.$emit('removeLabel', deletingCategory);
    },
    getCount(category) {
      return this.counts.get(category) && this.counts.get(category) > 1
        ? `(${this.counts.get(category)})`
        : '';
    },
    isActiveLabel(label) {
      return String(label).trim().toLowerCase() === this.activeLabel.trim().toLowerCase();
    },
    onClickLabel(label) {
      this.$emit('update:activeLabel', this.isActiveLabel(label) ? '' : String(label).trim().toLowerCase());
    },
    onSelectCategory(selectedCategory) {
      const group = this.getGroupsList?.find(group => group?.category?.some(category => category === selectedCategory)).group || '';

      if (group) {
        this.$emit( 'addLabel', {group, category: selectedCategory});
        this.showSelect = false;
        setTimeout(() => {
          this.selectedCategory = null;
        });
      }
    },
    getGroupByCategory(category, isFiltered) {
      const list = this.getGroupsList?.find(group => group?.category?.find(title => title === category))?.category || [];

      return isFiltered
        ? list.filter(li => li !== category)
        : list;
    },
    isSinglePerson() {
      return !this.trained.filter(person => person.gender === 'female').length > 1;
    },
  }
})
</script>

<style lang="scss">
  .picture-info {
    display: flex;
    position: relative;
  }

  .picture-info__labels {
    display: inline-flex;
    list-style: none;
    flex-wrap: wrap;
    gap: 5px;
    padding-left: 0;
    margin: 5px 0 0;
    font-size: 12px;
  }

  .add-category {
    display: none;
    padding: 5px;
    background-color: #fff;
    position: absolute;
    bottom: 100%;
    right: 3px;
    border-radius: 3px;
    box-shadow: 0 0 2px 5px rgba(#222, .1);
    z-index: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 200vw;
      height: 200vh;
      left: -50vw;
      top: -50vh;
      z-index: 0;
    }

    &--open {
      display: flex;
    }
  }
</style>
