<template>
  <div class="groups-filter">
    <ul class="groups-filter__list">
      <li class="groups-filter__item"
          :class="{
            'groups-filter__item--active': isActive(label)
          }"
          v-for="label in labels"
          :key="label"
          @click="clickHandler(label)"
      >
        {{ label }}
      </li>
    </ul>
    <span v-if="rest">({{ rest }} items were hidden)</span>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "AppAbstractGroupsFilter",
  emits: ['update:filter'],
  props: {
    filter: {
      type: Set,
      default: new Set(),
    },
    labels: {
      type: Array,
      default: () => [],
    },
    rest: {
      type: Number,
      default: 0,
    }
  },
  methods: {
    clickHandler(label) {
      this.isActive(label)
        ? this.filter.delete(label)
        : this.filter.add(label);

      this.$emit('update:filter', this.filter);
    },
    isActive(label) {
      return this.filter.has(label);
    },
  }
})
</script>

<style lang="scss">
.groups-filter {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
}

.groups-filter__list {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0;
  margin: 0;
}

.groups-filter__item {
  display: flex;
  padding: 3px 5px;
  border-radius: 3px;
  color: #222;
  border: 1px solid #222;
  cursor: pointer;

  &:hover {
    border-color: #777;
    color: #777;
  }

  &--active {
    background-color: #222;
    color: #fff;
  }

  &--active:hover {
    background-color: #777;
    color: #fff;
  }
}

</style>
