<template>
  <div class="finder-filter">
    <div class="finder-filter__select">
      <span>Status:</span>
      <div class="finder-filter__textfield">
        <select :value="currentStatusFilter"
                @change="$emit('update:currentStatusFilter', $event.target.value)"
        >
          <option value="">Any status</option>
          <option value="ready">
            Ready
          </option>
          <option value="unready">
            Unready
          </option>
        </select>

        <button type="button" class="finder-filter__button"
                :disabled="!currentStatusFilter"
                @click="$emit('update:currentStatusFilter', '')">
          X
        </button>
      </div>
    </div>


    <div class="finder-filter__select" v-if="isUserAdmin">
      <span>Filter by owner</span>
      <div class="finder-filter__textfield">
        <select :value="selectedOwner"
                @change="$emit('update:selectedOwner', $event.target.value)"
        >
          <option value="">Not selected</option>
          <option v-for="item in usersList"
                  :key="item.guid"
                  :value="item.login">{{ item.login }}
          </option>
        </select>

        <button type="button" class="finder-filter__button"
                :disabled="!selectedOwner"
                @click="onOwnerReset">
          X
        </button>
      </div>
    </div>

    <div class="finder-filter__select">
      <span>Filter by group</span>
      <div class="finder-filter__textfield">
        <select :value="currentGroupFilter"
                @change="$emit('update:currentGroupFilter', $event.target.value)"
        >
          <option value="">Not selected</option>
          <option v-for="item in groupsList"
                  :key="item"
                  :value="item">{{ item }}
          </option>
        </select>

        <button type="button" class="finder-filter__button"
                :disabled="!currentGroupFilter"
                @click="$emit('update:currentGroupFilter', '')">
          X
        </button>
      </div>
    </div>

    <div class="finder-filter__select">
      <span>Filter by category</span>
      <div class="finder-filter__textfield">
        <select :value="currentCategoryFilter"
                @change="$emit('update:currentCategoryFilter', $event.target.value)"
        >
          <option value="">Not selected</option>
          <option v-for="item in categoriesList"
                  :key="item.guid"
                  :value="item.title">{{ item.title }}
          </option>
        </select>

        <button type="button" class="finder-filter__button"
                :disabled="!currentCategoryFilter"
                @click="$emit('update:currentCategoryFilter', '')">
          X
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'AppFilterForm',
  props: {
    categoriesList: {
      type: Array,
      default: () => [],
    },
    currentCategoryFilter: {
      default: '',
      type: String,
    },
    currentGroupFilter: {
      default: '',
      type: String,
    },
    currentStatusFilter: {
      default: '',
      type: String,
    },
    groupsList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      default: false,
      type: Boolean,
    },
    selectedOwner: {
      default: '',
      type: String,
    },
    usersList: {
      default: () => [],
      type: Array,
    },
  },
  emits: ['update:selectedOwner', 'update:currentGroupFilter', 'update:currentCategoryFilter', 'update:currentStatusFilter'],
  computed: {
    ...mapState([
      'isUserAdmin'
    ])
  },
  methods: {
    onTitleReset() {
      this.$emit('update:titleFilterValue', '');
    },
    onOwnerReset() {
      this.$emit('update:selectedOwner', '');
    },
  }
}
</script>

<style scoped lang="scss">
.finder-filter {
  gap: 10px;
  display: flex;
  align-items: center;
  width: fit-content;
  align-self: flex-end;
}

.finder-filter__textfield {
  display: flex;
}

.finder-filter__textfield input {
  height: 20px;
  flex-grow: 1;
  padding: 0 5px;
  outline: none;
  border: 2px solid #ff9000;
  border-radius: 5px 0 0 5px;
}

.finder-filter__button {
  border-radius: 0 5px 5px 0;
  border: none;
  background-color: #ff9000;
  font-weight: bold;
  padding: 0 4px;
  white-space: nowrap;

  &:not([disabled]) {
    cursor: pointer;
  }

  &:not([disabled]):hover {
    background-color: lighten(#ff9000, 10%);
  }
}

.finder-filter__select--wide {
  grid-column: 1/-1;
}

.finder-filter__select {
  //min-height: 50px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  white-space: nowrap;
  //flex-direction: column;

  & > span {
    //margin-bottom: 3px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }

  select {
    height: 20px;
    width: 120px;
    padding: 0 5px;
    cursor: pointer;
    outline: none;
    border: 1px solid #aaa;
    border-radius: 5px 0 0 5px;
  }
}
</style>
